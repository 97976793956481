import React, { useState, useEffect, useRef } from 'react';

export const noop = () => { }

export const range = (size: number, startAt: number = 0): number[] => {
    return [...Array(size).keys()].map(i => i + startAt);
}

type Enum<E> = Record<keyof E, number | string> & { [k: number]: string };

export function getEnumName<E extends Enum<E>>(enumeration: E, text: string): string {
    if (Number.parseInt(text)) {
        return enumeration[Number.parseInt(text)]
    }
    if (Object.keys(enumeration).includes(text)) {
        return text
    }
    return ""
}

export function getEnumValidNumberKeys<E extends Enum<E>>(enumeration: E, range: number[]): number[] {
    return Object.keys(enumeration).filter(k => !isNaN(Number(k)) && range.includes(Number(k))).map(i => Number(i))
}

export const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
        savedCallback.current && savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const getPiceaLang = (lang: string):string => {
    switch (lang) {
      case "ru": return "ru-ru";
      case "cs": return "cs-cz";
      case "nb": return "nb-no";
      case "pl": return "pl-pl";
      case "pt": return "pt-br";
      // case "pt": return"pt-pt";
      case "sk": return "sk-sk";
      case "sv": return "sv-se";
      case "tr": return "tr-tr";
      default: return lang
    }
}

export const getApiHostname = (env: string):string => {
    switch (env) {
      case "Production": return "https://api.piceasoft.com";
      case "Staging": return "https://api-stg.piceasoft.com";
      default: return 'https://api-rd.piceasoft.com'
    }
}