import { IInterviewListConfig, IInterview, ICustomQuestionsDetails, ICustomQuestionsAnswerDetails, IReportingAnswer } from '@piceasoft/core';

export const getCustomQuestionsOperationDetails = (data: IInterviewListConfig, result: IInterview): any => {
  let operationDetails: ICustomQuestionsDetails = {
    answers: []
  }

  data.questions.forEach(q => {

    let answerDetails: ICustomQuestionsAnswerDetails = {
      id: q.index.toString(),
      question: q.title,
      answer: []
    }
    operationDetails.answers.push(answerDetails);

    if( q.answers ) {
      // filter out other answers except the one that the user chose
      const resultsQaPair = result.answers?.find(qa => qa.questionIndex.toString() === q.index.toString());
      const actualAnswerIndex = resultsQaPair?.answerIndex;
      const actualAnswer = q.answers.find(ans => ans.index === actualAnswerIndex);
      const reportingAnswer: IReportingAnswer = {
        id: actualAnswer?.index.toString() || "",
        value: actualAnswer?.text || "",
        stop: false
      };
      answerDetails.answer.push(reportingAnswer);
    }

  })
  return operationDetails;
}
