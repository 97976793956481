import { Action, Reducer } from "redux";
import { IPiceaWidget } from "@piceasoft/core";
import { PiceaWidgetState } from "../store/typings/PiceaWidgetState";

import { KnownActions } from "../actions/piceawidget-actions";

export const reducer: Reducer<IPiceaWidget> = (state: IPiceaWidget | undefined, incomingAction: Action): IPiceaWidget => {
    if (state === undefined)
        return {
            state: PiceaWidgetState.Missing 
        }
    
    const action = incomingAction as KnownActions

    if (action.type === 'ENVIRONMENT_PICEA_WIDGET_SCRIPT_STATE_CHANGE' )
        return {...state, state: action.state}
    
    return state;
}