import { CommandBarButton, DirectionalHint, getTheme, ICommandBarStyles, Image, IStackStyles, Stack, Text, ImageFit } from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import { useId } from '@uifabric/react-hooks';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { actionCreators } from '../../../core/actions/teaching-actions';
import { IStore } from '../../../core/store';
import { strings } from '../../../localization/strings';
import { StartTeachingItem } from '../../shared/startTeaching/StartTeachingItem';
import { MainSearch } from './MainSearch';
import { UserBox } from './UserBox';
import { getIconSymbolsFromString, getPersonaColorsPallete } from '../../../core/scripts/style'
import { CustomBreadcrumb } from './CustomBreadcrumb';


type HeaderProps = {
    logo?: string,
    organization?: string,
    point?: string
}

const Header: React.FC<HeaderProps> = (props) => {

    // const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const history = useHistory();

    const teachingState = useSelector((s: IStore) => s.teaching)
    const historyButtonId = useId('header-history-button-id')
    const teachingItemName = "history"

    const options = useSelector((s: IStore) => s.workplace.options)

    React.useEffect(() => {
        setTimeout(() => {
            dispatch(actionCreators.addItemId(teachingItemName, historyButtonId))
        }, 500)
    }, [])

    const dispatch = useDispatch();

    function sprintHandleClick() {
        history.push(`/process`);
    }

    function historyHandleClick() {
        history.push(`/log`);
    }

    function packageHandleClick() {
        history.push(`/warehouse`);
    }

    return (
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }} styles={headerStyles}>
            <Stack.Item>
                <Stack tokens={{ childrenGap: 8 }} horizontal verticalAlign="center">
                    <CommandBarButton href="/" title="Picea® Online" styles={logoButtonStyles}>
                        <Stack grow verticalFill horizontal verticalAlign='center' style={{ overflow: 'hidden', width: '100%', height: 48 }} >
                            <Stack verticalAlign='center' style={{ padding: 12 }}>
                                <Stack
                                    horizontalAlign="center"
                                    style={{
                                        width: 24,
                                        height: 24,
                                        borderRadius: theme.effects.roundedCorner4,
                                        backgroundColor: !props.logo ? (props.organization ? getPersonaColorsPallete[props.organization[0].toLowerCase()] : undefined) : undefined
                                    }}>
                                    {props.logo && (
                                        <Image height={24} src={props.logo} imageFit={ImageFit.cover} style={{ borderRadius: theme.effects.roundedCorner4 }} />
                                    ) || (
                                            <Text variant="small" style={{ color: "#fff", margin: 'auto' }}>
                                                {getIconSymbolsFromString(props.organization)}
                                            </Text>
                                        )}
                                </Stack>
                            </Stack>
                            <Text block nowrap variant="mediumPlus" style={{ fontWeight: "bold", color: SharedColors.cyanBlue20, maxWidth: 160 }}>
                                {props.organization ?? "Picea Online"}
                            </Text>
                        </Stack>
                    </CommandBarButton>
                    <CustomBreadcrumb />
                </Stack>
            </Stack.Item>
            <Stack.Item grow shrink>
                <Text style={{ marginLeft: 8 }}>{props.point}</Text>
            </Stack.Item>
            {/* <Stack.Item>
                <Stack horizontal>
                    <CommandBarButton onClick={openPanel} iconProps={{ iconName: "OfficeChat" }} styles={commandBarButtonStyles} />
                    <Panel isLightDismiss isOpen={isOpen} onDismiss={dismissPanel} closeButtonAriaLabel="Закрыть" headerText="Чат с модератором">
                        <p>Чат с модератором</p>
                    </Panel>
                    <Separator vertical />
                    <CommandBarButton onClick={sprintHandleClick} iconProps={{ iconName: "Sprint" }} styles={commandBarButtonStyles} />
                </Stack>
            </Stack.Item> */}
            <CommandBarButton onClick={() => dispatch(actionCreators.clearTeachingState())} iconProps={{ iconName: "Unknown" }} styles={commandBarButtonStyles} />
            {options?.search?.enabled && <MainSearch />}
            <Stack.Item>
                <Stack horizontal>
                    {options?.history?.enabled && (
                        <CommandBarButton id={historyButtonId} onClick={historyHandleClick} iconProps={{ iconName: "History" }} styles={commandBarButtonStyles} />
                    )}
                    {options?.warehouse?.enabled && (
                        <CommandBarButton onClick={packageHandleClick} iconProps={{ iconName: "Package" }} styles={commandBarButtonStyles} />
                    )}
                    <CommandBarButton styles={commandBarButtonStyles} style={{ marginLeft: 12 }}>
                        <UserBox />
                    </CommandBarButton>
                </Stack>
                {teachingState.currentItem === teachingItemName && (
                    <StartTeachingItem
                        name={teachingItemName}
                        header={strings.START_TEACHING.HISTORY.HEADER}
                        text={strings.START_TEACHING.HISTORY.TEXT}
                        target={historyButtonId}
                        direction={DirectionalHint.bottomCenter}
                    />
                )}
            </Stack.Item>
        </Stack>
    )
}

export default Header

const theme = getTheme();
const headerStyles: IStackStyles = { root: { backgroundColor: theme.palette.white, boxShadow: "rgba(0, 0, 0, .08) 0 1px 0" } };
const commandBarButtonStyles: ICommandBarStyles = { root: { height: 48, width: 48 } };
const logoButtonStyles: ICommandBarStyles = { root: { height: 48, width: 260 } };