import { endpoints } from "./endpoints";
import { getApiResponse, IApiResponse } from "./api";
import { IWebBasedDiagnosticsOptions } from "../store/typings/IWebBasedDiagnosticsOptions";
import { IWebDgsStatusMessage } from "@piceasoft/core";

export const webDiagnosticsApi = {
    async getOptions(serviceId: string): Promise<IApiResponse<IWebBasedDiagnosticsOptions>> {
        return await getApiResponse(endpoints.webDiagnostics.getOptions( serviceId));
    },
    notifyStatus: ( msg: IWebDgsStatusMessage) => {
        fetch('/v1/notifications/webdgs_status',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                transactionUid: msg.transactionUid,
                status: msg.status,
                message: msg.message,
                code: msg.code
            })
        })
    }
}