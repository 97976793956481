import axios, { AxiosRequestConfig } from "axios";
import { consoleStyles } from "../scripts/console";
import { accountApi } from "./account";
import { commonApi } from "./common.api";
import { environmentApi } from "./environment";
import { identificationApi } from "./identification";
import { logApi } from "./log";
import { offerApi } from "./offer";
import { overviewApi } from "./overview.api";
import { piceaApi } from "./picea";
import { processApi } from "./process";
import { transactionApi } from "./transaction";
import { workplaceApi } from "./workplace";
import { gradingApi } from "./grading";
import { widgetApi } from "./widget.api";
import { webDiagnosticsApi } from "./webDiagnostics.api";

export const api = {
    v1: {
        account: accountApi,
        environment: environmentApi,
        identification: identificationApi,
        log: logApi,
        offer: offerApi,
        picea: piceaApi,
        process: processApi,
        transaction: transactionApi,
        workplace: workplaceApi,
        overview: overviewApi,
        common: commonApi,
        grading: gradingApi
    },
    widget:widgetApi,
    webDiagnostics: webDiagnosticsApi
}

export interface IApiResponse<T> {
    successed?: boolean
    error?: any
    data?: T
}

export async function getApiResponse<T>(url: string, config?: AxiosRequestConfig): Promise<IApiResponse<T>> {
    let result: IApiResponse<T> = {};
    await axios(url, config as AxiosRequestConfig).then(async response => {
        const contentType = response.headers["content-type"];
        return (contentType && contentType.indexOf("application/json") !== -1) ? await response.data as T : undefined;
    }).then(data => {
        result = { successed: true };
        result.data = data;
    }).catch(error => {
        console.debug("%c" + "FETCH " + url, consoleStyles.apiCollback);
        console.info(error);
        result.error = error.message;
    });
    return result;
}
