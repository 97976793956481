import {
    CurrencyCodes,
    IPromoProviderItemCondition,
    IPromoProviderItem,
    IPromoProviderItemState,
    IProviderOffer,
    CommissionType,
    IOffer,
    IGradesCategory,
    CatalogTypes,
    IGradeCategoryGrade,
    OfferProviderTypes,
    IOfferProviderResponse,
    TCustomFieldData,
    IContractCustomField,
    IOfferProviderState,
} from "@piceasoft/core";

import { CurrencySigns, PromotionPriceTypes } from "@piceasoft/core"
import { TOffersProviderState } from "../process/stages/PreOffer"
import { TOfferProviderState } from "../process/stages/PostOffer";
import { selector } from "../../core/store/selector";
import { IStore } from "../../core/store";

/** Offer provider price item */
export type TProviderPriceWithPromo = {
    price: number
    priceCurrencySign?: string
    grade?: string
    gradeCategories?: IGradeCategoryGrade[]
    promo?: {
        price: number
        currencySign?: string
        priceType: PromotionPriceTypes
    }
    priceWithPromo?: number
    expires?: string,
    commissionValue?: number
    commissionType?: CommissionType
    customFieldsMap?: IContractCustomField[]
    customFields?: TCustomFieldData[]
}

export const getPriceWithCommission = (price: number = 0, commissionValue?: number, commissionType?: CommissionType) => {
    if (!commissionValue || !commissionType) {
        return price
    }
    let result = commissionType === CommissionType.Percentage ? price - (price * commissionValue) / 100 : price - commissionValue;
    if(result < 0)
    result = 0

    return Number(result.toFixed(2));
}

/** Add promo benefit/fixed price to each offers items for given offer provider */
export const getProviderPricesWithPromoArray = (prov?: TOffersProviderState, promotion?: IPromoProviderItem): TProviderPriceWithPromo[] | undefined => {
    
    return prov?.offers?.map(i => {
        let result: TProviderPriceWithPromo = {
            price: getPriceWithCommission(i.price, prov.commissionValue, prov.commissionType),
            priceCurrencySign: i.currency_code ? CurrencySigns[i.currency_code as CurrencyCodes] : undefined,
            grade: i.grade,
            gradeCategories: i.gradeCategories,
            commissionValue: prov.commissionValue,
            commissionType: prov.commissionType
        }

        const condition = promotion?.conditions?.find(c => c.grade === i.grade || c.grade === "*")
        /** It gets fist element among many possible, so the order is important. Currently it relies on sorting
         * from _business.GetProviderPromoItems, it is sorted by deviceMatchScore, 
         * then by deviceGradeScore (to adopt '*' usage in conditions list), so the best match is on top. 
         * TODO: it does not mandatory delivers best price for the customer, since _business.GetProviderPromoItems 
         * does not know the device price from the flow, to calculate overall best price (offer + promo). 
         * Probably it will need more development later.
        */
        
        if (condition && ((condition?.priceType === PromotionPriceTypes.Benefit && prov.allowPromotions) || (condition?.priceType === PromotionPriceTypes.FixPrice && prov.code === ""))) {
            result.promo = {
                price: condition.price,
                currencySign: condition.currency ? CurrencySigns[condition.currency as CurrencyCodes] : undefined,
                priceType: condition.priceType
            }
            if (condition.priceType === PromotionPriceTypes.Benefit) {
                result.priceWithPromo = getPriceWithCommission(i.price, prov.commissionValue, prov.commissionType) + condition.price
            }
            if (condition.priceType === PromotionPriceTypes.FixPrice) {
                result.priceWithPromo = condition.price
            }
        }
        return result;
    })
}


/** Pcice from offer provider, with addition of promo benefit/fixed price  */
export const getProviderPriceWithPromo = (
    offer: IProviderOffer,
    promotion?: IPromoProviderItem,
    provCode?: string,
    allowPromo?: boolean,
    prov?: TOfferProviderState
): TProviderPriceWithPromo | undefined => {
    let result: TProviderPriceWithPromo = {
        price: getPriceWithCommission(offer.price, prov?.commissionValue, prov?.commissionType),
        priceCurrencySign: offer.currency_code ? CurrencySigns[offer.currency_code as CurrencyCodes] : undefined,
        grade: offer.grade,
        expires: offer.expires,
        commissionValue: prov?.commissionValue,
        commissionType: prov?.commissionType,
        customFieldsMap: prov?.customFieldsMap,
        customFields: offer.customFields,
    }
    //TODO: support grade categories
    const condition = promotion?.conditions?.find(c => c.grade === offer?.grade || c.grade === "*")
    if (condition && ((condition?.priceType === PromotionPriceTypes.Benefit && allowPromo) || (condition?.priceType === PromotionPriceTypes.FixPrice && provCode === ""))) {
        result.promo = {
            price: condition.price,
            currencySign: condition.currency ? CurrencySigns[condition.currency as CurrencyCodes] : undefined,
            priceType: condition.priceType
        }
        if (condition.priceType === PromotionPriceTypes.Benefit) {
            result.priceWithPromo = getPriceWithCommission(offer.price, prov?.commissionValue, prov?.commissionType) + condition.price
        }
        if (condition.priceType === PromotionPriceTypes.FixPrice) {
            result.priceWithPromo = condition.price
        }
    }
    return result;
}

/** returns highest price with promo or highest price 
 * @param prices all prices with promo for provider
 * @param catalogType calculation logic
*/
export const getBestPrice = (prices: TProviderPriceWithPromo[], catalogType: CatalogTypes): TProviderPriceWithPromo | undefined => {
    
    let result:TProviderPriceWithPromo | undefined = undefined
    
    // Grade - discount logic
    if (catalogType == CatalogTypes.GradeDiscount) {
        result = prices.find(price => price.grade == "BASE PRICE")
    }

    // Grade - Price logic
    if (catalogType == CatalogTypes.Tradein) {
        prices.forEach(price => {
            if (!result) {
                result = price
            }
            const priceWithCommission = getPriceWithCommission(price.price, price.commissionValue, price.commissionType)
            const resultWithCommission = getPriceWithCommission(result.price, result.commissionValue, result.commissionType)
            if ((result?.priceWithPromo ?? result?.price) < (price.priceWithPromo ?? price.price)) {
                result = price
            }
        });
    }

    return result;
}

/**
 * Function filters PromoProvider conditions array,
 * based on grade, promotion price type and offer provider configuration
 * @param conditions array of PromoProvider conditions
 * @param grade grade, assigned to device
 * @param isExternalOfferProvider if offer provider is external (not a local catalog)
 * @param isExternalAllowPromotions if offer provider configured to allow promotions
 * @returns 
 */
export const getFilteredPromoProviderConditions = (conditions?: IPromoProviderItemCondition[], grade?: string, isExternalOfferProvider?: boolean, isExternalAllowPromotions?: boolean) => {
    let newConditions: IPromoProviderItemCondition[] = []
    conditions?.forEach(c => {
        if (c.priceType === PromotionPriceTypes.FixPrice) {
            /** Fixed price promos are disabled for external providers (not a local catalog)  */
            if (isExternalOfferProvider) {
                return;
            }
            /** if grade is absent, or matches the device grade, or any grade is allowed in conditions (*) then keep this contition item */
            if (!grade || grade === c.grade || c.grade === "*") {
                newConditions.push(c);
            }
        }
        if (c.priceType === PromotionPriceTypes.Benefit && (!isExternalOfferProvider || isExternalAllowPromotions)) {
            /** if offer provider is not external, or configured to allow promotions */
            /** if grade is absent, or matches the device grade, or any grade is allowed in conditions (*) then keep this contition item */
            if (!grade || grade === c.grade || c.grade === "*") {
                newConditions.push(c);
            }
        }
    })
    return newConditions;
}

/**
 * Is promo available for the offer from offer provider
 * @param promotion - the promotion item
 * @param grade - device grade code
 * @param selectedProvider - selected provider code
 * @param isExternalProviderAllowPromotions - are promotions allowed for given offer provider in workflow config
 * @returns 
 */
export const validatePromotion = (promotion?: IPromoProviderItemState, grade?: string, selectedProvider?: string, isExternalProviderAllowPromotions?: boolean): boolean => {
    const isLocalOfferProvider = selectedProvider === undefined || selectedProvider === "" || selectedProvider === null
    if (!promotion) {
        return true;
    }

    //TODO: where current device is compared to condition?
    //TODO: support grade cathegories
    const condition = promotion.conditions?.find(c => c.grade === grade || c.grade === "*" || !grade)
    
    if (condition && !grade) {
        return true
    }

    if (grade) {
        if (condition?.priceType === PromotionPriceTypes.FixPrice && isLocalOfferProvider) {
            // fixPrice campaigns are only allowed for local catalog
            return true;
        }
        if (condition?.priceType === PromotionPriceTypes.Benefit && (isLocalOfferProvider || isExternalProviderAllowPromotions)) {
            // benefiType campaigns are allowed for external offer provider, if configured so in provider settings
            return true;
        }

    }

    return false;
}

export const getAssesmentGradeCategoryResults = (
    gradeCode: string | undefined, 
    gradeDelimiter: string, 
    gradeCategories: IGradesCategory[] | undefined
) : IGradeCategoryGrade[] | null => {
    if (!gradeCode) return null;
    /** splitting combined grade into aray */
    var resultGradesByCathegories = gradeCode.split(gradeDelimiter ?? ":");
    // number of splitted grade should equal to number of workflow grade categories 
    if (!resultGradesByCathegories || resultGradesByCathegories.length != gradeCategories?.length) return null;

    const results = resultGradesByCathegories.map((rg, index) => {
        const category = gradeCategories.find(gc => gc.index == index+1);

        return {
            category: category?.code,
            grade: rg === '?' ? null : rg // use null if no grade ( missing grade has value of '?' )
        } as IGradeCategoryGrade;
        
    });

    return results;
}

/**
 * calculating offer from Grade_Discount catalog type
 * @param gradeCode assesement combined grade like A:B:?:?
 * @param gradeDelimiter custom grade delimiter from workflow settings
 * @param offers offers array
 * @param gradeCategories grade categories from workflow settings
 * @returns offer object with deprecated price
 * assumptions:
 *       There should be offer item with "BASE PRICE" grade - from here we take the MAX price
 *       Each grade column in catalog has a name {categoryCode}-{grageCode} 
 *       each grade column contains deprecation value in %. Examples:
 *       -50 deprececates current price by 50% of MAX price
 */
export  const getOfferFromGradeDiscountCatalogType = (
        gradeCode: string  | undefined, 
        gradeDelimiter: string, 
        offers: IOffer[]  | undefined, 
        gradeCategories: IGradesCategory[] | undefined
        ): IOffer | undefined => {
        // no offer if input parameters
        if (!(gradeCode && offers)) return undefined
        const base_offer = offers.find(i => i.grade === "BASE PRICE");
        // getting Max price offer from offers
        var maxPrice = base_offer?.price;
        // getting currency
        var currencyCode = base_offer?.currency_code;
        var sku = base_offer?.sku;
        //console.debug("Calculating deprecated offer, max price, currency, sku", {maxPrice, currencyCode, sku})
        // no offer if no Max price and currency
        if (!(maxPrice && currencyCode)) return undefined

        /** splitting combined grade into aray */
        var resultGradesByCathegories = gradeCode.split(gradeDelimiter ?? ":");
        // number of splitted grade should equal to number of workflow grade categories 
        if (!resultGradesByCathegories || resultGradesByCathegories.length != gradeCategories?.length) return undefined

        /** variable will contain final deprecation value, NOTE, value is negative for normal price deduction ( -10 = 10% off ) */
        let deprecation: number = 0;

        resultGradesByCathegories.map((rg, index) => {
            // grade in offer.items is stored as {cathegorycode}-{gradecode}
            const category = gradeCategories.find(gc => gc.index == index+1);
            if (!category) {
                console.log("could not find matching grade for %O from gradeCategories: %O", rg, gradeCategories);
                return undefined;
            }

            deprecation += offers?.find(i => i.grade == `${category?.code}-${category?.grades.find(g => g.code == rg)?.code}`)?.price ?? 0
        })

        let result = {
            grade: gradeCode,
            price: deprecation > -100 ? (maxPrice)*(1 + deprecation/100) : 0, // return O if value goes negative 
            currency_code: currencyCode,
            sku: sku
        };
        
        return result 
    }
    
export const findSelectedOffer = (state: IStore, providerCode?: string) : IProviderOffer | undefined => {
    if (!state.process.current) return undefined;
    
    const offerProvider = state.process.current?.offerProviders?.find(i => i.code === providerCode);
    const offerProviderResponse = offerProvider?.result?.data as IOfferProviderResponse;
    const assessmentGrade = state.process.current?.transaction.assessment.grade
    const providerConfig = state.process.current.workflow.commonOffer?.providers?.find((i) => i.code === providerCode);
    const gradeCategories = state.process.current?.workflow.gradesCategories
    const gradeDelimiter = state.process.current?.workflow.gradesDelimiter
    const offers = state.process.current.offers
    const catalogType = selector.process.getCatalogType(state);
    const useGradesCategories = state.process.current?.workflow?.useGradesCategories;
    
    // post-offer price from main workflow catalog
    if (providerCode === "") {
        if (catalogType === CatalogTypes.GradeDiscount) {
            // GradeDiscount logic
            return getOfferFromGradeDiscountCatalogType(
                assessmentGrade,
                gradeDelimiter ?? ":",
                offers.items,
                gradeCategories
            )    
        } else {
            // catalogTypes.Grade_Price logic
            return offers.items?.find(i => i.grade === assessmentGrade)
        }
    } else {
        // Post offer from offer providers
        if (useGradesCategories) {
            if (providerConfig?.type === OfferProviderTypes.Catalog) {
    
                // get offer from grade discount catalog
                return getOfferFromGradeDiscountCatalogType(
                    assessmentGrade,
                    gradeDelimiter ?? ":",
                    offerProviderResponse?.offers,
                    gradeCategories)
            }
            else if (providerConfig?.type === OfferProviderTypes.Offer) { // using vendor api with grade categories
                return offerProviderResponse?.offers?.at(0);
            }
        }
        else {
            // select offer based on grade
            return offerProviderResponse?.offers?.find(i => i.grade === assessmentGrade);
        }
    }
    return undefined
}

export const findBestProvider = (availableProviders?: IOfferProviderState[], grade?: string) : IOfferProviderState | undefined => {
    if( !availableProviders) {
        return undefined
    }
    let bestProvider: IOfferProviderState | undefined
    let highestPrice = 0

    availableProviders.forEach( provider => {
        if( provider.result?.data?.offers) {
            let offers = provider.result.data.offers;
            if( grade) {
                offers = provider.result?.data?.offers?.filter( o => o.grade === grade)
            }
            offers.forEach( offer => {
                if( offer.price >= highestPrice) {
                    highestPrice = offer.price
                    bestProvider = provider
                    if( bestProvider.result?.data){
                        bestProvider.result.data.offers = offers
                    }
                }
            })
        }
    })

    return bestProvider
}
