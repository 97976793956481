import { Action, Reducer } from "redux";
import { KnownAction } from "../actions/validationjwt-actions";
import { IValidationJwtDataToken } from "../store/typings/IValidationJwtToken";

export const reducer: Reducer<IValidationJwtDataToken> = (state: IValidationJwtDataToken | undefined, incomingAction: Action): IValidationJwtDataToken => {
    if (state === undefined) {
        return {
            data: {
                data: {
                    status: 0,
                    token: '',
                    valid_until: 0
                }
            }
        };
    }

    const action = incomingAction as KnownAction;
    switch(action.type){
        case 'VALIDATION_JWT_TOKEN_REQUEST':
            return state;
        case 'VALIDATION_JWT_TOKEN_RECEIVE':
            {
                return { 
                    ...state,
                    data: {
                        ...state.data,
                        ...action.data
                    }
                }
            }
        case 'VALIDATION_JWT_TOKEN_RESET':
            {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        data: {
                            ...state.data.data,
                            status: 0,
                            token: '',
                            valid_until: 0
                        }
                    }
                }
            }
        default: return state;
    }
}