import { FontIcon, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../../../core/store';

export const ProcessSync: React.FC = () => {
    const sync = useSelector((state: IStore) => state.process.sync);
    const session_id = useSelector((state: IStore) => state.process.current?.session_id);
    if (!sync) return null;

    return (
        <Stack horizontal verticalFill verticalAlign='center' tokens={{padding: 6}}>
            {((!sync.isFetching && !sync.haveToBeSynced) || !session_id) && (
                <Stack.Item>
                    <FontIcon iconName={'CompletedSolid'} style={{ fontSize: 14, height: 14, width: 14, color: SharedColors.greenCyan10 }} />
                </Stack.Item>
            ) || (
                    sync.isFetching && (
                        <Spinner size={SpinnerSize.small} />
                    ) || (
                        <Stack.Item>
                            <FontIcon iconName={'AlertSolid'} style={{ fontSize: 14, height: 14, width: 14, color: SharedColors.red10 }} />
                        </Stack.Item>
                    )
                )}
        </Stack>
    )
}
