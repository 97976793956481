import {
    CommandBarButton, FontIcon, FontWeights, getTheme, IconButton, IIconProps,
    mergeStyleSets, Modal, PrimaryButton, Separator, Stack, Text
} from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import { useBoolean } from '@uifabric/react-hooks';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../../../core/store';
import { strings } from '../../../localization/strings';

const Process: React.FC = () => {
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const process = useSelector((state: IStore) => state.process.current);
    const environment = useSelector((state: IStore) => state.environment);

    if (!process || !environment) return null;

    return (
        <Stack horizontal>
            <CommandBarButton onClick={showModal} style={{ height: 28, padding: "0 8px" }}>
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                    <FontIcon iconName="Processing" />
                    <Text variant="small">{process.id}</Text>
                </Stack>
            </CommandBarButton>
            <Modal isOpen={isModalOpen} onDismiss={hideModal} containerClassName={contentStyles.container}>
                <div className={contentStyles.header}>
                    <span style={{ paddingRight: 32 }}>{strings.FOOTER.PROCESS.TECHNICAL_INFORMATION}</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close window"
                        onClick={hideModal}
                    />
                </div>
                <div className={contentStyles.body}>
                    <Stack tokens={{ childrenGap: 16 }}>
                        <Stack.Item>
                            <Text style={{ color: SharedColors.gray40 }}>
                                {strings.FOOTER.PROCESS.PROCESS_ID}: <b>{process.id}</b>
                            </Text>
                        </Stack.Item>
                        <Separator />
                        <Stack.Item>
                            <Stack verticalAlign="center">
                                <Stack horizontal tokens={{ childrenGap: 12 }}>
                                    <Stack.Item>
                                        <Stack>
                                            <Text variant="small" style={{ color: SharedColors.gray20 }}>Environment: {environment.name}</Text>
                                            <Text variant="small" style={{ color: SharedColors.gray20 }}>Version: 1.0.1</Text>
                                            {/* <Text variant="small" style={{ color: SharedColors.gray20 }}>Version: {environment.version}</Text> */}
                                        </Stack>
                                    </Stack.Item>
                                    <Stack.Item grow>
                                        <Stack horizontalAlign="end">
                                            <PrimaryButton onClick={hideModal}>OK</PrimaryButton>
                                        </Stack>
                                    </Stack.Item>
                                </Stack>
                            </Stack>
                        </Stack.Item>
                    </Stack>
                </div>
            </Modal>
        </Stack>
    )
}

export default Process

const theme = getTheme();
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        maxWidth: 640
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
