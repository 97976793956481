import { Action, Reducer } from "redux";
import { KnownAction } from "../actions/googlemaps-actions";
import { IGoogleMaps } from "@piceasoft/core";

export const reducer: Reducer<IGoogleMaps> = (state: IGoogleMaps | undefined, incomingAction: Action): IGoogleMaps => {
    if (state === undefined) {
        return {
            isReady: false,
            apiKey: ""
        };
    }
    const action = incomingAction as KnownAction;
    let newState = { ...state }
    switch (action.type) {
        case 'GOOGLEMAPS_IS_READY':
            newState.isReady = true 
            return newState;
        default: return state;
    }
};
