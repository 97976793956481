import { FontSizes, FontWeights, getTheme, IStackStyles, Stack, Text } from '@fluentui/react';
import * as React from 'react';
import Picea from './Picea';
import PiceaConnections from './PiceaConnections';
import Process from './Process';
import System from './System';
import Tutorial from './Tutorial';
import { browserName, browserVersion } from "react-device-detect";
import { useSelector } from 'react-redux';
import { IStore } from '../../../core/store';
import { ProcessSync } from './ProcessSync';

type FooterProps = {
    code?: string
    point?: string
    supportTel?: string
}

const Footer: React.FC<FooterProps> = (props) => {

    const options = useSelector((s: IStore) => s.workplace.options)

    return (
        <Stack horizontal verticalAlign="center" styles={footerStyles} tokens={{ childrenGap: 4 }}>
            <Stack.Item>
                <System />
            </Stack.Item>
            {options?.help?.enabled && (
                <Stack.Item>
                    <Tutorial label={options?.help?.label} />
                </Stack.Item>
            )}
            <Stack.Item style={{ padding: "0 8px" }}>
                <Text variant="small">{browserName} {browserVersion}</Text>
            </Stack.Item>
            <Stack.Item grow styles={{ root: { fontSize: FontSizes.small } }}>
                <span> </span>
            </Stack.Item>
            <Stack.Item>
                <Process />
            </Stack.Item>
            {options?.picea?.enabled && (
                <Stack.Item>
                    <PiceaConnections />
                </Stack.Item>
            )}
            {options?.picea?.enabled && (
                <Stack.Item>
                    <Picea />
                </Stack.Item>
            )}
            <Stack.Item>
                <ProcessSync />
            </Stack.Item>
        </Stack>
    )
}

export default Footer

const theme = getTheme();
const footerStyles: IStackStyles = {
    root: {
        fontSize: FontSizes.large,
        fontWeight: FontWeights.semibold,
        zIndex: 20,
        backgroundColor: theme.palette.white,
        boxShadow: "rgba(0, 0, 0, .08) 0 -1px 0",
        height: 28
    }
};
