export function getURLParameters(url: string): Record<string, string> {
    const params: Record<string, string> = {};
    const queryString = url.split('?')[1];
  
    if (queryString) {
      const keyValuePairs = queryString.split('&');
  
      keyValuePairs.forEach(keyValuePair => {
        const [key, value] = keyValuePair.split('=');
        params[key] = value;
      });
    }
  
    return params;
  }