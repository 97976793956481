import { IProcess } from "../store";
import { IProcessState } from "../store/typings/IProcessState";
import { IResponseResult } from "../store/typings/IResponseResult";
import { endpoints } from "./endpoints";

export const processApi = {
    async updateProcessSessionId(process_id: number, session_id: string): Promise<IResponseResult<undefined>> {
        let result: IResponseResult<undefined> = { successed: false };
        await fetch(endpoints.v1.process.session.setId, {
            method: "POST",
            body: JSON.stringify({process_id, session_id}),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(async response => {
                await (response.json() as Promise<IResponseResult<undefined>>)
                    .then(response => result = response)
                    .catch(error => result.errors = [{ description: error.message }])
            })
            .catch(error => result.errors = [{ description: error.message }])
        return result;
    },
    async clearProcessSessionId(process_id: number): Promise<IResponseResult<IProcessState>> {
        let result: IResponseResult<IProcessState> = { successed: false };
        await fetch(endpoints.v1.process.session.clearId(process_id), {
            method: "DELETE"
        })
            .then(async response => {
                await (response.json() as Promise<IResponseResult<IProcessState>>)
                    .then(response => result = response)
                    .catch(error => result.errors = [{ description: error.message }])
            })
            .catch(error => result.errors = [{ description: error.message }])
        return result;
    },
    async getProcessState(process_id: number): Promise<IResponseResult<IProcessState>> {
        let result: IResponseResult<IProcessState> = { successed: false };
        await fetch(endpoints.v1.process.session.restore(process_id))
            .then(async response => {
                await (response.json() as Promise<IResponseResult<IProcessState>>)
                    .then(response => result = response)
                    .catch(error => result.errors = [{ description: error.message }])
            })
            .catch(error => result.errors = [{ description: error.message }])
        return result;
    },
    async setProcessState(process_id: number, completed: boolean, state: IProcess): Promise<IResponseResult<IProcessState>> {
        let result: IResponseResult<IProcessState> = { successed: false };
        await fetch(endpoints.v1.process.session.save(process_id, completed), {
            method: "POST",
            body: JSON.stringify(state),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(async response => {
                await (response.json() as Promise<IResponseResult<IProcessState>>)
                    .then(response => result = response)
                    .catch(error => result.errors = [{ description: error.message }])
            })
            .catch(error => result.errors = [{ description: error.message }])
        return result;
    }
}