import LocalizedStrings from "react-localization";

export const strings_login_en = {
    AUTHORIZATION: "Authorization",
    AUTHORIZATION_INFORMATION: "Input your account name and password for login.",
    LOGIN: "Login:",
    PASSWORD: "Password:",
    SIGN_IN: "Sign in"
}

export const strings_login_ru = {
    AUTHORIZATION: "Авторизация",
    AUTHORIZATION_INFORMATION: "Выполните вход под своим логином и паролем.",
    LOGIN: "Имя пользователя:",
    PASSWORD: "Пароль:",
    SIGN_IN: "Войти"
}

export const stringsLogin = new LocalizedStrings({
    en: {
        WORKPLACE: "WORKPLACE",
        PLEASE_WAIT: "Loading...",
        AUTHORIZATION: "Authorization",
        AUTHORIZATION_INFORMATION: "Sign in with your login and password.",
        LOGIN: "Login:",
        PASSWORD: "Password:",
        SIGN_IN: "Sign in",
        LOGIN_FAILED: "Login failed. Login or password is incorrect.",
    },
    ru: {
        WORKPLACE: "РАБОЧЕЕ МЕСТО",
        PLEASE_WAIT: "Пожалуйста, подождите...",
        AUTHORIZATION: "Авторизация",
        AUTHORIZATION_INFORMATION: "Выполните вход под своим логином и паролем.",
        LOGIN: "Имя пользователя:",
        PASSWORD: "Пароль:",
        SIGN_IN: "Войти",
        LOGIN_FAILED: "Не удалось войти в систему. Логин или пароль неверны!",
    }
});