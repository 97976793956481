import { CommandBarButton, DirectionalHint, FontIcon, getTheme, Stack, Text } from '@fluentui/react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators } from '../../../core/actions/teaching-actions';
import { IStore } from '../../../core/store';
import { strings } from '../../../localization/strings';
import { Help } from '../../shared/help/Help';
import { MarkdownHelp } from '../../shared/help/MarkdownHelp';
import { StartTeachingItem } from '../../shared/startTeaching/StartTeachingItem';

const Tutorial: React.FC<{ label?: string }> = ({ label }) => {
    const [showHelp, { toggle: toggleShowHelp }] = useBoolean(false)
    const tutorialButtonId = useId('footer-tutorial-button-id')
    const teachingState = useSelector((s: IStore) => s.teaching)
    const teachingItemName = "tutorial"
    const dispatch = useDispatch();
    const workplaceTutorial = useSelector((s: IStore) => s.workplace.tutorial)

    React.useEffect(() => {
        dispatch(actionCreators.addItemId(teachingItemName, tutorialButtonId))
    }, [])

    return (
        <Stack horizontal>
            <CommandBarButton id={tutorialButtonId} onClick={toggleShowHelp} style={{ height: 28, padding: "0 8px", backgroundColor: theme.palette.accent }}>
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                    <FontIcon iconName="Education" style={{ color: "#fff" }} />
                    <Text variant="small" style={{ color: "#fff", fontWeight: "bolder" }}>{label ?? strings.FOOTER.TUTORIAL.LABEL}</Text>
                </Stack>
            </CommandBarButton>
            {showHelp && workplaceTutorial && (
                <MarkdownHelp markdown={workplaceTutorial} show={showHelp} hide={toggleShowHelp} />
            ) || (
                    <Help manual="tutorial" show={showHelp} hide={toggleShowHelp} width={800} height={600} />
                )}
            <div>
                {teachingState.currentItem === teachingItemName && (
                    <StartTeachingItem
                        name={teachingItemName}
                        header={strings.START_TEACHING.TUTORIAL.HEADER}
                        text={strings.START_TEACHING.TUTORIAL.TEXT}
                        target={tutorialButtonId}
                        direction={DirectionalHint.topCenter}
                    />
                )}
            </div>
        </Stack>
    )
}

export default Tutorial

const theme = getTheme();
