import { IWorkflow } from "@piceasoft/core";
import { IEnvironment } from "@piceasoft/core";
import { getApiResponse, IApiResponse } from "./api";
import { endpoints } from "./endpoints";

export const environmentApi = {
    async getEnvironment(): Promise<IApiResponse<IEnvironment>> {
        return await getApiResponse(endpoints.v1.getEnvironmentConfiguration);
    },
    getServiceWorkflow: async (
        id: number,
    ): Promise<IWorkflow | undefined> => {
        let result: IWorkflow | undefined = undefined;
        await fetch(endpoints.v1.service(id.toString()))
            .then(async response => {
                await (response.json() as Promise<{workflow: IWorkflow}>)
                    .then(response => result = response.workflow)
            })
        return result;
    }
    
}