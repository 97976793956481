import { strings } from "../../localization/strings";
import { PiceaDevice } from "./picea.typings";

export enum GenericErrors {
    EDP_NOT_SUPPORTED_1 = 1,
    UNEXPECTED_ERROR = 2,
    EDP_NOT_SUPPORTED_255 = 255,
    EDP_UNKNOWN_ERROR = -2146435071,
EDP_UNKNOWN_EXCEPTION = -2146435070,
EDP_INVALID_POINTER = -2146435069,
EDP_INVALID_PARAMETER = -2146435068,
EDP_INVALID_HANDLE = -2146435067,
EDP_INVALID_VALUE = -2146435066,
EDP_NOT_ENOUGH_MEMORY = -2146435065,
EDP_NOT_INITIALIZED = -2146435064,
EDP_INIT_FAILED = -2146435063,
EDP_NOT_SUPPORTED_VERSION = -2146435062,
EDP_CANCELLED = -2146435061,
EDP_FAILED_TIMEOUT = -2146435060,
EDP_NOT_FOUND = -2146435059,
EDP_ALREADY_EXIST = -2146435058,
EDP_ALL_LISTED = -2146435057,
EDP_FAILED = -2146435056,
EDP_BUSY = -2146435055,
EDP_NOT_ALLOWED = -2146435054,
EDP_NOT_IMPLEMENTED = -2146435053,
EDP_NOT_SUPPORTED = -2146435052,
EDP_NOT_SUPPORTED_INTERFACE = -2146435051,
EDP_NOT_SUPPORTED_DEVICE = -2146435050,
EDP_NOT_SUPPORTED_TYPE = -2146435049,
EDP_NOT_SUPPORTED_FORMAT = -2146435048,
EDP_NO_PERMISSION = -2146435047,
EDP_CLIENT_ID_NOT_FOUND = -2146435046,
EDP_CONNECTION_TO_SERVER_FAILED = -2146435045,
EDP_OUT_OF_RANGE = -2146435044,
EDP_NO_DATA = -2146435043,
EDP_NO_MORE_DATA = -2146435042,
EDP_DIFFERENT_VARIANT_TYPE = -2146435041,
EDP_INVALID_VARIANT_TYPE = -2146435040,
EDP_INVALID_VARIANT_DATA = -2146435039,
EDP_BUFFER_TOO_SMALL = -2146435038,
EDP_NOT_AVAILABLE = -2146435037,
EDP_NOT_SUPPORTED_COMMAND_ID = -2146435036,
EDP_SESSION_NOT_FOUND = -2146435035,
EDP_NO_INTERFACE = -2146435034,
EDP_TRANSCODER_MEDIATYPE_PIN_NOT_FOUND = -2146435033,
EDP_TRANSCODER_PIN_NOT_FOUND = -2146435032,
EDP_TRANSCODER_NOT_SUPPORTED = -2146435031,
EDP_NOT_SUPPORTED_OUTPUT_FORMAT = -2146435030,
EDP_TRANSCODER_INVALID_FILE_FORMAT = -2146435029,
EDP_NOT_SUPPORTED_INPUT_FORMAT = -2146435028,
EDP_TRANSCODER_DRM_PROTECTED_INPUT_FILE = -2146435027,
EDP_SIZE_IS_TOO_BIG = -2146435026,
EDP_APPLICATION_RUNNING = -2146435025,
EDP_STORAGE_NOT_FOUND = -2146435024,
EDP_NO_CLIENTS = -2146435023,
EDP_MISSING_PARAMETER = -2146435022,
EDP_SERVICE_NOT_FOUND = -2146435021,
EDP_LIBRARY_NOT_FOUND = -2146435020,
EDP_ITEM_EXEC_SKIPPED = -2146435019,
EDP_ITEM_EXEC_USER_FAIL = -2146435018,
EDP_ITEM_EXEC_NOT_EXECUTED = -2146435017,
EDP_SW_VERSION_TOO_OLD = -2146435016,
EDP_PROTOCOL_NOT_FOUND = -2146435015,
EDP_INVALID_DATA = -2146435008,
EDP_NOT_SUPPORTED_FID = -2146435007,
EDP_NOT_SUPPORTED_MANUFACTURER = -2146435006,
EDP_NOT_SUPPORTED_MODEL = -2146435005,
EDP_DRIVER_MISSING = -2146435004,
EDP_DRIVER_NOT_FUNCTIONAL = -2146435003,
EDP_DRIVER_UPDATE_NEEDED = -2146435002,
EDP_DYNAMIC_MODULE_INSTALL_FAILED = -2146435001,
EDP_TRADE_IN_CURRENCY_MISSING = -2146435000,
EDP_NOT_SUPPORTED_ITEM = -2146434999,
EDP_REPORTING_CONFIGURATION_FAILURE = -2146434998,
EDP_EXTERNAL_API_FAILURE = -2146434997,
EDP_INVALID_DATETIME = -2146434996,
EDP_FOLDER_CACHE_FOLDER_FAILURE = -2146434995,
EDP_TERMINATED = -2146434994,
EDP_ITEM_TRANSFER_SKIPPED = -2146434993,
EDP_TRANSFER_ALL_FAILED = -2146434992,
EDP_MANDATORY_UPDATE_REQUIRED = -2146434991,
EDP_TOO_FREQUENT_OPERATION_FILTER_ACTIVE = -2146434990,
EDP_PICEASETUP_NOT_FOUND = -2146434989,
EDP_VPP_INIT_ERROR = -2146434988
}

export enum BusErrors {
    EDP_BUS_NOT_FUNCTIONAL = -2146434944
}

export enum LicenseErrors {
    EDP_LICENSE_MISSING = -2146434928,
    EDP_PRODUCT_EXPIRED = -2146434927,
    EDP_LICENSE_EXPIRED = -2146434926,
    EDP_INVALID_PRODUCT_ID = -2146434925,
    EDP_ACTIVATION_FAILED = -2146434924,
    EDP_ACTIVATION_FAILED_INVALID_LICENSE = -2146434923,
    EDP_ACTIVATION_FAILED_NO_FREE_LICENSES = -2146434922,
    EDP_ACTIVATION_FAILED_ALREADY_USED = -2146434921,
    EDP_ACTIVATION_REQUIRED = -2146434920,
    EDP_PRODUCT_NOT_SUPPORTED = -2146434919,
    EDP_PRODUCT_TOO_OLD = -2146434918,
    EDP_BUCKET_NOT_FOUND = -2146434917,
    EDP_BUCKET_EXPIRED = -2146434916,
    EDP_BUCKET_EMPTY = -2146434915,
    EDP_BUCKET_ALLOCATION_NOT_FOUND = -2146434914,
    EDP_CODE_NOT_FOUND = -2146434913,
    EDP_CODE_USED = -2146434912,
    EDP_CODE_EXPIRED = -2146434911,
    EDP_CODE_NOT_SUPPORTED = -2146434910,
    EDP_STOLEN_CHECK_BUCKET_EMPTY = -2146434909,
    EDP_LICENSE_CHAIN_DEACTIVATED = -2146434908    
}

export enum DatabaseErrors {
    EDP_DATABASE_ERROR = -2146434816,
    EDP_DATABASE_INTERNAL = -2146434815,
    EDP_DATABASE_PERM = -2146434814,
    EDP_DATABASE_ABORT = -2146434813,
    EDP_DATABASE_BUSY = -2146434812,
    EDP_DATABASE_LOCKED = -2146434811,
    EDP_DATABASE_NOMEM = -2146434810,
    EDP_DATABASE_READONLY = -2146434809,
    EDP_DATABASE_INTERRUPT = -2146434808,
    EDP_DATABASE_IOERR = -2146434807,
    EDP_DATABASE_CORRUPT = -2146434806,
    EDP_DATABASE_FULL = -2146434805,
    EDP_DATABASE_CANTOPEN = -2146434804,
    EDP_DATABASE_EMPTY = -2146434803,
    EDP_DATABASE_SCHEMA = -2146434802,
    EDP_DATABASE_TOOBIG = -2146434801,
    EDP_DATABASE_CONSTRAINT = -2146434800,
    EDP_DATABASE_MISMATCH = -2146434799,
    EDP_DATABASE_MISUSE = -2146434798,
    EDP_DATABASE_NOLFS = -2146434797,
    EDP_DATABASE_AUTH = -2146434796,
    EDP_DATABASE_FORMAT = -2146434795,
    EDP_DATABASE_RANGE = -2146434794,
    EDP_DATABASE_NOTADB = -2146434793    
}

export enum ObjectErrors {
    EDP_PROP_ID_NOT_SUPPORTED = -2146434560,
    EDP_PROP_ID_OUT_OF_RANGE = -2146434559,
    EDP_PROP_ID_NOT_FOUND = -2146434558,
    EDP_INVALID_PROP_DATA = -2146434557,
    EDP_API_NOT_FOUND = -2146434556,
    EDP_API_FUNCTION_NOT_FOUND = -2146434555,
    EDP_FUNCTION_NOT_FOUND = -2146434554    
}

export enum DeviceErrors {
    EDP_DEVICE_NOT_FOUND = -2146434304,
    EDP_NOT_SUPPORTED_CONNECTION_TYPE = -2146434303,
    EDP_CONNECTION_BUSY = -2146434302,
    EDP_CONNECTION_LOST = -2146434301,
    EDP_CONNECTION_FAILED = -2146434300,
    EDP_DEVICE_LOCKED = -2146434299,
    EDP_DEVICE_UNKNOWN_ERROR = -2146434298,
    EDP_DEVICE_NOT_RESPONDING = -2146434297,
    EDP_DEVICE_NO_SIM_CARD = -2146434296,
    EDP_DEVICE_NO_PERMISSION = -2146434295,
    EDP_DEVICE_UI_ACTION_FAILED = -2146434294,
    EDP_DEVICE_TRUST_CONFIRM_NEEDED = -2146434293,
    EDP_DEVICE_NOT_READY = -2146434292,
    EDP_DEVICE_NOT_ACTIVATED = -2146434291,
    EDP_DEVICE_SETUP_NOT_FINISHED = -2146434290,
    EDP_DEVICE_RESERVED = -2146434289,
    EDP_DEVICE_BATTERY_LEVEL_TOO_LOW = -2146434288,
    EDP_DEVICE_ALREADY_ACTIVATED = -2146434287,
    EDP_DEVICE_SIM_PIN_LOCKED = -2146434286,
    EDP_DEVICE_ADB_INSTALL_REJECTED = -2146434285,
    EDP_DEVICE_STOLEN_CHECK_NOT_SUPPORTED = -2146434284,
    EDP_DEVICE_CONNECTOR_INSTALL_ONGOING = -2146434283,
    EDP_DEVICE_IOS_BACKUP_PASSWORD_LEFT = -2146434282,
    EDP_DEVICE_STOLEN_CHECK_UNKNOWN_RESPONSE = -2146434281,
    EDP_DEVICE_RECONNECTION_NOT_DETECTED = -2146434280
}

export enum FileSystemErrors {
    EDP_STORAGE_FULL = -2146434048,
    EDP_MEMORY_FULL = -2146434047,
    EDP_FILE_OPENING_FAILED = -2146434046,
    EDP_FILE_NO_PERMISSION = -2146434045,
    EDP_FILE_NOT_FOUND = -2146434044,
    EDP_FOLDER_NOT_FOUND = -2146434043,
    EDP_FOLDER_PATH_TOO_LONG = -2146434042,
    EDP_FILE_NAME_TOO_LONG = -2146434041,
    EDP_INVALID_FILE_NAME = -2146434040,
    EDP_INVALID_FOLDER_NAME = -2146434039,
    EDP_FILE_WRITING_FAILED = -2146434038,
    EDP_FOLDER_NOT_EMPTY = -2146434037,
    EDP_FILE_BUSY = -2146434036,
    EDP_STORAGE_READ_ONLY = -2146434035,
    EDP_FOLDER_CREATION_FAILED = -2146434034,
    EDP_FILE_DELETION_FAILED = -2146434033,
    EDP_FILE_RENAME_FAILED = -2146434032,
    EDP_PC_STORAGE_FULL = -2146434031,
    EDP_INVALID_FILE_DATA = -2146434030,
    EDP_FILE_READING_FAILED = -2146434029    
}

export enum InstallerErrors {
    EDP_DEVICE_INSTALLER_BUSY = -2146433792,
    EDP_DEVICE_INSTALLER_USER_CANCEL = -2146433791,
    EDP_DEVICE_INSTALLER_FILE_CORRUPTED = -2146433790,
    EDP_DEVICE_INSTALLER_INSUFFICIENT_MEMORY = -2146433789,
    EDP_DEVICE_INSTALLER_PACKAGE_NOT_SUPPORTED = -2146433788,
    EDP_DEVICE_INSTALLER_SECURITY_FAILURE = -2146433787,
    EDP_DEVICE_INSTALLER_MISSING_DEPENDENCY = -2146433786,
    EDP_DEVICE_INSTALLER_FILE_IN_USE = -2146433785,
    EDP_DEVICE_INSTALLER_GENERAL_ERROR = -2146433784,
    EDP_DEVICE_INSTALLER_NO_RIGHTS = -2146433783,
    EDP_DEVICE_INSTALLER_NETWORK_FAILURE = -2146433782,
    EDP_DEVICE_INSTALLER_ACCESS_DENIED = -2146433781,
    EDP_DEVICE_INSTALLER_UPGRADE_ERROR = -2146433780,
    EDP_DEVICE_INSTALLER_UNINSTALL_FAILED = -2146433779,
    EDP_DEVICE_INSTALLER_INVALID_APP_ID = -2146433778,
    EDP_DEVICE_INSTALLER_UNINSTALLABLE_APPLICATION = -2146433777,
    EDP_DEVICE_INSTALLER_USER_ACTION_NEEDED = -2146433776,
    EDP_DEVICE_INSTALLER_NO_SD_CARD_INSERTED = -2146433775,
    EDP_DEVICE_INSTALLER_WRONG_SD_CARD_INSERTED = -2146433774,
    EDP_DEVICE_INSTALLER_LKMERROR = -2146433773    
}

export enum DRMErrors {
    EDP_DRM_NO_CREDENTIALS_SET = -2146433536,
    EDP_DRM_NO_LICENSE = -2146433535
}

export enum MessageErrors {
    EDP_DEVICE_MSGCENTRE_NOT_DEFINED = -2146433280
}

export enum SyncErrors {
    EDP_DEVICE_UI_NOT_IDLE = -2146433024,
    EDP_NOT_SYNCHRONIZED = -2146433023,
    EDP_DEVICE_ITEM_REJECT = -2146433022
}

export enum ErasingErrors {
    EDP_ERASER_OPERATION_FAILURE = -2146432768,
    EDP_ERASER_USB_MASS_STORAGE_MOUNT_DETECTED = -2146432767,
    EDP_ERASER_CANNOT_READ_DETAILED_DEVICE_INFO = -2146432766,
    EDP_ERASER_OVERWRITE_FAILED = -2146432765,
    EDP_ERASER_DEVICE_STOLEN_WARNING = -2146432764,
    EDP_ERASER_DEVICE_STOLEN = -2146432763,
    EDP_ERASER_DEVICE_RECONNECTION_NOT_DETECTED = -2146432762,
    EDP_ERASER_VALIDATION_FAILED = -2146432761,
    EDP_ERASER_ESIM_REMOVAL_FAILED = -2146432760,
    EDP_ERASER_AND_NO_DEVICE_TYPE_AVAILABLE = -2146432717,
    EDP_ERASER_AND_PROCESSING_ROOTED_DEVICES_NOT_ENABLED = -2146432716,
    EDP_ERASER_AND_GRANTING_PRIVILEGED_ACCESS_FAILED = -2146432715,
    EDP_ERASER_AND_CANNOT_DELETE_DATA = -2146432714,
    EDP_ERASER_AND_SCRIPT_NOT_RUN_WITH_PRIVILEGED_ACCESS = -2146432713,
    EDP_ERASER_AND_DEVICE_ADMINISTRATOR_ACTIVATION_FAILED = -2146432712,
    EDP_ERASER_AND_EXTERNAL_STORAGE_COUNT_MISMATCH = -2146432711,
    EDP_ERASER_AND_FACTORY_RESET_FAILED = -2146432710,
    EDP_ERASER_AND_GENERATING_DELETE_SCRIPT_FAILED = -2146432709,
    EDP_ERASER_AND_INSTALL_FAILED_INSUFFICIENT_STORAGE = -2146432708,
    EDP_ERASER_AND_INSTALL_FAILED = -2146432707,
    EDP_ERASER_AND_DEVICE_OWNER_ALREADY_PROVISIONED = -2146432706,
    EDP_ERASER_AND_ADB_CANNOT_ACCESS_DEVICE = -2146432705,
    EDP_ERASER_AND_DEVICE_OWNER_FAILED = -2146432704,
    EDP_ERASER_IOS_IMAGE_DOWNLOAD_FAILED = -2146432618,
    EDP_ERASER_IOS_RESTORE_PROCESS_FAILED = -2146432617,
    EDP_ERASER_IOS_NO_TEMPORARY_FOLDER_EXISTS = -2146432616,
    EDP_ERASER_IOS_USB_CONNECTIVITY_ISSUE_DURING_FLASHING = -2146432615,
    EDP_ERASER_IOS_ASR_ERROR_ITUNES_RESTORE_NEEDED = -2146432608,
    EDP_ERASER_IOS_BASEBAND_ERROR_ITUNES_RESTORE_NEEDED = -2146432607,
    EDP_ERASER_IOS_IBEC_SENDING_ERROR = -2146432606,
    EDP_ERASER_IOS_APPLE_DEVICE_DETECTION_ERROR = -2146432605,
    EDP_ERASER_IOS_IDEVICERESTORE_TIMEOUT_TRIGGERED = -2146432604,
    EDP_ERASER_IOS_IDEVICERESTORE_DEVICE_DETECTION_ERROR = -2146432603,
    EDP_ERASER_IOS_IDEVICERESTORE_TSS_ERROR = -2146432602,
    EDP_ERASER_IOS_INVALID_IMAGE_FILE = -2146432601,
    EDP_ERASER_IOS_PLACE_RECOVERY_MODE = -2146432600,
    EDP_ERASER_IOS_ENTER_RECOVERY_MODE = -2146432599,
    EDP_ERASER_IOS_PLACE_RESTORE_MODE = -2146432598,
    EDP_ERASER_IOS_ENTER_RESTORE_MODE = -2146432597,
    EDP_ERASER_WP_FLASH_PROCESS_FAILED = -2146432592,
    EDP_ERASER_WP_USE_WDRT_TOOL = -2146432591,
    EDP_ERASER_INVALID_SERIAL_NUMBER_FORMAT = -2146432590,
    EDP_ERASER_INVALID_PIN_CODE_FORMAT = -2146432589,
    EDP_ERASER_INVALID_PIN_CODE_FORMAT_OLD_BB = -2146432588,
    EDP_ERASER_STORAGE_NOT_SUPPORTED = -2146432587,
    EDP_ERASER_PROTECTION_ACCOUNT_DETECTED = -2146432586,
    EDP_ERASER_SIM_DETECTED = -2146432585    
}

export enum SoftwareUpdateErrors {
    DP_SWUPDATE_ANDROID_LAUNCHED = -2146432576,
    DP_SWUPDATE_ANDROID_DOWNLOAD_STARTED = -2146432575,
    EDP_SWUPDATE_ANDROID_WIFI_SETTINGS_MISSING = -2146432574,
    EDP_SWUPDATE_ANDROID_WIFI_NOT_ENABLED = -2146432573,
    EDP_SWUPDATE_ANDROID_WIFI_NOT_CONNECTED = -2146432572,
    EDP_SWUPDATE_ANDROID_WIFI_INTERNET_FAILED = -2146432571    
}

export enum SocketErrors {
    ERR_SOCKET_NOT_CONNECTED = -2146432511,
    ERR_SOCKET_CONNECT_FAILED = -2146432510
}

export enum NetworkErrors {
    EDP_NETWORK_NOT_AVAILABLE = -2146432256,
    EDP_INVALID_NETWORK_SETTINGS = -2146432255,
    EDP_INVALID_HTTP_REQUEST = -2146432254,
    EDP_HTTP_REQUEST_FAILED = -2146432253,
    EDP_NETWORK_CONNECTION_REFUSED = -2146432242,
    EDP_NETWORK_REMOTE_HOST_CLOSED = -2146432241,
    EDP_NETWORK_HOST_NOT_FOUND = -2146432240,
    EDP_NETWORK_TIMEOUT = -2146432239,
    EDP_NETWORK_OPERATION_CANCELED = -2146432238,
    EDP_NETWORK_SSL_HANDSHAKE_FAILED = -2146432237,
    EDP_NETWORK_TEMPORARY_NETWORK_FAILURE = -2146432236,
    EDP_NETWORK_UNKNOWN_NETWORK_ERROR = -2146432235,
    EDP_NETWORK_PROXY_CONNECTION_REFUSED = -2146432234,
    EDP_NETWORK_PROXY_CONNECTION_CLOSED = -2146432233,
    EDP_NETWORK_PROXY_NOT_FOUND = -2146432232,
    EDP_NETWORK_PROXY_TIMEOUT = -2146432231,
    EDP_NETWORK_PROXY_AUTHENTICATION_REQUIRED = -2146432230,
    EDP_NETWORK_UNKNOWN_PROXY_ERROR = -2146432229,
    EDP_NETWORK_CONTENT_ACCESS = -2146432228,
    EDP_NETWORK_CONTENT_OPERATION_NOT_PERMITTED = -2146432227,
    EDP_NETWORK_CONTENT_NOT_FOUND = -2146432226,
    EDP_NETWORK_AUTHENTICATION_REQUIRED = -2146432225,
    EDP_NETWORK_CONTENT_RE_SEND = -2146432224,
    EDP_NETWORK_UNKNOWN_CONTENT_ERROR = -2146432223,
    EDP_NETWORK_PROTOCOL_UNKNOWN_ERROR = -2146432222,
    EDP_NETWORK_PROTOCOL_INVALID_OPERATION = -2146432221,
    EDP_NETWORK_PROTOCOL_FAILURE = -2146432220,
    EDP_NETWORK_OPER_FAILURE_PLEASE_RETRY = -2146432219,
    EDP_NETWORK_2FA_AUTHENTICATION = -2146432218,
    EDP_NETWORK_REDIRECT_NOT_ALLOWED = -2146432217,
    EDP_NETWORK_TOKENS_NOT_AVAILABLE = -2146432216    
}

export enum OtherError {
    EDP_EXTERNAL_APPLICATION_MISSING = -2146431232,
    EDP_IOS_FIND_MY_IPHONE_WAS_ON = -2146431231,
    EDP_APPLE_ID_CREDENTIALS_NEEDED = -2146431230,
    EDP_IOS_RESTORE_ROLLBACK = -2146431229,
    EDP_IOS_MDM_BACKUPRESTORE_NOT_ALLOWED = -2146431228,
    EDP_IOS_MDM_RESTRICTED = -2146431227,
    EDP_MESSAGE_CONTENT_IN_ICLOUD = -2146431226,
    EDP_IOS_ACTIVATION_FAILED_SIM_NOT_SUPPORTED = -2146431225,
    EDP_IOS_ACTIVATION_FAILED_ACTIVATION_SESSION_EXPIRED = -2146431224,
    EDP_IOS_ACTIVATION_FAILED_UNKNOWN_ERROR = -2146431223,
    EDP_IOS_SETUP_CONFIGURATION_ERROR = -2146431222,
    EDP_IOS_SETUP_WIFI_ERROR = -2146431221,
    EDP_IOS_SETUP_LANGUAGE_ERROR = -2146431220,
    EDP_IOS_iCLOUD_RESTORE_IN_PROGRESS = -2146431219
}

export enum PCInstallErrors {
    EDP_SERVICE_INSTALL_PACKAGE_FAILED = -2146430976,
    EDP_SERVICE_UPDATE_PRODUCT_FAILED = -2146430975,
    EDP_SERVICE_IMAGE_PATH_UPDATE_FAILED = -2146430974,
    EDP_SERVICE_FIREWALL_EXCEPTION_FAILED = -2146430973,
    EVERIFY_UNKNOWN = -1342177280,
    EVERIFY_OK = -1342177279,
    EVERIFY_CANCELLED = -1342177278,
    EVERIFY_FAILED_MASK = -1207959552,
    EVERIFY_FAILED = -1207959551,
    EVERIFY_SESSION_ONGOING = -1207959550,
    EVERIFY_NO_CONFIGURATION = -1207959549,
    EVERIFY_NO_SESSION = -1207959548,
    EVERIFY_INVALID_PARAMETER = -1207959547,
    EVERIFY_NETWORK_REQUEST_FAILED = -1207959546,
    EVERIFY_NO_FEATURE = -1207959545,
    EVERIFY_NO_CREDITS = -1207959544,
    EVERIFY_NO_PERMISSIONS = -1207959543,
    DP_UNKNOWN = -1,
    EDP_INVALID_COMPUTER_TIME = -2146430972,
    EVERIFY_GSMA_BUNDLE_EXPIRED = -1207959542
}

export enum ServerErrors {
    EDP_INTERNAL_ERROR = -1,
    EDP_SIGNATURE_ERROR = 1000,
    EDP_AUTHENTICATION_ERROR = 1001,
    EDP_INVALID_PARAMETERS = 1002,
    EDP_DATABASE_ERROR = 1003,
    EDP_UNKNOWN_COMMAND = 1004,
    EDP_INVALID_REQUEST = 1005,
    EDP_SERVICE_UNAVAILABLE = 503,
    EDP_NOT_IN_HEADERS = 400,
    EDP_INVALID_CONTENT_TYPE = 415,
    EDP_PRODUCT_ID_NOT_VALID = 403
}

export enum SessionApiErrors  {
    EDP_MISSING_PARAMETERS = 1002
}


export type PiceaGetApiError = {
    status: number
    details: {
        error: number
        message: string
    },
    error: {
        error: number
        error_text: string
    }
}

/**
 * Error object from api method callback param
 */
export type PiceaApiError = {
    // status: number; // api may send this, but it is -1. Deprecated, use details.error instead
    details: {
        error: number;
        message: string;
    };
    source: string;
}



export const errorDescriber = (error: number): string => {

    if (error !== null && error > 255) 
        error = Int32Array.from(Uint32Array.of(error))[0];

    switch (error) {
        case GenericErrors.EDP_UNKNOWN_ERROR: return strings.PICEA.ERRORS.GENERIC.EDP_UNKNOWN_ERROR;
        case GenericErrors.EDP_UNKNOWN_EXCEPTION: return strings.PICEA.ERRORS.GENERIC.EDP_UNKNOWN_EXCEPTION;
        case GenericErrors.EDP_INVALID_POINTER: return strings.PICEA.ERRORS.GENERIC.EDP_INVALID_POINTER;
        case GenericErrors.EDP_INVALID_PARAMETER: return strings.PICEA.ERRORS.GENERIC.EDP_INVALID_PARAMETER;
        case GenericErrors.EDP_INVALID_HANDLE: return strings.PICEA.ERRORS.GENERIC.EDP_INVALID_HANDLE;
        case GenericErrors.EDP_INVALID_VALUE: return strings.PICEA.ERRORS.GENERIC.EDP_INVALID_VALUE;
        case GenericErrors.EDP_NOT_ENOUGH_MEMORY: return strings.PICEA.ERRORS.GENERIC.EDP_NOT_ENOUGH_MEMORY;
        case GenericErrors.EDP_NOT_INITIALIZED: return strings.PICEA.ERRORS.GENERIC.EDP_NOT_INITIALIZED;
        case GenericErrors.EDP_INIT_FAILED: return strings.PICEA.ERRORS.GENERIC.EDP_INIT_FAILED;
        case GenericErrors.EDP_NOT_SUPPORTED_VERSION: return strings.PICEA.ERRORS.GENERIC.EDP_NOT_SUPPORTED_VERSION;
        case GenericErrors.EDP_CANCELLED: return strings.PICEA.ERRORS.GENERIC.EDP_CANCELLED;
        case GenericErrors.EDP_FAILED_TIMEOUT: return strings.PICEA.ERRORS.GENERIC.EDP_FAILED_TIMEOUT;
        case GenericErrors.EDP_NOT_FOUND: return strings.PICEA.ERRORS.GENERIC.EDP_NOT_FOUND;
        case GenericErrors.EDP_ALREADY_EXIST: return strings.PICEA.ERRORS.GENERIC.EDP_ALREADY_EXIST;
        case GenericErrors.EDP_ALL_LISTED: return strings.PICEA.ERRORS.GENERIC.EDP_ALL_LISTED;
        case GenericErrors.EDP_FAILED: return strings.PICEA.ERRORS.GENERIC.EDP_FAILED;
        case GenericErrors.EDP_BUSY: return strings.PICEA.ERRORS.GENERIC.EDP_BUSY;
        case GenericErrors.EDP_NOT_ALLOWED: return strings.PICEA.ERRORS.GENERIC.EDP_NOT_ALLOWED;
        case GenericErrors.EDP_NOT_IMPLEMENTED: return strings.PICEA.ERRORS.GENERIC.EDP_NOT_IMPLEMENTED;
        case GenericErrors.EDP_NOT_SUPPORTED: return strings.PICEA.ERRORS.GENERIC.EDP_NOT_SUPPORTED;
        case GenericErrors.EDP_NOT_SUPPORTED_1: return strings.PICEA.ERRORS.GENERIC.EDP_NOT_SUPPORTED_1;
        case GenericErrors.UNEXPECTED_ERROR: return  strings.PICEA.ERRORS.GENERIC.UNEXPECTED_ERROR;
        case GenericErrors.EDP_NOT_SUPPORTED_255: return strings.PICEA.ERRORS.GENERIC.EDP_NOT_SUPPORTED_255;
        case GenericErrors.EDP_NOT_SUPPORTED_INTERFACE: return strings.PICEA.ERRORS.GENERIC.EDP_NOT_SUPPORTED_INTERFACE;
        case GenericErrors.EDP_NOT_SUPPORTED_DEVICE: return strings.PICEA.ERRORS.GENERIC.EDP_NOT_SUPPORTED_DEVICE;
        case GenericErrors.EDP_NOT_SUPPORTED_TYPE: return strings.PICEA.ERRORS.GENERIC.EDP_NOT_SUPPORTED_TYPE;
        case GenericErrors.EDP_NOT_SUPPORTED_FORMAT: return strings.PICEA.ERRORS.GENERIC.EDP_NOT_SUPPORTED_FORMAT;
        case GenericErrors.EDP_NO_PERMISSION: return strings.PICEA.ERRORS.GENERIC.EDP_NO_PERMISSION;
        case GenericErrors.EDP_CLIENT_ID_NOT_FOUND: return strings.PICEA.ERRORS.GENERIC.EDP_CLIENT_ID_NOT_FOUND;
        case GenericErrors.EDP_CONNECTION_TO_SERVER_FAILED: return strings.PICEA.ERRORS.GENERIC.EDP_CONNECTION_TO_SERVER_FAILED;
        case GenericErrors.EDP_OUT_OF_RANGE: return strings.PICEA.ERRORS.GENERIC.EDP_OUT_OF_RANGE;
        case GenericErrors.EDP_NO_DATA: return strings.PICEA.ERRORS.GENERIC.EDP_NO_DATA;
        case GenericErrors.EDP_NO_MORE_DATA: return strings.PICEA.ERRORS.GENERIC.EDP_NO_MORE_DATA;
        case GenericErrors.EDP_DIFFERENT_VARIANT_TYPE: return strings.PICEA.ERRORS.GENERIC.EDP_DIFFERENT_VARIANT_TYPE;
        case GenericErrors.EDP_INVALID_VARIANT_TYPE: return strings.PICEA.ERRORS.GENERIC.EDP_INVALID_VARIANT_TYPE;
        case GenericErrors.EDP_INVALID_VARIANT_DATA: return strings.PICEA.ERRORS.GENERIC.EDP_INVALID_VARIANT_DATA;
        case GenericErrors.EDP_BUFFER_TOO_SMALL: return strings.PICEA.ERRORS.GENERIC.EDP_BUFFER_TOO_SMALL;
        case GenericErrors.EDP_NOT_AVAILABLE: return strings.PICEA.ERRORS.GENERIC.EDP_NOT_AVAILABLE;
        case GenericErrors.EDP_NOT_SUPPORTED_COMMAND_ID: return strings.PICEA.ERRORS.GENERIC.EDP_NOT_SUPPORTED_COMMAND_ID;
        case GenericErrors.EDP_SESSION_NOT_FOUND: return strings.PICEA.ERRORS.GENERIC.EDP_SESSION_NOT_FOUND;
        case GenericErrors.EDP_NO_INTERFACE: return strings.PICEA.ERRORS.GENERIC.EDP_NO_INTERFACE;
        case GenericErrors.EDP_TRANSCODER_MEDIATYPE_PIN_NOT_FOUND: return strings.PICEA.ERRORS.GENERIC.EDP_TRANSCODER_MEDIATYPE_PIN_NOT_FOUND;
        case GenericErrors.EDP_TRANSCODER_PIN_NOT_FOUND: return strings.PICEA.ERRORS.GENERIC.EDP_TRANSCODER_PIN_NOT_FOUND;
        case GenericErrors.EDP_TRANSCODER_NOT_SUPPORTED: return strings.PICEA.ERRORS.GENERIC.EDP_TRANSCODER_NOT_SUPPORTED;
        case GenericErrors.EDP_NOT_SUPPORTED_OUTPUT_FORMAT: return strings.PICEA.ERRORS.GENERIC.EDP_NOT_SUPPORTED_OUTPUT_FORMAT;
        case GenericErrors.EDP_TRANSCODER_INVALID_FILE_FORMAT: return strings.PICEA.ERRORS.GENERIC.EDP_TRANSCODER_INVALID_FILE_FORMAT;
        case GenericErrors.EDP_NOT_SUPPORTED_INPUT_FORMAT: return strings.PICEA.ERRORS.GENERIC.EDP_NOT_SUPPORTED_INPUT_FORMAT;
        case GenericErrors.EDP_TRANSCODER_DRM_PROTECTED_INPUT_FILE: return strings.PICEA.ERRORS.GENERIC.EDP_TRANSCODER_DRM_PROTECTED_INPUT_FILE;
        case GenericErrors.EDP_SIZE_IS_TOO_BIG: return strings.PICEA.ERRORS.GENERIC.EDP_SIZE_IS_TOO_BIG;
        case GenericErrors.EDP_APPLICATION_RUNNING: return strings.PICEA.ERRORS.GENERIC.EDP_APPLICATION_RUNNING;
        case GenericErrors.EDP_STORAGE_NOT_FOUND: return strings.PICEA.ERRORS.GENERIC.EDP_STORAGE_NOT_FOUND;
        case GenericErrors.EDP_NO_CLIENTS: return strings.PICEA.ERRORS.GENERIC.EDP_NO_CLIENTS;
        case GenericErrors.EDP_MISSING_PARAMETER: return strings.PICEA.ERRORS.GENERIC.EDP_MISSING_PARAMETER;
        case GenericErrors.EDP_SERVICE_NOT_FOUND: return strings.PICEA.ERRORS.GENERIC.EDP_SERVICE_NOT_FOUND;
        case GenericErrors.EDP_LIBRARY_NOT_FOUND: return strings.PICEA.ERRORS.GENERIC.EDP_LIBRARY_NOT_FOUND;
        case GenericErrors.EDP_ITEM_EXEC_SKIPPED: return strings.PICEA.ERRORS.GENERIC.EDP_ITEM_EXEC_SKIPPED;
        case GenericErrors.EDP_ITEM_EXEC_USER_FAIL: return strings.PICEA.ERRORS.GENERIC.EDP_ITEM_EXEC_USER_FAIL;
        case GenericErrors.EDP_ITEM_EXEC_NOT_EXECUTED: return strings.PICEA.ERRORS.GENERIC.EDP_ITEM_EXEC_NOT_EXECUTED;
        case GenericErrors.EDP_SW_VERSION_TOO_OLD: return strings.PICEA.ERRORS.GENERIC.EDP_SW_VERSION_TOO_OLD;
        case GenericErrors.EDP_PROTOCOL_NOT_FOUND: return strings.PICEA.ERRORS.GENERIC.EDP_PROTOCOL_NOT_FOUND;
        case GenericErrors.EDP_INVALID_DATA: return strings.PICEA.ERRORS.GENERIC.EDP_INVALID_DATA;
        case GenericErrors.EDP_NOT_SUPPORTED_FID: return strings.PICEA.ERRORS.GENERIC.EDP_NOT_SUPPORTED_FID;
        case GenericErrors.EDP_NOT_SUPPORTED_MANUFACTURER: return strings.PICEA.ERRORS.GENERIC.EDP_NOT_SUPPORTED_MANUFACTURER;
        case GenericErrors.EDP_NOT_SUPPORTED_MODEL: return strings.PICEA.ERRORS.GENERIC.EDP_NOT_SUPPORTED_MODEL;
        case GenericErrors.EDP_DRIVER_MISSING: return strings.PICEA.ERRORS.GENERIC.EDP_DRIVER_MISSING;
        case GenericErrors.EDP_DRIVER_NOT_FUNCTIONAL: return strings.PICEA.ERRORS.GENERIC.EDP_DRIVER_NOT_FUNCTIONAL;
        case GenericErrors.EDP_DRIVER_UPDATE_NEEDED: return strings.PICEA.ERRORS.GENERIC.EDP_DRIVER_UPDATE_NEEDED;
        case GenericErrors.EDP_DYNAMIC_MODULE_INSTALL_FAILED: return strings.PICEA.ERRORS.GENERIC.EDP_DYNAMIC_MODULE_INSTALL_FAILED;
        case GenericErrors.EDP_TRADE_IN_CURRENCY_MISSING: return strings.PICEA.ERRORS.GENERIC.EDP_TRADE_IN_CURRENCY_MISSING;
        case GenericErrors.EDP_NOT_SUPPORTED_ITEM: return strings.PICEA.ERRORS.GENERIC.EDP_NOT_SUPPORTED_ITEM;
        case GenericErrors.EDP_REPORTING_CONFIGURATION_FAILURE: return strings.PICEA.ERRORS.GENERIC.EDP_REPORTING_CONFIGURATION_FAILURE;
        case GenericErrors.EDP_EXTERNAL_API_FAILURE: return strings.PICEA.ERRORS.GENERIC.EDP_EXTERNAL_API_FAILURE;
        case GenericErrors.EDP_INVALID_DATETIME: return strings.PICEA.ERRORS.GENERIC.EDP_INVALID_DATETIME;
        case GenericErrors.EDP_FOLDER_CACHE_FOLDER_FAILURE: return strings.PICEA.ERRORS.GENERIC.EDP_FOLDER_CACHE_FOLDER_FAILURE;
        case GenericErrors.EDP_TERMINATED: return strings.PICEA.ERRORS.GENERIC.EDP_TERMINATED;
        case GenericErrors.EDP_ITEM_TRANSFER_SKIPPED: return strings.PICEA.ERRORS.GENERIC.EDP_ITEM_TRANSFER_SKIPPED;
        case GenericErrors.EDP_TRANSFER_ALL_FAILED: return strings.PICEA.ERRORS.GENERIC.EDP_TRANSFER_ALL_FAILED;
        case GenericErrors.EDP_MANDATORY_UPDATE_REQUIRED: return strings.PICEA.ERRORS.GENERIC.EDP_MANDATORY_UPDATE_REQUIRED;
        case GenericErrors.EDP_TOO_FREQUENT_OPERATION_FILTER_ACTIVE: return strings.PICEA.ERRORS.GENERIC.EDP_TOO_FREQUENT_OPERATION_FILTER_ACTIVE;
        case GenericErrors.EDP_PICEASETUP_NOT_FOUND: return strings.PICEA.ERRORS.GENERIC.EDP_PICEASETUP_NOT_FOUND;
        case GenericErrors.EDP_VPP_INIT_ERROR: return strings.PICEA.ERRORS.GENERIC.EDP_VPP_INIT_ERROR;
        
        
        case BusErrors.EDP_BUS_NOT_FUNCTIONAL: return strings.PICEA.ERRORS.BUS.EDP_BUS_NOT_FUNCTIONAL;
        
        case SessionApiErrors.EDP_MISSING_PARAMETERS: return strings.PICEA.ERRORS.GENERIC.EDP_INVALID_PARAMETER;

        case LicenseErrors.EDP_LICENSE_MISSING: return strings.PICEA.ERRORS.LICENSE.EDP_LICENSE_MISSING;
        case LicenseErrors.EDP_PRODUCT_EXPIRED: return strings.PICEA.ERRORS.LICENSE.EDP_PRODUCT_EXPIRED;
        case LicenseErrors.EDP_LICENSE_EXPIRED: return strings.PICEA.ERRORS.LICENSE.EDP_LICENSE_EXPIRED;
        case LicenseErrors.EDP_INVALID_PRODUCT_ID: return strings.PICEA.ERRORS.LICENSE.EDP_INVALID_PRODUCT_ID;
        case LicenseErrors.EDP_ACTIVATION_FAILED: return strings.PICEA.ERRORS.LICENSE.EDP_ACTIVATION_FAILED;
        case LicenseErrors.EDP_ACTIVATION_FAILED_INVALID_LICENSE: return strings.PICEA.ERRORS.LICENSE.EDP_ACTIVATION_FAILED_INVALID_LICENSE;
        case LicenseErrors.EDP_ACTIVATION_FAILED_NO_FREE_LICENSES: return strings.PICEA.ERRORS.LICENSE.EDP_ACTIVATION_FAILED_NO_FREE_LICENSES;
        case LicenseErrors.EDP_ACTIVATION_FAILED_ALREADY_USED: return strings.PICEA.ERRORS.LICENSE.EDP_ACTIVATION_FAILED_ALREADY_USED;
        case LicenseErrors.EDP_ACTIVATION_REQUIRED: return strings.PICEA.ERRORS.LICENSE.EDP_ACTIVATION_REQUIRED;
        case LicenseErrors.EDP_PRODUCT_NOT_SUPPORTED: return strings.PICEA.ERRORS.LICENSE.EDP_PRODUCT_NOT_SUPPORTED;
        case LicenseErrors.EDP_PRODUCT_TOO_OLD: return strings.PICEA.ERRORS.LICENSE.EDP_PRODUCT_TOO_OLD;
        case LicenseErrors.EDP_BUCKET_NOT_FOUND: return strings.PICEA.ERRORS.LICENSE.EDP_BUCKET_NOT_FOUND;
        case LicenseErrors.EDP_BUCKET_EXPIRED: return strings.PICEA.ERRORS.LICENSE.EDP_BUCKET_EXPIRED;
        case LicenseErrors.EDP_BUCKET_EMPTY: return strings.PICEA.ERRORS.LICENSE.EDP_BUCKET_EMPTY;
        case LicenseErrors.EDP_BUCKET_ALLOCATION_NOT_FOUND: return strings.PICEA.ERRORS.LICENSE.EDP_BUCKET_ALLOCATION_NOT_FOUND;
        case LicenseErrors.EDP_CODE_NOT_FOUND: return strings.PICEA.ERRORS.LICENSE.EDP_CODE_NOT_FOUND;
        case LicenseErrors.EDP_CODE_USED: return strings.PICEA.ERRORS.LICENSE.EDP_CODE_USED;
        case LicenseErrors.EDP_CODE_EXPIRED: return strings.PICEA.ERRORS.LICENSE.EDP_CODE_EXPIRED;
        case LicenseErrors.EDP_CODE_NOT_SUPPORTED: return strings.PICEA.ERRORS.LICENSE.EDP_CODE_NOT_SUPPORTED;
        case LicenseErrors.EDP_STOLEN_CHECK_BUCKET_EMPTY: return strings.PICEA.ERRORS.LICENSE.EDP_STOLEN_CHECK_BUCKET_EMPTY;
        case LicenseErrors.EDP_LICENSE_CHAIN_DEACTIVATED: return strings.PICEA.ERRORS.LICENSE.EDP_LICENSE_CHAIN_DEACTIVATED;

        case DatabaseErrors.EDP_DATABASE_ERROR: return strings.PICEA.ERRORS.DATABASE.EDP_DATABASE_ERROR;
        case DatabaseErrors.EDP_DATABASE_INTERNAL: return strings.PICEA.ERRORS.DATABASE.EDP_DATABASE_INTERNAL;
        case DatabaseErrors.EDP_DATABASE_PERM: return strings.PICEA.ERRORS.DATABASE.EDP_DATABASE_PERM;
        case DatabaseErrors.EDP_DATABASE_ABORT: return strings.PICEA.ERRORS.DATABASE.EDP_DATABASE_ABORT;
        case DatabaseErrors.EDP_DATABASE_BUSY: return strings.PICEA.ERRORS.DATABASE.EDP_DATABASE_BUSY;
        case DatabaseErrors.EDP_DATABASE_LOCKED: return strings.PICEA.ERRORS.DATABASE.EDP_DATABASE_LOCKED;
        case DatabaseErrors.EDP_DATABASE_NOMEM: return strings.PICEA.ERRORS.DATABASE.EDP_DATABASE_NOMEM;
        case DatabaseErrors.EDP_DATABASE_READONLY: return strings.PICEA.ERRORS.DATABASE.EDP_DATABASE_READONLY;
        case DatabaseErrors.EDP_DATABASE_INTERRUPT: return strings.PICEA.ERRORS.DATABASE.EDP_DATABASE_INTERRUPT;
        case DatabaseErrors.EDP_DATABASE_IOERR: return strings.PICEA.ERRORS.DATABASE.EDP_DATABASE_IOERR;
        case DatabaseErrors.EDP_DATABASE_CORRUPT: return strings.PICEA.ERRORS.DATABASE.EDP_DATABASE_CORRUPT;
        case DatabaseErrors.EDP_DATABASE_FULL: return strings.PICEA.ERRORS.DATABASE.EDP_DATABASE_FULL;
        case DatabaseErrors.EDP_DATABASE_CANTOPEN: return strings.PICEA.ERRORS.DATABASE.EDP_DATABASE_CANTOPEN;
        case DatabaseErrors.EDP_DATABASE_EMPTY: return strings.PICEA.ERRORS.DATABASE.EDP_DATABASE_EMPTY;
        case DatabaseErrors.EDP_DATABASE_SCHEMA: return strings.PICEA.ERRORS.DATABASE.EDP_DATABASE_SCHEMA;
        case DatabaseErrors.EDP_DATABASE_TOOBIG: return strings.PICEA.ERRORS.DATABASE.EDP_DATABASE_TOOBIG;
        case DatabaseErrors.EDP_DATABASE_CONSTRAINT: return strings.PICEA.ERRORS.DATABASE.EDP_DATABASE_CONSTRAINT;
        case DatabaseErrors.EDP_DATABASE_MISMATCH: return strings.PICEA.ERRORS.DATABASE.EDP_DATABASE_MISMATCH;
        case DatabaseErrors.EDP_DATABASE_MISUSE: return strings.PICEA.ERRORS.DATABASE.EDP_DATABASE_MISUSE;
        case DatabaseErrors.EDP_DATABASE_NOLFS: return strings.PICEA.ERRORS.DATABASE.EDP_DATABASE_NOLFS;
        case DatabaseErrors.EDP_DATABASE_AUTH: return strings.PICEA.ERRORS.DATABASE.EDP_DATABASE_AUTH;
        case DatabaseErrors.EDP_DATABASE_FORMAT: return strings.PICEA.ERRORS.DATABASE.EDP_DATABASE_FORMAT;
        case DatabaseErrors.EDP_DATABASE_RANGE: return strings.PICEA.ERRORS.DATABASE.EDP_DATABASE_RANGE;
        case DatabaseErrors.EDP_DATABASE_NOTADB: return strings.PICEA.ERRORS.DATABASE.EDP_DATABASE_NOTADB;

        case ObjectErrors.EDP_PROP_ID_NOT_SUPPORTED: return strings.PICEA.ERRORS.OBJECT.EDP_PROP_ID_NOT_SUPPORTED;
        case ObjectErrors.EDP_PROP_ID_OUT_OF_RANGE: return strings.PICEA.ERRORS.OBJECT.EDP_PROP_ID_OUT_OF_RANGE;
        case ObjectErrors.EDP_PROP_ID_NOT_FOUND: return strings.PICEA.ERRORS.OBJECT.EDP_PROP_ID_NOT_FOUND;
        case ObjectErrors.EDP_INVALID_PROP_DATA: return strings.PICEA.ERRORS.OBJECT.EDP_INVALID_PROP_DATA;
        case ObjectErrors.EDP_API_NOT_FOUND: return strings.PICEA.ERRORS.OBJECT.EDP_API_NOT_FOUND;
        case ObjectErrors.EDP_API_FUNCTION_NOT_FOUND: return strings.PICEA.ERRORS.OBJECT.EDP_API_FUNCTION_NOT_FOUND;
        case ObjectErrors.EDP_FUNCTION_NOT_FOUND: return strings.PICEA.ERRORS.OBJECT.EDP_FUNCTION_NOT_FOUND;

        case DeviceErrors.EDP_DEVICE_NOT_FOUND: return strings.PICEA.ERRORS.DEVICE.EDP_DEVICE_NOT_FOUND;
        case DeviceErrors.EDP_NOT_SUPPORTED_CONNECTION_TYPE: return strings.PICEA.ERRORS.DEVICE.EDP_NOT_SUPPORTED_CONNECTION_TYPE;
        case DeviceErrors.EDP_CONNECTION_BUSY: return strings.PICEA.ERRORS.DEVICE.EDP_CONNECTION_BUSY;
        case DeviceErrors.EDP_CONNECTION_LOST: return strings.PICEA.ERRORS.DEVICE.EDP_CONNECTION_LOST;
        case DeviceErrors.EDP_CONNECTION_FAILED: return strings.PICEA.ERRORS.DEVICE.EDP_CONNECTION_FAILED;
        case DeviceErrors.EDP_DEVICE_LOCKED: return strings.PICEA.ERRORS.DEVICE.EDP_DEVICE_LOCKED;
        case DeviceErrors.EDP_DEVICE_UNKNOWN_ERROR: return strings.PICEA.ERRORS.DEVICE.EDP_DEVICE_UNKNOWN_ERROR;
        case DeviceErrors.EDP_DEVICE_NOT_RESPONDING: return strings.PICEA.ERRORS.DEVICE.EDP_DEVICE_NOT_RESPONDING;
        case DeviceErrors.EDP_DEVICE_NO_SIM_CARD: return strings.PICEA.ERRORS.DEVICE.EDP_DEVICE_NO_SIM_CARD;
        case DeviceErrors.EDP_DEVICE_NO_PERMISSION: return strings.PICEA.ERRORS.DEVICE.EDP_DEVICE_NO_PERMISSION;
        case DeviceErrors.EDP_DEVICE_UI_ACTION_FAILED: return strings.PICEA.ERRORS.DEVICE.EDP_DEVICE_UI_ACTION_FAILED;
        case DeviceErrors.EDP_DEVICE_TRUST_CONFIRM_NEEDED: return strings.PICEA.ERRORS.DEVICE.EDP_DEVICE_TRUST_CONFIRM_NEEDED;
        case DeviceErrors.EDP_DEVICE_NOT_READY: return strings.PICEA.ERRORS.DEVICE.EDP_DEVICE_NOT_READY;
        case DeviceErrors.EDP_DEVICE_NOT_ACTIVATED: return strings.PICEA.ERRORS.DEVICE.EDP_DEVICE_NOT_ACTIVATED;
        case DeviceErrors.EDP_DEVICE_SETUP_NOT_FINISHED: return strings.PICEA.ERRORS.DEVICE.EDP_DEVICE_SETUP_NOT_FINISHED;
        case DeviceErrors.EDP_DEVICE_RESERVED: return strings.PICEA.ERRORS.DEVICE.EDP_DEVICE_RESERVED;
        case DeviceErrors.EDP_DEVICE_BATTERY_LEVEL_TOO_LOW: return strings.PICEA.ERRORS.DEVICE.EDP_DEVICE_BATTERY_LEVEL_TOO_LOW;
        case DeviceErrors.EDP_DEVICE_ALREADY_ACTIVATED: return strings.PICEA.ERRORS.DEVICE.EDP_DEVICE_ALREADY_ACTIVATED;
        case DeviceErrors.EDP_DEVICE_SIM_PIN_LOCKED: return strings.PICEA.ERRORS.DEVICE.EDP_DEVICE_SIM_PIN_LOCKED;
        case DeviceErrors.EDP_DEVICE_ADB_INSTALL_REJECTED: return strings.PICEA.ERRORS.DEVICE.EDP_DEVICE_ADB_INSTALL_REJECTED;
        case DeviceErrors.EDP_DEVICE_STOLEN_CHECK_NOT_SUPPORTED: return strings.PICEA.ERRORS.DEVICE.EDP_DEVICE_STOLEN_CHECK_NOT_SUPPORTED;
        case DeviceErrors.EDP_DEVICE_CONNECTOR_INSTALL_ONGOING: return strings.PICEA.ERRORS.DEVICE.EDP_DEVICE_CONNECTOR_INSTALL_ONGOING;
        case DeviceErrors.EDP_DEVICE_IOS_BACKUP_PASSWORD_LEFT: return strings.PICEA.ERRORS.DEVICE.EDP_DEVICE_IOS_BACKUP_PASSWORD_LEFT;
        case DeviceErrors.EDP_DEVICE_STOLEN_CHECK_UNKNOWN_RESPONSE: return strings.PICEA.ERRORS.DEVICE.EDP_DEVICE_STOLEN_CHECK_UNKNOWN_RESPONSE;
        case DeviceErrors.EDP_DEVICE_RECONNECTION_NOT_DETECTED: return strings.PICEA.ERRORS.DEVICE.EDP_DEVICE_RECONNECTION_NOT_DETECTED;


        case FileSystemErrors.EDP_STORAGE_FULL: return strings.PICEA.ERRORS.FILESYSTEM.EDP_STORAGE_FULL;
        case FileSystemErrors.EDP_MEMORY_FULL: return strings.PICEA.ERRORS.FILESYSTEM.EDP_MEMORY_FULL;
        case FileSystemErrors.EDP_FILE_OPENING_FAILED: return strings.PICEA.ERRORS.FILESYSTEM.EDP_FILE_OPENING_FAILED;
        case FileSystemErrors.EDP_FILE_NO_PERMISSION: return strings.PICEA.ERRORS.FILESYSTEM.EDP_FILE_NO_PERMISSION;
        case FileSystemErrors.EDP_FILE_NOT_FOUND: return strings.PICEA.ERRORS.FILESYSTEM.EDP_FILE_NOT_FOUND;
        case FileSystemErrors.EDP_FOLDER_NOT_FOUND: return strings.PICEA.ERRORS.FILESYSTEM.EDP_FOLDER_NOT_FOUND;
        case FileSystemErrors.EDP_FOLDER_PATH_TOO_LONG: return strings.PICEA.ERRORS.FILESYSTEM.EDP_FOLDER_PATH_TOO_LONG;
        case FileSystemErrors.EDP_FILE_NAME_TOO_LONG: return strings.PICEA.ERRORS.FILESYSTEM.EDP_FILE_NAME_TOO_LONG;
        case FileSystemErrors.EDP_INVALID_FILE_NAME: return strings.PICEA.ERRORS.FILESYSTEM.EDP_INVALID_FILE_NAME;
        case FileSystemErrors.EDP_INVALID_FOLDER_NAME: return strings.PICEA.ERRORS.FILESYSTEM.EDP_INVALID_FOLDER_NAME;
        case FileSystemErrors.EDP_FILE_WRITING_FAILED: return strings.PICEA.ERRORS.FILESYSTEM.EDP_FILE_WRITING_FAILED;
        case FileSystemErrors.EDP_FOLDER_NOT_EMPTY: return strings.PICEA.ERRORS.FILESYSTEM.EDP_FOLDER_NOT_EMPTY;
        case FileSystemErrors.EDP_FILE_BUSY: return strings.PICEA.ERRORS.FILESYSTEM.EDP_FILE_BUSY;
        case FileSystemErrors.EDP_STORAGE_READ_ONLY: return strings.PICEA.ERRORS.FILESYSTEM.EDP_STORAGE_READ_ONLY;
        case FileSystemErrors.EDP_FOLDER_CREATION_FAILED: return strings.PICEA.ERRORS.FILESYSTEM.EDP_FOLDER_CREATION_FAILED;
        case FileSystemErrors.EDP_FILE_DELETION_FAILED: return strings.PICEA.ERRORS.FILESYSTEM.EDP_FILE_DELETION_FAILED;
        case FileSystemErrors.EDP_FILE_RENAME_FAILED: return strings.PICEA.ERRORS.FILESYSTEM.EDP_FILE_RENAME_FAILED;
        case FileSystemErrors.EDP_PC_STORAGE_FULL: return strings.PICEA.ERRORS.FILESYSTEM.EDP_PC_STORAGE_FULL;
        case FileSystemErrors.EDP_INVALID_FILE_DATA: return strings.PICEA.ERRORS.FILESYSTEM.EDP_INVALID_FILE_DATA;
        case FileSystemErrors.EDP_FILE_READING_FAILED: return strings.PICEA.ERRORS.FILESYSTEM.EDP_FILE_READING_FAILED;
        
        case InstallerErrors.EDP_DEVICE_INSTALLER_BUSY: return strings.PICEA.ERRORS.INSTALLER.EDP_DEVICE_INSTALLER_BUSY;
        case InstallerErrors.EDP_DEVICE_INSTALLER_USER_CANCEL: return strings.PICEA.ERRORS.INSTALLER.EDP_DEVICE_INSTALLER_USER_CANCEL;
        case InstallerErrors.EDP_DEVICE_INSTALLER_FILE_CORRUPTED: return strings.PICEA.ERRORS.INSTALLER.EDP_DEVICE_INSTALLER_FILE_CORRUPTED;
        case InstallerErrors.EDP_DEVICE_INSTALLER_INSUFFICIENT_MEMORY: return strings.PICEA.ERRORS.INSTALLER.EDP_DEVICE_INSTALLER_INSUFFICIENT_MEMORY;
        case InstallerErrors.EDP_DEVICE_INSTALLER_PACKAGE_NOT_SUPPORTED: return strings.PICEA.ERRORS.INSTALLER.EDP_DEVICE_INSTALLER_PACKAGE_NOT_SUPPORTED;
        case InstallerErrors.EDP_DEVICE_INSTALLER_SECURITY_FAILURE: return strings.PICEA.ERRORS.INSTALLER.EDP_DEVICE_INSTALLER_SECURITY_FAILURE;
        case InstallerErrors.EDP_DEVICE_INSTALLER_MISSING_DEPENDENCY: return strings.PICEA.ERRORS.INSTALLER.EDP_DEVICE_INSTALLER_MISSING_DEPENDENCY;
        case InstallerErrors.EDP_DEVICE_INSTALLER_FILE_IN_USE: return strings.PICEA.ERRORS.INSTALLER.EDP_DEVICE_INSTALLER_FILE_IN_USE;
        case InstallerErrors.EDP_DEVICE_INSTALLER_GENERAL_ERROR: return strings.PICEA.ERRORS.INSTALLER.EDP_DEVICE_INSTALLER_GENERAL_ERROR;
        case InstallerErrors.EDP_DEVICE_INSTALLER_NO_RIGHTS: return strings.PICEA.ERRORS.INSTALLER.EDP_DEVICE_INSTALLER_NO_RIGHTS;
        case InstallerErrors.EDP_DEVICE_INSTALLER_NETWORK_FAILURE: return strings.PICEA.ERRORS.INSTALLER.EDP_DEVICE_INSTALLER_NETWORK_FAILURE;
        case InstallerErrors.EDP_DEVICE_INSTALLER_ACCESS_DENIED: return strings.PICEA.ERRORS.INSTALLER.EDP_DEVICE_INSTALLER_ACCESS_DENIED;
        case InstallerErrors.EDP_DEVICE_INSTALLER_UPGRADE_ERROR: return strings.PICEA.ERRORS.INSTALLER.EDP_DEVICE_INSTALLER_UPGRADE_ERROR;
        case InstallerErrors.EDP_DEVICE_INSTALLER_UNINSTALL_FAILED: return strings.PICEA.ERRORS.INSTALLER.EDP_DEVICE_INSTALLER_UNINSTALL_FAILED;
        case InstallerErrors.EDP_DEVICE_INSTALLER_INVALID_APP_ID: return strings.PICEA.ERRORS.INSTALLER.EDP_DEVICE_INSTALLER_INVALID_APP_ID;
        case InstallerErrors.EDP_DEVICE_INSTALLER_UNINSTALLABLE_APPLICATION: return strings.PICEA.ERRORS.INSTALLER.EDP_DEVICE_INSTALLER_UNINSTALLABLE_APPLICATION;
        case InstallerErrors.EDP_DEVICE_INSTALLER_USER_ACTION_NEEDED: return strings.PICEA.ERRORS.INSTALLER.EDP_DEVICE_INSTALLER_USER_ACTION_NEEDED;
        case InstallerErrors.EDP_DEVICE_INSTALLER_NO_SD_CARD_INSERTED: return strings.PICEA.ERRORS.INSTALLER.EDP_DEVICE_INSTALLER_NO_SD_CARD_INSERTED;
        case InstallerErrors.EDP_DEVICE_INSTALLER_WRONG_SD_CARD_INSERTED: return strings.PICEA.ERRORS.INSTALLER.EDP_DEVICE_INSTALLER_WRONG_SD_CARD_INSERTED;
        case InstallerErrors.EDP_DEVICE_INSTALLER_LKMERROR: return strings.PICEA.ERRORS.INSTALLER.EDP_DEVICE_INSTALLER_LKMERROR;

        case DRMErrors.EDP_DRM_NO_CREDENTIALS_SET: return strings.PICEA.ERRORS.DRM.EDP_DRM_NO_CREDENTIALS_SET;
        case DRMErrors.EDP_DRM_NO_LICENSE: return strings.PICEA.ERRORS.DRM.EDP_DRM_NO_LICENSE;

        case MessageErrors.EDP_DEVICE_MSGCENTRE_NOT_DEFINED: return strings.PICEA.ERRORS.MESSAGE.EDP_DEVICE_MSGCENTRE_NOT_DEFINED;

        case SyncErrors.EDP_DEVICE_UI_NOT_IDLE: return strings.PICEA.ERRORS.SYNC.EDP_DEVICE_UI_NOT_IDLE;
        case SyncErrors.EDP_NOT_SYNCHRONIZED: return strings.PICEA.ERRORS.SYNC.EDP_NOT_SYNCHRONIZED;
        case SyncErrors.EDP_DEVICE_ITEM_REJECT: return strings.PICEA.ERRORS.SYNC.EDP_DEVICE_ITEM_REJECT;

        case ErasingErrors.EDP_ERASER_OPERATION_FAILURE: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_OPERATION_FAILURE;
        case ErasingErrors.EDP_ERASER_USB_MASS_STORAGE_MOUNT_DETECTED: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_USB_MASS_STORAGE_MOUNT_DETECTED;
        case ErasingErrors.EDP_ERASER_CANNOT_READ_DETAILED_DEVICE_INFO: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_CANNOT_READ_DETAILED_DEVICE_INFO;
        case ErasingErrors.EDP_ERASER_OVERWRITE_FAILED: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_OVERWRITE_FAILED;
        case ErasingErrors.EDP_ERASER_DEVICE_STOLEN_WARNING: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_DEVICE_STOLEN_WARNING;
        case ErasingErrors.EDP_ERASER_DEVICE_STOLEN: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_DEVICE_STOLEN;
        case ErasingErrors.EDP_ERASER_DEVICE_RECONNECTION_NOT_DETECTED: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_DEVICE_RECONNECTION_NOT_DETECTED;
        case ErasingErrors.EDP_ERASER_VALIDATION_FAILED: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_VALIDATION_FAILED;
        case ErasingErrors.EDP_ERASER_ESIM_REMOVAL_FAILED: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_ESIM_REMOVAL_FAILED;
        case ErasingErrors.EDP_ERASER_AND_NO_DEVICE_TYPE_AVAILABLE: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_AND_NO_DEVICE_TYPE_AVAILABLE;
        case ErasingErrors.EDP_ERASER_AND_PROCESSING_ROOTED_DEVICES_NOT_ENABLED: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_AND_PROCESSING_ROOTED_DEVICES_NOT_ENABLED;
        case ErasingErrors.EDP_ERASER_AND_GRANTING_PRIVILEGED_ACCESS_FAILED: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_AND_GRANTING_PRIVILEGED_ACCESS_FAILED;
        case ErasingErrors.EDP_ERASER_AND_CANNOT_DELETE_DATA: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_AND_CANNOT_DELETE_DATA;
        case ErasingErrors.EDP_ERASER_AND_SCRIPT_NOT_RUN_WITH_PRIVILEGED_ACCESS: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_AND_SCRIPT_NOT_RUN_WITH_PRIVILEGED_ACCESS;
        case ErasingErrors.EDP_ERASER_AND_DEVICE_ADMINISTRATOR_ACTIVATION_FAILED: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_AND_DEVICE_ADMINISTRATOR_ACTIVATION_FAILED;
        case ErasingErrors.EDP_ERASER_AND_EXTERNAL_STORAGE_COUNT_MISMATCH: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_AND_EXTERNAL_STORAGE_COUNT_MISMATCH;
        case ErasingErrors.EDP_ERASER_AND_FACTORY_RESET_FAILED: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_AND_FACTORY_RESET_FAILED;
        case ErasingErrors.EDP_ERASER_AND_GENERATING_DELETE_SCRIPT_FAILED: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_AND_GENERATING_DELETE_SCRIPT_FAILED;
        case ErasingErrors.EDP_ERASER_AND_INSTALL_FAILED_INSUFFICIENT_STORAGE: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_AND_INSTALL_FAILED_INSUFFICIENT_STORAGE;
        case ErasingErrors.EDP_ERASER_AND_INSTALL_FAILED: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_AND_INSTALL_FAILED;
        case ErasingErrors.EDP_ERASER_AND_DEVICE_OWNER_ALREADY_PROVISIONED: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_AND_DEVICE_OWNER_ALREADY_PROVISIONED;
        case ErasingErrors.EDP_ERASER_AND_ADB_CANNOT_ACCESS_DEVICE: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_AND_ADB_CANNOT_ACCESS_DEVICE;
        case ErasingErrors.EDP_ERASER_IOS_IMAGE_DOWNLOAD_FAILED: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_IOS_IMAGE_DOWNLOAD_FAILED;
        case ErasingErrors.EDP_ERASER_IOS_RESTORE_PROCESS_FAILED: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_IOS_RESTORE_PROCESS_FAILED;
        case ErasingErrors.EDP_ERASER_IOS_NO_TEMPORARY_FOLDER_EXISTS: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_IOS_NO_TEMPORARY_FOLDER_EXISTS;
        case ErasingErrors.EDP_ERASER_IOS_USB_CONNECTIVITY_ISSUE_DURING_FLASHING: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_IOS_USB_CONNECTIVITY_ISSUE_DURING_FLASHING;
        case ErasingErrors.EDP_ERASER_IOS_ASR_ERROR_ITUNES_RESTORE_NEEDED: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_IOS_ASR_ERROR_ITUNES_RESTORE_NEEDED;
        case ErasingErrors.EDP_ERASER_IOS_BASEBAND_ERROR_ITUNES_RESTORE_NEEDED: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_IOS_BASEBAND_ERROR_ITUNES_RESTORE_NEEDED;
        case ErasingErrors.EDP_ERASER_IOS_IBEC_SENDING_ERROR: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_IOS_IBEC_SENDING_ERROR;
        case ErasingErrors.EDP_ERASER_IOS_APPLE_DEVICE_DETECTION_ERROR: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_IOS_APPLE_DEVICE_DETECTION_ERROR;
        case ErasingErrors.EDP_ERASER_IOS_IDEVICERESTORE_TIMEOUT_TRIGGERED: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_IOS_IDEVICERESTORE_TIMEOUT_TRIGGERED;
        case ErasingErrors.EDP_ERASER_IOS_IDEVICERESTORE_DEVICE_DETECTION_ERROR: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_IOS_IDEVICERESTORE_DEVICE_DETECTION_ERROR;
        case ErasingErrors.EDP_ERASER_IOS_IDEVICERESTORE_TSS_ERROR: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_IOS_IDEVICERESTORE_TSS_ERROR;
        case ErasingErrors.EDP_ERASER_IOS_INVALID_IMAGE_FILE: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_IOS_INVALID_IMAGE_FILE;
        case ErasingErrors.EDP_ERASER_WP_FLASH_PROCESS_FAILED: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_WP_FLASH_PROCESS_FAILED;
        case ErasingErrors.EDP_ERASER_WP_USE_WDRT_TOOL: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_WP_USE_WDRT_TOOL;
        case ErasingErrors.EDP_ERASER_AND_DEVICE_OWNER_FAILED: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_AND_DEVICE_OWNER_FAILED;
        case ErasingErrors.EDP_ERASER_IOS_PLACE_RECOVERY_MODE: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_IOS_PLACE_RECOVERY_MODE;
        case ErasingErrors.EDP_ERASER_IOS_ENTER_RECOVERY_MODE: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_IOS_ENTER_RECOVERY_MODE;
        case ErasingErrors.EDP_ERASER_IOS_PLACE_RESTORE_MODE: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_IOS_PLACE_RESTORE_MODE;
        case ErasingErrors.EDP_ERASER_IOS_ENTER_RESTORE_MODE: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_IOS_ENTER_RESTORE_MODE;
        case ErasingErrors.EDP_ERASER_INVALID_SERIAL_NUMBER_FORMAT: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_INVALID_SERIAL_NUMBER_FORMAT;
        case ErasingErrors.EDP_ERASER_INVALID_PIN_CODE_FORMAT: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_INVALID_PIN_CODE_FORMAT;
        case ErasingErrors.EDP_ERASER_INVALID_PIN_CODE_FORMAT_OLD_BB: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_INVALID_PIN_CODE_FORMAT_OLD_BB;
        case ErasingErrors.EDP_ERASER_STORAGE_NOT_SUPPORTED: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_STORAGE_NOT_SUPPORTED;
        case ErasingErrors.EDP_ERASER_PROTECTION_ACCOUNT_DETECTED: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_PROTECTION_ACCOUNT_DETECTED;
        case ErasingErrors.EDP_ERASER_SIM_DETECTED: return strings.PICEA.ERRORS.ERASING.EDP_ERASER_SIM_DETECTED;
        
        
        case SoftwareUpdateErrors.DP_SWUPDATE_ANDROID_LAUNCHED: return strings.PICEA.ERRORS.SOFTWARE_UPDATE.DP_SWUPDATE_ANDROID_LAUNCHED;
        case SoftwareUpdateErrors.DP_SWUPDATE_ANDROID_DOWNLOAD_STARTED: return strings.PICEA.ERRORS.SOFTWARE_UPDATE.DP_SWUPDATE_ANDROID_DOWNLOAD_STARTED;
        case SoftwareUpdateErrors.EDP_SWUPDATE_ANDROID_WIFI_SETTINGS_MISSING: return strings.PICEA.ERRORS.SOFTWARE_UPDATE.EDP_SWUPDATE_ANDROID_WIFI_SETTINGS_MISSING;
        case SoftwareUpdateErrors.EDP_SWUPDATE_ANDROID_WIFI_NOT_ENABLED: return strings.PICEA.ERRORS.SOFTWARE_UPDATE.EDP_SWUPDATE_ANDROID_WIFI_NOT_ENABLED;
        case SoftwareUpdateErrors.EDP_SWUPDATE_ANDROID_WIFI_NOT_CONNECTED: return strings.PICEA.ERRORS.SOFTWARE_UPDATE.EDP_SWUPDATE_ANDROID_WIFI_NOT_CONNECTED;
        case SoftwareUpdateErrors.EDP_SWUPDATE_ANDROID_WIFI_INTERNET_FAILED: return strings.PICEA.ERRORS.SOFTWARE_UPDATE.EDP_SWUPDATE_ANDROID_WIFI_INTERNET_FAILED;

        case SocketErrors.ERR_SOCKET_NOT_CONNECTED: return strings.PICEA.ERRORS.SOCKET.ERR_SOCKET_NOT_CONNECTED;
        case SocketErrors.ERR_SOCKET_CONNECT_FAILED: return strings.PICEA.ERRORS.SOCKET.ERR_SOCKET_CONNECT_FAILED;

        case NetworkErrors.EDP_NETWORK_NOT_AVAILABLE: return strings.PICEA.ERRORS.NETWORK.EDP_NETWORK_NOT_AVAILABLE;
        case NetworkErrors.EDP_INVALID_NETWORK_SETTINGS: return strings.PICEA.ERRORS.NETWORK.EDP_INVALID_NETWORK_SETTINGS;
        case NetworkErrors.EDP_INVALID_HTTP_REQUEST: return strings.PICEA.ERRORS.NETWORK.EDP_INVALID_HTTP_REQUEST;
        case NetworkErrors.EDP_HTTP_REQUEST_FAILED: return strings.PICEA.ERRORS.NETWORK.EDP_HTTP_REQUEST_FAILED;
        case NetworkErrors.EDP_NETWORK_CONNECTION_REFUSED: return strings.PICEA.ERRORS.NETWORK.EDP_NETWORK_CONNECTION_REFUSED;
        case NetworkErrors.EDP_NETWORK_REMOTE_HOST_CLOSED: return strings.PICEA.ERRORS.NETWORK.EDP_NETWORK_REMOTE_HOST_CLOSED;
        case NetworkErrors.EDP_NETWORK_HOST_NOT_FOUND: return strings.PICEA.ERRORS.NETWORK.EDP_NETWORK_HOST_NOT_FOUND;
        case NetworkErrors.EDP_NETWORK_TIMEOUT: return strings.PICEA.ERRORS.NETWORK.EDP_NETWORK_TIMEOUT;
        case NetworkErrors.EDP_NETWORK_OPERATION_CANCELED: return strings.PICEA.ERRORS.NETWORK.EDP_NETWORK_OPERATION_CANCELED;
        case NetworkErrors.EDP_NETWORK_SSL_HANDSHAKE_FAILED: return strings.PICEA.ERRORS.NETWORK.EDP_NETWORK_SSL_HANDSHAKE_FAILED;
        case NetworkErrors.EDP_NETWORK_TEMPORARY_NETWORK_FAILURE: return strings.PICEA.ERRORS.NETWORK.EDP_NETWORK_TEMPORARY_NETWORK_FAILURE;
        case NetworkErrors.EDP_NETWORK_UNKNOWN_NETWORK_ERROR: return strings.PICEA.ERRORS.NETWORK.EDP_NETWORK_UNKNOWN_NETWORK_ERROR;
        case NetworkErrors.EDP_NETWORK_PROXY_CONNECTION_REFUSED: return strings.PICEA.ERRORS.NETWORK.EDP_NETWORK_PROXY_CONNECTION_REFUSED;
        case NetworkErrors.EDP_NETWORK_PROXY_CONNECTION_CLOSED: return strings.PICEA.ERRORS.NETWORK.EDP_NETWORK_PROXY_CONNECTION_CLOSED;
        case NetworkErrors.EDP_NETWORK_PROXY_NOT_FOUND: return strings.PICEA.ERRORS.NETWORK.EDP_NETWORK_PROXY_NOT_FOUND;
        case NetworkErrors.EDP_NETWORK_PROXY_TIMEOUT: return strings.PICEA.ERRORS.NETWORK.EDP_NETWORK_PROXY_TIMEOUT;
        case NetworkErrors.EDP_NETWORK_PROXY_AUTHENTICATION_REQUIRED: return strings.PICEA.ERRORS.NETWORK.EDP_NETWORK_PROXY_AUTHENTICATION_REQUIRED;
        case NetworkErrors.EDP_NETWORK_UNKNOWN_PROXY_ERROR: return strings.PICEA.ERRORS.NETWORK.EDP_NETWORK_UNKNOWN_PROXY_ERROR;
        case NetworkErrors.EDP_NETWORK_CONTENT_ACCESS: return strings.PICEA.ERRORS.NETWORK.EDP_NETWORK_CONTENT_ACCESS;
        case NetworkErrors.EDP_NETWORK_CONTENT_OPERATION_NOT_PERMITTED: return strings.PICEA.ERRORS.NETWORK.EDP_NETWORK_CONTENT_OPERATION_NOT_PERMITTED;
        case NetworkErrors.EDP_NETWORK_CONTENT_NOT_FOUND: return strings.PICEA.ERRORS.NETWORK.EDP_NETWORK_CONTENT_NOT_FOUND;
        case NetworkErrors.EDP_NETWORK_AUTHENTICATION_REQUIRED: return strings.PICEA.ERRORS.NETWORK.EDP_NETWORK_AUTHENTICATION_REQUIRED;
        case NetworkErrors.EDP_NETWORK_CONTENT_RE_SEND: return strings.PICEA.ERRORS.NETWORK.EDP_NETWORK_CONTENT_RE_SEND;
        case NetworkErrors.EDP_NETWORK_UNKNOWN_CONTENT_ERROR: return strings.PICEA.ERRORS.NETWORK.EDP_NETWORK_UNKNOWN_CONTENT_ERROR;
        case NetworkErrors.EDP_NETWORK_PROTOCOL_UNKNOWN_ERROR: return strings.PICEA.ERRORS.NETWORK.EDP_NETWORK_PROTOCOL_UNKNOWN_ERROR;
        case NetworkErrors.EDP_NETWORK_PROTOCOL_INVALID_OPERATION: return strings.PICEA.ERRORS.NETWORK.EDP_NETWORK_PROTOCOL_INVALID_OPERATION;
        case NetworkErrors.EDP_NETWORK_PROTOCOL_FAILURE: return strings.PICEA.ERRORS.NETWORK.EDP_NETWORK_PROTOCOL_FAILURE;
        case NetworkErrors.EDP_NETWORK_OPER_FAILURE_PLEASE_RETRY: return strings.PICEA.ERRORS.NETWORK.EDP_NETWORK_OPER_FAILURE_PLEASE_RETRY;
        case NetworkErrors.EDP_NETWORK_2FA_AUTHENTICATION: return strings.PICEA.ERRORS.NETWORK.EDP_NETWORK_2FA_AUTHENTICATION;
        case NetworkErrors.EDP_NETWORK_REDIRECT_NOT_ALLOWED: return strings.PICEA.ERRORS.NETWORK.EDP_NETWORK_REDIRECT_NOT_ALLOWED;
        case NetworkErrors.EDP_NETWORK_TOKENS_NOT_AVAILABLE: return strings.PICEA.ERRORS.NETWORK.EDP_NETWORK_TOKENS_NOT_AVAILABLE;

        case OtherError.EDP_EXTERNAL_APPLICATION_MISSING: return strings.PICEA.ERRORS.OTHER.EDP_EXTERNAL_APPLICATION_MISSING;
        case OtherError.EDP_IOS_FIND_MY_IPHONE_WAS_ON: return strings.PICEA.ERRORS.OTHER.EDP_IOS_FIND_MY_IPHONE_WAS_ON;
        case OtherError.EDP_APPLE_ID_CREDENTIALS_NEEDED: return strings.PICEA.ERRORS.OTHER.EDP_APPLE_ID_CREDENTIALS_NEEDED;
        case OtherError.EDP_IOS_RESTORE_ROLLBACK: return strings.PICEA.ERRORS.OTHER.EDP_IOS_RESTORE_ROLLBACK;
        case OtherError.EDP_IOS_MDM_BACKUPRESTORE_NOT_ALLOWED: return strings.PICEA.ERRORS.OTHER.EDP_IOS_MDM_BACKUPRESTORE_NOT_ALLOWED;
        case OtherError.EDP_IOS_MDM_RESTRICTED: return strings.PICEA.ERRORS.OTHER.EDP_IOS_MDM_RESTRICTED;
        case OtherError.EDP_MESSAGE_CONTENT_IN_ICLOUD: return strings.PICEA.ERRORS.OTHER.EDP_MESSAGE_CONTENT_IN_ICLOUD;
        case OtherError.EDP_IOS_ACTIVATION_FAILED_SIM_NOT_SUPPORTED: return strings.PICEA.ERRORS.OTHER.EDP_IOS_ACTIVATION_FAILED_SIM_NOT_SUPPORTED;
        case OtherError.EDP_IOS_ACTIVATION_FAILED_ACTIVATION_SESSION_EXPIRED: return strings.PICEA.ERRORS.OTHER.EDP_IOS_ACTIVATION_FAILED_ACTIVATION_SESSION_EXPIRED;
        case OtherError.EDP_IOS_ACTIVATION_FAILED_UNKNOWN_ERROR: return strings.PICEA.ERRORS.OTHER.EDP_IOS_ACTIVATION_FAILED_UNKNOWN_ERROR;
        case OtherError.EDP_IOS_SETUP_CONFIGURATION_ERROR: return strings.PICEA.ERRORS.OTHER.EDP_IOS_SETUP_CONFIGURATION_ERROR;
        case OtherError.EDP_IOS_SETUP_WIFI_ERROR: return strings.PICEA.ERRORS.OTHER.EDP_IOS_SETUP_WIFI_ERROR;
        case OtherError.EDP_IOS_SETUP_LANGUAGE_ERROR: return strings.PICEA.ERRORS.OTHER.EDP_IOS_SETUP_LANGUAGE_ERROR;
        

        case PCInstallErrors.EDP_SERVICE_INSTALL_PACKAGE_FAILED: return strings.PICEA.ERRORS.PC_INSTALL.EDP_SERVICE_INSTALL_PACKAGE_FAILED;
        case PCInstallErrors.EDP_SERVICE_UPDATE_PRODUCT_FAILED: return strings.PICEA.ERRORS.PC_INSTALL.EDP_SERVICE_UPDATE_PRODUCT_FAILED;
        case PCInstallErrors.EDP_SERVICE_IMAGE_PATH_UPDATE_FAILED: return strings.PICEA.ERRORS.PC_INSTALL.EDP_SERVICE_IMAGE_PATH_UPDATE_FAILED;
        case PCInstallErrors.EDP_SERVICE_FIREWALL_EXCEPTION_FAILED: return strings.PICEA.ERRORS.PC_INSTALL.EDP_SERVICE_FIREWALL_EXCEPTION_FAILED;
        case PCInstallErrors.EVERIFY_UNKNOWN: return strings.PICEA.ERRORS.PC_INSTALL.EVERIFY_UNKNOWN;
        case PCInstallErrors.EVERIFY_OK: return strings.PICEA.ERRORS.PC_INSTALL.EVERIFY_OK;
        case PCInstallErrors.EVERIFY_CANCELLED: return strings.PICEA.ERRORS.PC_INSTALL.EVERIFY_CANCELLED;
        case PCInstallErrors.EVERIFY_FAILED_MASK: return strings.PICEA.ERRORS.PC_INSTALL.EVERIFY_FAILED_MASK;
        case PCInstallErrors.EVERIFY_FAILED: return strings.PICEA.ERRORS.PC_INSTALL.EVERIFY_FAILED;
        case PCInstallErrors.EVERIFY_SESSION_ONGOING: return strings.PICEA.ERRORS.PC_INSTALL.EVERIFY_SESSION_ONGOING;
        case PCInstallErrors.EVERIFY_NO_CONFIGURATION: return strings.PICEA.ERRORS.PC_INSTALL.EVERIFY_NO_CONFIGURATION;
        case PCInstallErrors.EVERIFY_NO_SESSION: return strings.PICEA.ERRORS.PC_INSTALL.EVERIFY_NO_SESSION;
        case PCInstallErrors.EVERIFY_INVALID_PARAMETER: return strings.PICEA.ERRORS.PC_INSTALL.EVERIFY_INVALID_PARAMETER;
        case PCInstallErrors.EVERIFY_NETWORK_REQUEST_FAILED: return strings.PICEA.ERRORS.PC_INSTALL.EVERIFY_NETWORK_REQUEST_FAILED;
        case PCInstallErrors.EVERIFY_NO_FEATURE: return strings.PICEA.ERRORS.PC_INSTALL.EVERIFY_NO_FEATURE;
        case PCInstallErrors.EVERIFY_NO_CREDITS: return strings.PICEA.ERRORS.PC_INSTALL.EVERIFY_NO_CREDITS;
        case PCInstallErrors.EVERIFY_NO_PERMISSIONS: return strings.PICEA.ERRORS.PC_INSTALL.EVERIFY_NO_PERMISSIONS;
        case PCInstallErrors.DP_UNKNOWN: return strings.PICEA.ERRORS.PC_INSTALL.DP_UNKNOWN;
        case PCInstallErrors.EDP_INVALID_COMPUTER_TIME: return strings.PICEA.ERRORS.PC_INSTALL.EDP_INVALID_COMPUTER_TIME;
        case PCInstallErrors.EVERIFY_GSMA_BUNDLE_EXPIRED: return strings.PICEA.ERRORS.PC_INSTALL.EVERIFY_GSMA_BUNDLE_EXPIRED;

        default: return `${strings.PICEA.ERRORS.GENERIC.UNEXPECTED_ERROR}, Code: ${error?.toString()}`;
    }
}

export const cloningErrorDescriber = (error: number): string => {
    return errorDescriber(error);
}

export const eraserErrorDescriber = (error: number): string => {
    switch( error) {
        case OtherError.EDP_APPLE_ID_CREDENTIALS_NEEDED: return strings.PICEA.ERRORS.ERASING.EDP_APPLE_ID_CREDENTIALS_NEEDED;
    }
    return errorDescriber( error);
}

export enum DeviceStatuses {
    ADB_confirmNeeded = 0x07,  //<! ADB (Android Debug Bridge) confirmation dialog is expected to be accepted from device
    ADB_enableAdbMode = 0x06,  //<! Android device detected, but ADB (Android Debug Bridge) mode has not been enabled in device
    AndroidPiceaOneConnecting = 0x0B,  //<! PiceaOne is connecting to start communication with client
    AndroidPiceaOneInstallConfirmNeeded = 0x0D,  //<! With some Android devices, PiceaOne installation must be separately accepted from device user interface
    AndroidPiceaOneInstallFailed = 0x09,  //<! PiceaOne installation failed to device
    AndroidPiceaOneInstallFailedNoSpace = 0x1F,  //<! Not enough free space in device to install PiceaOne
    AndroidPiceaOneInstallOngoing = 0x08,  //<! PiceaOne installation to the device is ongoing. After installation this status will change based on installation result.
    AndroidPiceaOneInstallRejected = 0x0A,  //<! PiceaOne installation has been rejected
    AndroidPiceaOneNoConnection = 0x0C,  //<! PiceaOne connection with the client has failed. Most often user is instructed to reconnect the device
    Android_SetupNotFinished = 0x1C,  //<! Android device's initial setup has not been finished. 
    DeviceChargeOnly = 0x02,  //<! Android device connected in charge only mode
    DeviceConnecting = 0x19,  //<! Device has been detected but connectivity initialization is still ongoing
    DeviceDiskFull = 0x17,  //<! Device has run out of storage space
    DeviceInfoNotReady = 0x1E,  //<! DeviceInfoNotReady, device info is not yet read from server
    DeviceLocked = 0x01,  //<! Device is locked, lock screen visible in device user interface
    DeviceReserved = 0x18,  //<! Device is reserved for certain operation, communication limitations during reserved state exist
    DriverMissing = 0x15,  //<! Expected USB driver has not been installed to the Windows PC
    DriverNotFunctional = 0x14,  //<! USB driver in Windows PC have been detected to be in non-functional state. Reinstallation recommended
    DriverUpdateNeeded = 0x13,  //<! USB Drivers to Windows PC should be updated with latest versions
    FindMyIPhone = 0x22,  //<! Find my iphone is on. For transfer service only.
    MTP_notFound = 0x03,  //<! Media Transfer protocol not available, MTP is required e.g for Switch
    MTP_notUsable = 0x04,  //<! Android device connected in ADB only mode. Also this status is set when switch service is requesting device status
    PTP_mode = 0x05,  //<! Device detected in PTP mode, user is requested to change to MTP mode
    ReconnectNeeded = 0x16,  //<! Problem in communication with the device, reconnection needed to recover communication
    SecurityLockEnabled = 0x21,  //<! Security lock enabled in device
    TipGoogleAccountsToAndroid = 0x8002,  //<! For transfer. User should activate google account in target device.
    TipMemoryCard = 0x8001,  //<! For transfer. User should move memory card to the target device.
    TipSimCard = 0x8000,  //<! For transfer. User should move SIM card to the target device.
    UnsupportedOSVersion = 0x20,  //<! Device OS version is not supported
    WP_FlashMode = 0x1B,  //<! WIndows Phone detected in flash mode
    iOS_TrustConfirmNeeded = 0x0E,  //<! Apple device has not been paired, trust confirmation dialog from device user interface must be accepted
    iOS_BackupEncrypted = 0x11,  //<! Apple device uses encrypted backups, password must be asked from user to remove encryption. This status is returned for for Switch service
    iOS_DFUMode = 0x1A,  //<! Apple device detected in DFU (Device Firmware Update) mode
    iOS_NotActivated = 0x0F,  //<! Apple device connected in non activated state
    iOS_RecoveryMode = 0x12,  //<! Apple device has been connected in recovery mode. Apple Device might end up to recovery mode e.g in case of failed firmware update
    iOS_SetupNotFinished = 0x10,  //<! Apple device setup after factory reset or erasure has not been completed.
    iOS_UnknownConnectivityError = 0x1D,  //<! iOs_UnknownConnectivityError, device is not seen on iOS bus, unknown reason
}

export const deviceDescriber = (error: number, isSubject?: boolean): string => {
    switch (error) {
        case DeviceStatuses.ADB_confirmNeeded: return isSubject === true ? "" : strings.PICEA.DEVICE_STATUSES.ADB_CONFIRM_NEEDED;
        case DeviceStatuses.ADB_enableAdbMode: return isSubject === true ? "" : strings.PICEA.DEVICE_STATUSES.ADB_ENABLE_ADB_MODE;
        case DeviceStatuses.AndroidPiceaOneConnecting: return isSubject === true ? "" : strings.PICEA.DEVICE_STATUSES.ANDROID_PICEA_ONE_CONNECTING;
        case DeviceStatuses.AndroidPiceaOneInstallConfirmNeeded: return isSubject === true ? "" : strings.PICEA.DEVICE_STATUSES.ANDROID_PICEA_ONE_INSTALL_CONFIRM_NEEDED;
        case DeviceStatuses.AndroidPiceaOneInstallFailed: return isSubject === true ? strings.PICEA.DEVICE_STATUSES.ANDROID_PICEA_ONE_INSTALL_FAILED : strings.PICEA.DEVICE_STATUSES.ANDROID_PICEA_ONE_CHECK_USB_FREE_SPACE_AND_RETRY;
        case DeviceStatuses.AndroidPiceaOneInstallOngoing: return isSubject === true ? "" : strings.PICEA.DEVICE_STATUSES.ANDROID_PICEA_ONE_INSTALL_ONGOING;
        case DeviceStatuses.AndroidPiceaOneInstallRejected: return isSubject === true ? "" : strings.PICEA.DEVICE_STATUSES.ANDROID_PICEA_ONE_INSTALL_REJECTED;
        case DeviceStatuses.AndroidPiceaOneNoConnection: return isSubject === true ? "" : strings.PICEA.DEVICE_STATUSES.ANDROID_PICEA_ONE_NO_CONNECTION;
        case DeviceStatuses.Android_SetupNotFinished: return isSubject === true ? "" : strings.PICEA.DEVICE_STATUSES.ANDROID_SETUP_NOT_FINISHED;
        case DeviceStatuses.DeviceChargeOnly: return isSubject === true ? strings.PICEA.DEVICE_STATUSES.CHARGE_ONLY : strings.PICEA.DEVICE_STATUSES.DEVICE_CHARGE_ONLY;
        case DeviceStatuses.DeviceConnecting: return isSubject === true ? "" : strings.PICEA.DEVICE_STATUSES.DEVICE_CONNECTING;
        case DeviceStatuses.DeviceDiskFull: return isSubject === true ? strings.PICEA.DEVICE_STATUSES.LACK_OF_DISK_SPACE : strings.PICEA.DEVICE_STATUSES.DEVICE_DISK_FULL;
        case DeviceStatuses.DeviceLocked: return isSubject === true ? strings.PICEA.DEVICE_STATUSES.ATTENTION : strings.PICEA.DEVICE_STATUSES.DEVICE_LOCKED;
        case DeviceStatuses.DeviceReserved: return isSubject === true ? strings.PICEA.DEVICE_STATUSES.ATTENTION : strings.PICEA.DEVICE_STATUSES.DEVICE_RESERVED;
        case DeviceStatuses.DriverMissing: return isSubject === true ? strings.PICEA.DEVICE_STATUSES.DRIVER_MISSING : strings.PICEA.DEVICE_STATUSES.DRIVER_REQUIRED;
        case DeviceStatuses.DriverNotFunctional: return isSubject === true ? strings.PICEA.DEVICE_STATUSES.FAIL : strings.PICEA.DEVICE_STATUSES.DRIVER_NOT_FUNCTIONAL;
        case DeviceStatuses.DriverUpdateNeeded: return isSubject === true ? strings.PICEA.DEVICE_STATUSES.ATTENTION : strings.PICEA.DEVICE_STATUSES.DRIVER_UPDATE_NEEDED;
        case DeviceStatuses.MTP_notFound: return isSubject === true ? strings.PICEA.DEVICE_STATUSES.ATTENTION : strings.PICEA.DEVICE_STATUSES.MTP_NOT_FOUND;
        case DeviceStatuses.MTP_notUsable: return isSubject === true ? strings.PICEA.DEVICE_STATUSES.ATTENTION : strings.PICEA.DEVICE_STATUSES.MTP_NOT_USABLE;
        case DeviceStatuses.PTP_mode: return isSubject === true ? strings.PICEA.DEVICE_STATUSES.ATTENTION : strings.PICEA.DEVICE_STATUSES.PTP_MODE;
        case DeviceStatuses.ReconnectNeeded: return isSubject === true ? strings.PICEA.DEVICE_STATUSES.ATTENTION : strings.PICEA.DEVICE_STATUSES.RECONNECT_NEEDED;
        case DeviceStatuses.WP_FlashMode: return isSubject === true ? strings.PICEA.DEVICE_STATUSES.ATTENTION : strings.PICEA.DEVICE_STATUSES.WP_FLASH_MODE;
        case DeviceStatuses.iOS_DFUMode: return isSubject === true ? strings.PICEA.DEVICE_STATUSES.ATTENTION : strings.PICEA.DEVICE_STATUSES.IOS_DFU_MODE;
        case DeviceStatuses.iOS_NotActivated: return isSubject === true ? strings.PICEA.DEVICE_STATUSES.DEVICE_NOT_ACTIVATED : strings.PICEA.DEVICE_STATUSES.IOS_NOT_ACTIVATED;
        case DeviceStatuses.iOS_RecoveryMode: return isSubject === true ? strings.PICEA.DEVICE_STATUSES.ATTENTION : strings.PICEA.DEVICE_STATUSES.IOS_RECOVERY_MODE;
        case DeviceStatuses.iOS_SetupNotFinished: return isSubject === true ? strings.PICEA.DEVICE_STATUSES.ATTENTION : strings.PICEA.DEVICE_STATUSES.IOS_SETUP_NOT_FINISHED;
        case DeviceStatuses.iOS_TrustConfirmNeeded: return isSubject === true ? "" : strings.PICEA.DEVICE_STATUSES.IOS_TRUST_CONFIRM_NEEDED;
        case DeviceStatuses.iOS_UnknownConnectivityError: return isSubject === true ? strings.PICEA.DEVICE_STATUSES.FAIL : strings.PICEA.DEVICE_STATUSES.IOS_UNKNOWN_CONNECTIVITY_ERROR;
        case 30: return isSubject === true ? strings.PICEA.DEVICE_STATUSES.STATUS_30 : strings.PICEA.DEVICE_STATUSES.CONNECTING;
        default: return error?.toString();
    }
}


export const getDeviceHelpURL = (status: DeviceStatuses, language: string, device?: PiceaDevice): string | undefined => {
    const osVersion = device?.sw_version || device?.os_version

    const supportBaseUrl = "https://services.piceasoft.com/support"

    switch (status) {
        case DeviceStatuses.FindMyIPhone: return `${supportBaseUrl}/fmiphelp?version=${osVersion}&os=ios&lang=${language}`
        case DeviceStatuses.ADB_enableAdbMode: return `${supportBaseUrl}/debugmode?manufacturer=${device?.manufacturer}&version=${osVersion}&lang=${language}`
        case DeviceStatuses.AndroidPiceaOneInstallRejected: return `${supportBaseUrl}/manual_install?manufacturer=${device?.manufacturer}&version=${osVersion}&lang=${language}`
        default: return undefined
    }
}

export const contentTransferDeviceStatusDescriber = ( status: DeviceStatuses ): string => {
    switch (status) {
        case DeviceStatuses.ADB_confirmNeeded: return strings.CONTENT_TRANSFER.DEVICE_STATUSES.ADB_CONFIRM_NEEDED;
        case DeviceStatuses.DeviceLocked: return strings.CONTENT_TRANSFER.DEVICE_STATUSES.DEVICE_LOCKED;

        case DeviceStatuses.DeviceChargeOnly:
        case DeviceStatuses.MTP_notFound:
        case DeviceStatuses.MTP_notUsable:
             return strings.CONTENT_TRANSFER.DEVICE_STATUSES.SELECT_MTP_MODE;

        case DeviceStatuses.PTP_mode: return strings.CONTENT_TRANSFER.DEVICE_STATUSES.PTP_MODE;
        case DeviceStatuses.SecurityLockEnabled: return strings.CONTENT_TRANSFER.DEVICE_STATUSES.SECURITY_LOCK_ENABLED;
        case DeviceStatuses.ADB_enableAdbMode: return strings.CONTENT_TRANSFER.DEVICE_STATUSES.ADB_ENABLE_ADB_MODE;
        case DeviceStatuses.FindMyIPhone: return strings.CONTENT_TRANSFER.DEVICE_STATUSES.FIND_MY_IPHONE;
        case DeviceStatuses.TipGoogleAccountsToAndroid: return strings.CONTENT_TRANSFER.DEVICE_STATUSES.TIP_GOOGLE_ACCOUNTS_TO_ANDROID;
        case DeviceStatuses.TipMemoryCard: return strings.CONTENT_TRANSFER.DEVICE_STATUSES.TIP_MEMORY_CARD;
        case DeviceStatuses.TipSimCard: return strings.CONTENT_TRANSFER.DEVICE_STATUSES.TIP_SIM_CARD;
        case DeviceStatuses.UnsupportedOSVersion: return strings.CONTENT_TRANSFER.DEVICE_STATUSES.UNSUPPORTED_OS_VERSION;

        case DeviceStatuses.Android_SetupNotFinished:
        case DeviceStatuses.iOS_SetupNotFinished:
            return strings.CONTENT_TRANSFER.DEVICE_STATUSES.FINISH_DEVICE_SETUP;

        case DeviceStatuses.iOS_RecoveryMode: return strings.CONTENT_TRANSFER.DEVICE_STATUSES.IOS_RECOVERY_MODE;
        case DeviceStatuses.DeviceDiskFull: return strings.CONTENT_TRANSFER.DEVICE_STATUSES.DEVICE_DISK_FULL;
        
        default: return deviceDescriber(status);
    }
}



// Specify here how severi is the issue for switch. Icon is shown based on that.
export const deviceStatusSeverityForSwitch = (status: number): 'error' | 'info' | 'success' | 'warning' => {
    switch (status) {
        case DeviceStatuses.ADB_confirmNeeded: return 'error';
        case DeviceStatuses.DeviceConnecting: return 'info';
        case DeviceStatuses.TipGoogleAccountsToAndroid: return 'info';
        case DeviceStatuses.TipMemoryCard: return 'info';
        case DeviceStatuses.TipSimCard: return 'info';
        case DeviceStatuses.SecurityLockEnabled: return 'info';
        default: return 'error';
    }
}

export const titleDescriber = (error: number): string => {
  if (error !== null && error > 255) 
    error = Int32Array.from(Uint32Array.of(error))[0];
  
  switch (error) {
    case ServerErrors.EDP_PRODUCT_ID_NOT_VALID:
      return strings.PICEA.ERRORS.STATUSES.ERR_PRODUCT_EXPIRED.TITLE
    default:
      return strings.WIDGET.ERRORS.TITLE.ERROR
  }
}

export const serverErrorDescriber = (error: number, message: string): string => {
  if (error !== null && error > 255) 
    error = Int32Array.from(Uint32Array.of(error))[0];
  
  switch (error) {
    case ServerErrors.EDP_PRODUCT_ID_NOT_VALID:
      return strings.PICEA.ERRORS.STATUSES.ERR_PRODUCT_EXPIRED.DESCRIPTION
    default:
      return message
  }
}
