import { Modal, Stack, IconButton, getTheme, FontWeights, mergeStyleSets, Text, ScrollablePane } from "@fluentui/react";
import 'react-markdown-editor-lite/lib/index.css';
import MarkdownIt from "markdown-it";
import { strings } from "../../../localization/strings";
import s from '../../../assets/css/scrollHide.module.css';

type TProps = {
    show: boolean
    hide: () => void
    markdown: string
    title?: string
    description?: string
}

export const MarkdownHelp: React.FC<TProps> = (props) => {

    const mdParser = new MarkdownIt(/* Markdown-it options */);

    return (
        <Modal scrollableContentClassName={s.scrollHide} isOpen={props.show} onDismiss={props.hide} containerClassName={contentStyles.container}>
            <Stack verticalFill>
                <Stack.Item className={contentStyles.header}>
                    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }} style={{ paddingRight: 32 }}>
                        <Stack.Item>
                            <Text variant="xLargePlus">{props.title ?? strings.FOOTER.TUTORIAL.LABEL}</Text>
                            {props.description && <Text variant="medium">{props.description}</Text>}
                        </Stack.Item>
                    </Stack>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel="Close window"
                        onClick={props.hide}
                    />
                </Stack.Item>
                <Stack.Item verticalFill style={{ position: "relative" }}>
                    <ScrollablePane>
                        <Stack verticalFill style={{ padding: "0 24px 0 24px" }}>
                            <div className="custom-html-style" dangerouslySetInnerHTML={{ __html: mdParser.render(props.markdown) }} />
                        </Stack>
                    </ScrollablePane>
                </Stack.Item>
            </Stack>
        </Modal>
    )
}

const theme = getTheme()
const iconButtonStyles = {
    root: {
        color: theme.palette.blue,
        marginLeft: 'auto'
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
}

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: 800,
        height: 600,
        maxWidth: 800,
        maxHeight: 600
    },
    header: [
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
