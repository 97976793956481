import { IResponseResult } from "../store/typings/IResponseResult";
import { ITransactionDevice, IStickerData, ITransaction, ITransactionValue, IModerationMessage } from "@piceasoft/core";
import { getApiResponse, IApiResponse } from "./api";
import { endpoints } from "./endpoints";

export const transactionApi = {
    async getTransaction(processId: number, discountId?: string, device?: ITransactionDevice, sku?: string): Promise<IApiResponse<ITransaction>> {
        return await getApiResponse(endpoints.v1.process.requestTransaction(processId, discountId, sku), {
            method: "POST",
            data: device,
            headers: {
                "Content-Type": "application/json"
            }
        });
    },
    async getTransactionByTransactionId(uid: string): Promise<IResponseResult<ITransaction>> {
        let result: IResponseResult<ITransaction> = { successed: false };
        await fetch(endpoints.v1.getTransaction(uid))
            .then(async response => {
                await (response.json() as Promise<IResponseResult<ITransaction>>)
                    .then(response => result = response)
                    .catch(error => result.errors = [{ description: error.message }])
            })
            .catch(error => result.errors = [{ description: error.message }])
        return result;

    },
    async sendContractToCustomer(uid: string, templateId?: number): Promise<IResponseResult<undefined>> {
        let result: IResponseResult<undefined> = { successed: false };
        await fetch(endpoints.v1.sendContractToCustomer(uid, templateId))
            .then(async response => {
                await (response.json() as Promise<IResponseResult<undefined>>)
                    .then(response => result = response)
                    .catch(error => result.errors = [{ description: error.message }])
            })
            .catch(error => result.errors = [{ description: error.message }])
        return result;

    },
    async setControlTransactionId(transactionId: string, controlTransactionId: string): Promise<IResponseResult<undefined>> {
        let result: IResponseResult<undefined> = { successed: false };
        await fetch(endpoints.v1.setControlTransactionId(transactionId, controlTransactionId), {
            method: "PATCH"
        })
            .then(async response => {
                await (response.json() as Promise<IResponseResult<undefined>>)
                    .then(response => result = response)
                    .catch(error => result.errors = [{ description: error.message }])
            })
            .catch(error => result.errors = [{ description: error.message }])
        return result;

    },
    async postSticker(data: IStickerData): Promise<IResponseResult<undefined>> {
        let result: IResponseResult<undefined> = { successed: false };
        await fetch(endpoints.v1.postSticker, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { "Content-Type": "application/json" }
        })
            .then(async response => {
                if (response.status === 200) {
                    result = {successed: true}
                }
                else {
                    result = { successed: false, errors: [{code: response.status.toString(), description: response.statusText}]}
                }
            })
            .catch(error => result.errors = [{ description: error.message }])
        return result;

    },
    async addEvent(transactionUid: string, action: string, status: string): Promise<IApiResponse<undefined>> {
        return await getApiResponse(endpoints.v1.process.addEvent(transactionUid), {
            method: "POST",
            data: {
                action: action,
                status: status
            },
            headers: { "Content-Type": "application/json" }
        });
    },
    async addEvents(transactionUid: string, events: Array<{ action: string, status: string }>): Promise<IApiResponse<undefined>> {
        return await getApiResponse(endpoints.v1.process.addEvents(transactionUid), {
            method: "POST", data: events, headers: { "Content-Type": "application/json" }
        });
    },
    async updateTransaction(uid: string, data: ITransactionValue[]): Promise<IApiResponse<undefined>> {
        return await getApiResponse(endpoints.v1.process.updateTransaction(uid), { method: "PATCH", data: data, headers: { "Content-Type": "application/json" } })
    },

    /**
     * Gets status of photo moderation.
     * 
     * @param uid Unique identifier of the transaction
     * 
     * @returns Moderation status
     */
    async getPhotoModeration(uid: string) : Promise<IApiResponse<IModerationMessage>> {
        return await getApiResponse(endpoints.v1.process.photoModeration(uid))
    }
}
