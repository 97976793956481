import { IEraserDetails } from "@piceasoft/core";

declare global {
    interface Window {
        PICEA: IPicea
    }
}

export enum PiceaConnectionTypes {
    OTF = 1,
    USB = 2,
    NCD = 3
}

export enum PiceaApplicationStates {
    /** Работа приложения приостановлена (оно не активно). */
    Suspended = 0,
    /** Работа приложения восстановлена (оно активно). */
    Activated = 1
}

export interface IPicea {
    onConnected: (handler: (args: PiceaConnectionEventArgs) => void) => () => void;
    onDisconnected: (handler: (args: PiceaConnectionEventArgs) => void) => () => void;
    onDeviceAdded: (handler: (args: PiceaDeviceEventArgs) => void) => () => void;
    onDeviceUpdated: (handler: (args: PiceaDeviceEventArgs) => void) => () => void;
    onDeviceRemoved: (handler: (args: PiceaDeviceEventArgs) => void) => () => void;
    onApplicationStateChanged: (handler: (args: PiceaApplicationStateEventArgs) => void) => () => void;
}

export type PiceaConnectionEventArgs = {
    error: PiceaError
    connectionType: PiceaConnectionTypes
    data: any
}

export type PiceaDeviceEventArgs = {
    error: PiceaError
    connectionType: PiceaConnectionTypes
    device: PiceaDevice
}

export type PiceaApplicationStateEventArgs = {
    error: PiceaError
    type: any
    data: PiceaApplicationState
}


export type PiceaError = {
    status: number
    message: string
    details?: PiceaErrorDetails
}

export type PiceaErrorDetails = {
    error: number
    message: string
}

export type PiceaApplicationState = {
    state: PiceaApplicationStates
    device_id: string
}

export type PiceaDevice = {
    /** Device ID. */
    device_id: string
    /** Device type. */
    tid: string
    /** Type string. */
    tid_string?: string
    /** Device format. */
    fid: string
    /** Format string. */
    fid_string?: string
    /** Operating system. */
    os?: string
    /** Device manufacturer. */
    manufacturer: string
    /** Device brand name. */
    brand_name?: string
    /** Device model. */
    model_name: string
    /** Device model code/number. */
    model_code?: string
    /** Device primary IMEI. */
    imei: string
    /** Device secondary IMEI. */
    imei2: string
    /** Device MEID value. */
    meid?: string
    /** Device serial number. */
    serial?: string
    /** Device USB serial number (available only when connected with USB cable). */
    usb_serial?: string
    /** Device unique ID. */
    unique_id?: string
    /** Device software version. */
    sw_version?: string
    /** Device OS version. */
    os_version?: string
    /** Device storage. */
    storage: string
    /** Device internal memory. */
    ram?: string
    /** Device color. */
    color?: string
    /** Device supports GSM. */
    has_gsm: boolean
    /** Device supports EUICC. */
    has_euicc: boolean
    /** Device supports CDMA. */
    has_cdma?: string
    /** HTTP URL to device image. */
    device_img?: string
    /** HTTP URL to device image. */
    image_url?: string
    /** iDevice product ID (iPhone n.n etc.) */
    hardware_id?: string

    platform?: string

    non_connected_device?: boolean 

    /*! If connector supports session API, this is true. If not, this is missing or false*/
    use_session_api?: boolean

    group?: string

    model_info?: IModelInfo
    /** Device product code/number. */
    product_code?: string
}

export interface IModelInfo{
    is_dual_sim?: boolean
    has_gsm?: boolean
    has_euicc?: boolean
}

export type PiceaDevices = {
    status: number
    devices: PiceaDevice[]
}

export enum DiagnosticsSets {
    TS_ACCESSORIES = 176,
    TS_AUDIO = 16,
    TS_BATTERY = 144,
    TS_CAMERA = 32,
    TS_CHIPSETS = 208,
    TS_CONDITION = 160,
    TS_CONNECTIVITY = 80,
    TS_CONNECTORS = 112,
    TS_DISPLAY = 48,
    TS_GENERAL = 0,
    TS_HW_BUTTONS = 64,
    TS_PERFORMANCE = 192,
    TS_ROBOTIC = 224,
    TS_SENSORS = 96,
    TS_SOFTWARE = 128
}

export enum DiagnosticStatuses {
    TCR_CANCELED = 4,
    TCR_FAILURE = 2,
    TCR_NOT_IMPLEMENTED = 7,
    TCR_NOT_SUPPORTED_BY_THE_DEVICE = 8,
    TCR_PASSED = 1,
    TCR_SKIPPED = 3,
    TCR_SKIPPED_BY_USER = 9,
    TCR_UNDEFINED = 0,
    TCR_UNEXECUTED = 5,
    TCR_WARNING = 6
}

export type PiceaDefaultResp = {
    status: number
}
export type PiceaOpenSessionDataResp = {
    session_id: string
    status: number
}

// ContentTransfer related

export type SwitchEnumerationStatusData = {
    switch_session_id: string
    is_ready_to_transfer: boolean
    source: SwitchDeviceStatusData
    target: SwitchDeviceStatusData
}
export type SwitchDeviceStatusData = {
    ios_device_reading_status?: SwitchIosDeviceReadingStatusData
    content_types: SwitchContentTypeStatusData[]
}
export type SwitchContentTypeStatusData = {
    type: number
    count: number
    size: number
    progress: number
    selectable: boolean
    status: OperationState // number, OperationState
    error_code: number // errorcode, if failed
}
export type SwitchIosDeviceReadingStatusData = {
    progress: number
    status: OperationState
    error_code: number // errorcode, if failed
}

export type SwitchIosCloningStatusData = {
    switch_session_id: string
    remaining_time_estimate: number
    total_progress: number
    state: RestoreState
    state_progress: number
}

export type SwitchIosRestoreStatusData = {
    switch_session_id: string
    remaining_time_estimate: number
    total_progress: number
    state: RestoreState
    state_progress: number
}

export type SwitchTransferStatusData = {
    switch_session_id: string
    remaining_time_estimate?: number
    content_types: SwitchTransferServiceStatusData[]
    status: number
    total_progress: number
}

export type SwitchTransferServiceStatusData = {
    type: number
    count: number // source items count
    size: number  // source items total size
    progress: number
    status: number
}

/** OperationState */
export enum OperationState {
    OperationState_Unknown = 0,  //<! Unknown
    OperationState_Waiting = 1,  //<! Waiting
    OperationState_Ongoing = 2,  //<! Ongoing
    OperationState_Completed = 3  //<! Completed
}

/** OperationStatus */
export enum OperationStatus {
    OperationStatus_Unknown = 0,  //<! Unknown
    OperationStatus_Succeeded = 1,  //<! Succeeded
    OperationStatus_Failed = 2,  //<! Failed
    OperationStatus_EmptyRun = 3,  //<! Empty run
    OperationStatus_Skipped = 4,  //<! Skipped operation
    OperationStatus_Cancelled = 5,  //<! Cancelled
}

export type SwitchContentTypeReport = {
    content_type: number
    source_fid: string // guid
    target_fid: string // guid
    duration: number // seconds
    status: OperationStatus
    error_code: number // see EDP errorcodes
    error_msg?: number
    item_count: number
    item_size: null | number
    item_size_failed: null | number

    succeeded: number
    updated: number
    failed: number
    duplicate: number
    not_supported: number
    failure_ratio: number
    errors: Object[] // error_code, item_count
}

export type SwitchDetailsReport = {
    switch_type: number
    failure_ratio: number // 0...1
    target_device: Object // device object

    backup_info?: Object // backupRecord if backup/restore. Not applicable for us.

    // ios restore
    full_restore?: {
        result: number
        size: number
        }

    accounts?: Object

    content_types?: SwitchContentTypeReport[]
}

// 'operation_status' object for erasure operation 'eraser_operation_completed'
export type ErasureOperationReportData = {
    device: PiceaDevice
    duration: number // seconds
    erasure_details: IEraserDetails
    error_code: number
    error_message: string
    failure_rate: number // 0...100
    switch_details: SwitchDetailsReport
    rerun: boolean
    session_id: string
    status: OperationStatus
    timestamp: string
    type: number
    type_string: string
    uid: string
    utc_offset: number
}
// 'data' object for erasure operation 'eraser_operation_completed'
export type ErasureOperationCompletedData = {
    device_id: string
    operation_status: ErasureOperationReportData
}

export type SwitchOperationReportData = {
    duration: number // seconds
    status: OperationStatus
    error_code: number
    failure_rate: number // 0...100
    switch_details: SwitchDetailsReport
}

export type SwitchTransferOperationCompletedData = {
    device_id: string // source device id
    switch_session_id: string
    operation_status: SwitchOperationReportData
    result: number
}

// data type for onSwitchDeviceReconnectNeeded and onSwitchDeviceReconnectRecovered
export type SwitchReconnectNtfData = {
    device_id: string
}

// param for onSwitchSmsAppChangeNeeded
export type SwitchSmsAppChangeNeededNtfData = {
    device_id: string
    switch_session_id: string
}

// param for onSwitchSmsAppChangeDone
export type SwitchSmsAppChangeDoneNtfData = {
    device_id: string
    switch_session_id: string
    status: number
    error_code: number
}

// param for onSwitchRestoreUserActionNeeded
export type SwitchRestoreUserActionNeeded = {
    error_code: number
    switch_session_id: string
    required_action: RestoreState
}

export enum RestoreState {
    RestoreState_Undefined = 0,  //<! Restore state not set
    RestoreState_Preparing = 1,  //<! Preparing restore
    RestoreState_Restoring = 2,  //<! Restoring device
    RestoreState_Rebooting = 3,  //<! Rebooting device
    RestoreState_Finalising = 4,  //<! Finalising
    RestoreState_Finished = 5,  //<! Restore completed
    RestoreState_FindMyIPhoneOn = 6,  //<! Restore failed. FindMy is on
    RestoreState_DeviceLocked = 7,  //<! Restore failed. Device is locked
    RestoreState_ComputerLowMemory = 8,  //<! Restore failed. Not enough space on PC
    RestoreState_NotEnoughFreeSpace = 9,  //<! Restore failed. Not enough space on device
    RestoreState_Failed = 10,  //<! Restore failed.
}

// param for isCloningAllowed
export type SwitchIsCloningAllowedResult = {
    status: number // EDP errorcode
    result: CloningSupport
    time_estimate?: number
    data_size_estimate?: number
}

export type SwitchAnalyzedData = {
    type: number			// Content type
    new: number			    // Amount of new items
    updated: number		    // Amount of updated items
    duplicate: number		// Amount of duplicate items
    not_supported: number   // Amount of not supported items if known
}

export type SwitchIsTransferAllowedResult = {
    status: number; // operation status, 0 if got response from connector
    time_estimate?: number;
    result: number; // DP_OK if transfer is allowed, EDP_STORAGE_FULL | EDP_PC_STORAGE_FULL
    space_needed_in_target_device: number // bytes
    amount_of_selected_content: number // bytes
    content_types: SwitchAnalyzedData[]
}

// Cloning support issues
export enum CloningSupport {
    CloningSupport_Undefined = 0x00,  //<! Cloning support not determined
    CloningSupport_Ok = 0x01,  //<! Cloning supported
    CloningSupport_NotSupported = 0x02,  //<! Cloning not supported
    CloningSupport_SwUpdateNeeded = 0x03,  //<! Target device needs software update for cloning.
    CloningSupport_TooOldDevice = 0x04,  //<! Target device is too old for cloning. Not possible to update SW to required level.
    CloningSupport_NoEnoughSpace = 0x05,  //<! Not enough space in target device.
    CloningSupport_DataMissing = 0x06,  //<! Could not get enough information to determine cloning support.
    CloningSupport_NewerVersionThanWeProvide = 0x07,  //<! Target SW update needed, but we don't have required update image available
    CloningSupport_SwUpdateBlocked = 0x08,  //<! Target SW update needed, but SW update feature is disabled.
    CloningSupport_SourceDisabled = 0x09,  //<! Cloning feature is disabled for source device.
    CloningSupport_TargetDisabled = 0x0A,  //<! Cloning feature is disabled for target device.
    CloningSupport_NotEnoughSpacePC = 0x0B,  //<! Not enough free space on the PC to perform cloning.
}

export enum IosEncryptionStatus {
    IosEncryption_Undefined = 0x00,  //<! Encryption status not known.
    IosEncryption_NotEncrypted = 0x01,  //<! Not encrypted.
    IosEncryption_Encrypted = 0x02,  //<! Encrypted
    IosEncryption_MdmProtected = 0x03,  //<! MDM protected, check not possible
    IosEncryption_CorrectPassword = 0x04,  //<! Encrypted, the provided password is correct.
    IosEncryption_DeviceLocked = 0x05,  //<! Device is locked, check not possible.
    IosEncryption_DeviceNotPaired = 0x06,  //<! Device is not paired (trust confirmation), check not possible.
}

export type checkEncryptionPasswordResp = {
    status: number //  0 : Succeeded
    encryption_status: IosEncryptionStatus
}

/**
 * ios Device encryptionInfo
 * password is needed if status != IosEncryption_NotEncrypted
 */
export type DeviceEncryptionInfo = {
    password?: string;
    encryption_status: IosEncryptionStatus;
}

export enum ContentType {
    ContentType_Unknown = 0,  //<! Unknown
    ContentType_Contacts = 1,  //<! Contacts
    ContentType_Calendar = 2,  //<! Calendar
    ContentType_Messages = 3,  //<! Messages
    ContentType_Bookmarks = 4,  //<! Bookmarks
    ContentType_Music = 5,  //<! Music
    ContentType_Photos = 6,  //<! Photos
    ContentType_Videos = 7,  //<! Videos
    ContentType_Documents = 8,  //<! Documents
    ContentType_Apps = 9,  //<! Applications
    ContentType_CallLog = 10,  //<! Call log
    ContentType_iOSRestore = 20,  //<! iOS restore
    ContentType_OptionalContentApps = 11,
    ContentType_PreloadContentApps = 12,
    ContentType_WhatsApp = 13
}

export type DeviceStorageInfo = {
    name: string;
    size_free: number;
    size_total: number;
}

export type DeviceStorageInfoResp = {
    status: number; // always 0
    storages?: DeviceStorageInfo[];
}

// response for ONLINE_API.getConnectorSessionState
export type ConnectorSessionState = {
    is_session_valid: boolean;
    switch_state: ConnectorSessionSwitchState;
}

export type ConnectorSessionSwitchState = {
    switch_session_id?: string;
    source_reconnect_needed: boolean;
    target_reconnect_needed: boolean;

    target_sms_app_change_needed?: boolean;
    target_sms_app_change_done_error_code?: number // EDP errorcode

    switch_restore_user_action_needed?: SwitchRestoreUserActionNeeded;
    switch_ios_cloning_status?: SwitchIosCloningStatusData;
    switch_enumeration_status?: SwitchEnumerationStatusData;
    switch_transfer_status?: SwitchTransferStatusData;
    switch_ios_restore_status?: SwitchIosRestoreStatusData;
    transfer_operation_completed?: SwitchTransferOperationCompletedData
}


export type ISessionResultsCallbackData = {
    status: number,
    json_data: any,
    key: string,
    session_id: string
  }

export type ISessionResultsCallbackFunc = (data: ISessionResultsCallbackData) => void;

//  See Business.Services.PiceaModel for full definition.
//  Add missing fields as needed.
export interface IPiceaModel {
    manufacturer?: string
    model_name?: string,
    aca?: string[]
}