import { MessageBarType } from "@fluentui/react";
import { 
    IOfferHub, IService, IIdentification, 
    IProcessStageState, ITransaction, IPromoProviderState,
    IWorkflow, ProcessStages, OperationTypes, IOfferProviderState 
} from "@piceasoft/core";
import { PiceaSessionState } from "./PiceaSessionState";
import { IContentTransferState } from "./IContentTransferState"

/** Процесс сервиса. */
/** Workflow process */
export interface IProcess {
    /** Уникальный идентификатор процесса. */
    /** Process unique Id */
    id: number
    /** Online API process session unique identifier. */
    session_id?: string
    /** Код сервисной процедуры процесса. */
    /** Workflow object */
    service: IService
    /** Текущий этап выполняемого процесса. */
    /** Current step of the workflow */
    stage: ProcessStages
    /** Флаг указывает на то, что все условия текущего этапа выполнены и процесс может перейти к следующему этапу. */
    /** Flag indicating that current step is completed and workflow can go to next step */
    stageCompleted: boolean
    /** Порядковый номер текущего этапа выполняемого процесса. */
    /** Index of the current step */
    stageIndex: number
    /** Действие, необходимое для завершения текущего этапа. */
    /** Action which is needed to complete current step */
    stageAction?: () => void
    /** Action to do in back button */
    backButtonAction?: () => void
    /** Состояние этапов транзакции. */
    /** States of steps */
    stages: IProcessStageState[]
    /** Идентификация устройства. */
    /** Состояние идентификации */
    identification?: IIdentification
    /** Контейнер с перечнем коммерческих предложений. */
    /** Container with a list of commercial offers from local catalog (main catalog for workflow in settings) */
    offers: IOfferHub
    /** Список результатов запросов коммерческих предложений от провайдеров для устройства. */
    /** List of commercial offers from providers */
    offerProviders: IOfferProviderState[]
    /** Список результатов запросов коммерческих предложений от провайдеров для устройства. */
    /** List of campaign offers from providers */
    promoProviders?: IPromoProviderState[]
    /** Транзакция выполняемого процесса. */
    /** Transaction (workflow execution) data */
    transaction: ITransaction
    /** Данные исполняемого рабочего процесса. */
    /** Workflow settings */
    workflow: IWorkflow
    /** Сообщение при выполнении процесса. */
    /** Global errors information and warning. shown in top bar */
    message?: IProcessMessage
    /** Контролируемая транзакция. */
    /** Controlled transaction data */
    controlledTransaction?: ITransaction
    /** Контрольная транзакция без ведомой транзакции. */
    /** Utility internal flag */
    withoutControlledTransaction?: boolean
    /** Flag for current running operation */
    currentOperation?: OperationTypes
    /** Indicates if device is locked by IMEI */
    imeiLocked?: boolean
    /** Indicates current state of ONLINE Session */
    session_state?: PiceaSessionState
    /** ContentTransfer data */
    contentTransfer?: IContentTransferState
}

/** Global errors information and warning */
export interface IProcessMessage {
    /** Error text */
    text: string,
    /** Top bar style */
    type: MessageBarType,
    /** Code */
    code?: ProcessMessageCodes,
    /** Is message block process? Can't be overwriten */
    isBlocker?: {
        dialogTitle: string,
        nextAction: () => void,
        dialogAction: () => void,
        hideDialog: boolean
    }
    /** Action для повтора при ошибке. */
    /** Retry action  */
    onRetryAction?: () => void,
    /** Don't display footer if set to true. */
    hideFooter?: boolean,
    /** Show spinner if set to true. */
    showSpinner?: boolean
}

export enum ProcessMessageCodes {
    noGrade = "noGrade",
    imeiLockout = "imeiLockout",
    imeiChecking = "imeiChecking"
}