import {
    CommandBarButton, FontIcon, FontWeights, getTheme, IconButton,
    IIconProps, Link, mergeStyleSets, MessageBar, MessageBarType,
    Modal, PrimaryButton, ProgressIndicator, Separator, Stack, Text
} from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import { useBoolean } from '@uifabric/react-hooks';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../../../core/store';
import { PiceaStatuses } from '@piceasoft/core';
import { strings } from '../../../localization/strings';

const Picea: React.FC = () => {
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const picea = useSelector((state: IStore) => state.environment.picea);
    if (!picea) return null;

    let status
    let statusColor
    let statusIcon

    switch (picea.status) {
        case "available": status = strings.FOOTER.PICEA_SOFTWARE.STATUS_LOADED; statusColor = SharedColors.green10; statusIcon = "Completed"; break;
        case "fail": status = strings.FOOTER.PICEA_SOFTWARE.STATUS_FAIL; statusColor = SharedColors.red10; statusIcon = "Error"; break;
        case "loading": status = strings.FOOTER.PICEA_SOFTWARE.STATUS_LOADING; statusColor = theme.palette.black; statusIcon = "CloudDownload"; break;
        case "missing": return null;
    }

    return (
        <Stack horizontal>
            <CommandBarButton onClick={showModal} style={{ height: 28, padding: "0 8px" }}>
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                    <Text variant="small">{strings.FOOTER.PICEA_SOFTWARE.LABLE}</Text>
                    <FontIcon iconName={statusIcon} style={{ color: statusColor }} />
                </Stack>
            </CommandBarButton>
            <Modal isOpen={isModalOpen} onDismiss={hideModal} containerClassName={contentStyles.container}>
                <div className={contentStyles.header}>
                    <span style={{ paddingRight: 32 }}>{strings.FOOTER.PICEA_SOFTWARE.TITLE}</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel={strings.FOOTER.PICEA_SOFTWARE.CLOSE_BUTTON_ARIALABEL}
                        onClick={hideModal}
                    />
                </div>
                <div className={contentStyles.body}>
                    <Stack tokens={{ childrenGap: 16 }}>
                        <Stack.Item>
                            <Text style={{ color: SharedColors.gray40 }}>{strings.FOOTER.PICEA_SOFTWARE.DESCRIPTION}</Text>
                        </Stack.Item>
                        {picea.status === "available" && (
                            <Stack.Item>
                                <MessageBar messageBarType={MessageBarType.info} isMultiline={true}>
                                    <Text>
                                        <Link href={picea.installer} target="_blank">
                                            {strings.FOOTER.PICEA_SOFTWARE.INSTALL_LINK_TEXT}
                                        </Link> <span dangerouslySetInnerHTML={{ __html: strings.FOOTER.PICEA_SOFTWARE.MESSAGE_AVAILABLE }} />
                                    </Text>
                                </MessageBar>
                            </Stack.Item>
                        )}
                        {picea.error && (
                            <Stack.Item>
                                <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                                    {picea.error.message}
                                </MessageBar>
                            </Stack.Item>
                        )}
                        {picea.status === PiceaStatuses.Loading && (
                            <ProgressIndicator label={strings.FOOTER.PICEA_SOFTWARE.PROGRESS_LABLE} description={strings.FOOTER.PICEA_SOFTWARE.PROGRESS_DESCRIPTION} />
                        )}
                        <Separator />
                        <Stack.Item>
                            <Stack verticalAlign="center">
                                <Stack horizontal tokens={{ childrenGap: 12 }}>
                                    <Stack.Item>
                                        <FontIcon iconName={statusIcon} style={{ fontSize: 24, height: 24, width: 24, color: statusColor }} />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Text variant="large" style={{ color: statusColor }}>{status}</Text>
                                    </Stack.Item>
                                    <Stack.Item grow>
                                        <Stack horizontalAlign="end">
                                            <PrimaryButton onClick={hideModal}>{strings.FOOTER.PICEA_SOFTWARE.BUTTON_OK}</PrimaryButton>
                                        </Stack>
                                    </Stack.Item>
                                </Stack>
                            </Stack>
                        </Stack.Item>
                    </Stack>
                </div>
            </Modal>
        </Stack>
    )
}

export default Picea

const theme = getTheme();
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        maxWidth: 640
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
