
import { useState, useEffect } from "react";
export default function useDebounce<T>(value: T, wait: number) {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);
    
    useEffect(() => {
        const timeout = setTimeout(() => setDebouncedValue(value), wait);
        return () => clearTimeout(timeout);
    }, [value, wait]);
    
    return debouncedValue;
}