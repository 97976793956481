import {useCallback, useMemo, useState} from "react";
import {IGrade, IGradesCategory, IGradeWithCategory} from "@piceasoft/core";

// export default function useGrades(grades: IGrade[] | undefined, categories: IGradesCategory[] | undefined) {
//
//     // Grades with categories
//     const gradeCategories: IGradeWithCategory[] = []
//
//     /**
//      * Function to get the lowest among 2 grades
//      * @param category grade category
//      * @param g1 first grade code to compare
//      * @param g2 second grade code to compare
//      * @returns code of the lowest grade
//      */
//     const worstGrades = useCallback((category: string, g1: string, g2: string): string => {
//         if (g1 === "" || g2 === "") return "";
//
//         const getGrade = (g: string) => {
//             if (categories) {
//                 return categories
//                     .find(c => c.code === category)
//                     ?.grades.find(grade => grade.code === g);
//             }
//             return grades?.find(grade => grade.code === g);
//         };
//
//         const grade1 = getGrade(g1);
//         const grade2 = getGrade(g2);
//
//         return (grade1?.index ?? 0) > (grade2?.index ?? 0) ? g1 : g2;
//     }, [categories, grades]);
//
//     /**
//      * Function to store the result grade. Replaces the stored grade with the lower one.
//      * @param category grade category
//      * @param grade grade
//      */
//     const receiveGrade = useCallback((category: string, grade: string) => {
//         gradeCategories = (prev => {
//             const existing = prev.find(gc => gc.category === category);
//
//             if (existing) {
//                 const newGrade = worstGrades(category, existing.grade, grade);
//                 return prev.map(gc =>
//                     gc.category === category ? { ...gc, grade: newGrade } : gc
//                 );
//             } else {
//                 return [...prev, { category, grade }];
//             }
//         });
//     }, [worstGrades]);
//
//     /**
//      * Do not use with grade categories!!!
//      * Memoized calculation for best grade index based on the grades array
//      * The best grade is the one with the smallest index (or the first one after sorting)
//      */
//     const bestGradeIndex = useMemo(() => {
//         return grades?.sort((a, b) => a.index - b.index)[0].index as number ;
//     }, [grades]) 
//    
//     return { gradeCategories, receiveGrade, worstGrades, bestGradeIndex };
// }

/**
 * Utility function to recalculate grade of inspection module
 * 
 * @param {IGrade[] | undefined} grades - List of the grades defined against workflow
 * @param {IGradesCategory[] | undefined} categories - List of the grade categories defined against workflow
 * */
export default function useGrades(
    grades: IGrade[] | undefined,
    categories: IGradesCategory[] | undefined
) {

    /**
     * list of grades + categories(if applicable) recalculated for the inspection module
     */
    let gradeCategories: IGradeWithCategory[] = []
    
    /**
     * Function to get the lowest among 2 grades
     * @param category grade category
     * @param g1 first grade code to compare
     * @param g2 second grade code to compare
     * @returns code of the lowest grade
     */
    const worstGrades = (category: string, g1: string, g2: string): string => {
        if (g1 === "" || g2 === "") return "";

        const getGrade = (g: string) => {
            if (categories) {
                return categories
                    .find(c => c.code === category)
                    ?.grades.find(grade => grade.code === g);
            }
            return grades?.find(grade => grade.code === g);
        };

        const grade1 = getGrade(g1);
        const grade2 = getGrade(g2);

        return (grade1?.index ?? 0) > (grade2?.index ?? 0) ? g1 : g2;
    };

    /**
     * Function to store the result grade. Replaces the stored grade with the lower one.
     * @param category grade category
     * @param grade grade
     */
    const receiveGrade = (category: string, grade: string) => {
        let gc = gradeCategories.find(gc => gc.category === category)
        //If grade is already pushed for category, update it if incoming grade is lower than stored one
        if (gc) {
            gc.grade = worstGrades(category, gc.grade, grade);
        } else {
            gradeCategories.push({ category, grade });
        }
    }

    /**
     * Do not use with grade categories!!!
     * Memoized calculation for best grade index based on the grades array
     * The best grade is the one with the smallest index (or the first one after sorting)
     */
    const bestGradeIndex = grades?.sort((a, b) => a.index - b.index)[0]?.index ?? 0;

    // Return results
    return { receiveGrade, worstGrades, gradeCategories, bestGradeIndex };
}