import { Stack, SharedColors, Text, Image, getTheme, ITheme, ImageFit, ScrollablePane } from "@fluentui/react"
import { getIconSymbolsFromString, getPersonaColorsPallete } from "../../../../core/scripts/style"
import { IOffer, IGrade } from "@piceasoft/core"
import { Price } from "../../../process/offer/Price"
import defaultServiceIcon from "../../../../assets/images/service.svg"

type TProps = {
    serviceName: string
    description?: string
    generate?: boolean
    imgSrc?: string
    serviceId: number
    offers: IOffer[]
    grades: IGrade[]
}

export const ServicePricesBox: React.FC<TProps> = (props) => {
    return (
        <Stack disableShrink styles={{
            root: {
                backgroundColor: theme.palette.neutralLight,
                boxShadow: theme.effects.elevation8,
                borderRadius: theme.effects.roundedCorner4
            }
        }}>
            <Stack key={props.serviceId} tokens={{ padding: `24px 12px ${props.offers.length > 0 ? '0px' : '24px'} 12px`, childrenGap: 12 }}>
                <Stack.Item tokens={{ padding: '0 12px' }}>
                    <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 16 }}>
                        <Stack verticalAlign="center" horizontalAlign='center' tokens={{ childrenGap: 8 }} >
                            <Stack verticalAlign="center"
                                horizontalAlign="center"
                                style={{
                                    width: 48,
                                    height: 48,
                                    borderRadius: theme.effects.roundedCorner6,
                                    backgroundColor: !props.imgSrc && props.generate ?
                                        (props.serviceName ?
                                            getPersonaColorsPallete[props.serviceName[0].toLowerCase()] :
                                            undefined) :
                                        undefined
                                }}>
                                {props.imgSrc && (
                                    <Stack horizontalAlign='center'>
                                        <Image src={props.imgSrc} width={48} height={48} imageFit={ImageFit.cover} style={{ borderRadius: theme.effects.roundedCorner4 }} />
                                    </Stack>
                                ) || (props.generate && (
                                    <Text style={{ color: "#fff", fontSize: 20, fontWeight: 400, paddingBottom: 2 }}>
                                        {getIconSymbolsFromString(props.serviceName)}
                                    </Text>
                                ) || (
                                        <Stack horizontalAlign='center'>
                                            <Image src={defaultServiceIcon} width={48} height={48} imageFit={ImageFit.cover} style={{ borderRadius: theme.effects.roundedCorner4 }} />
                                        </Stack>
                                    )
                                    )
                                }
                            </Stack>
                        </Stack>
                        <Stack tokens={{ childrenGap: 2 }}>
                            <Text variant="mediumPlus" style={{ color: SharedColors.gray40, fontWeight: "bolder" }}>{props.serviceName}</Text>
                            {props.description && (
                                <Text variant="medium" style={{ color: SharedColors.gray20 }}>{props.description}</Text>
                            )}
                        </Stack>
                    </Stack>
                </Stack.Item>
                {props.offers.length > 0 && (
                    <Stack.Item tokens={{ padding: '0 8px 12px 8px' }}>
                        <Stack verticalFill horizontalAlign="center" style={{ overflow: 'auto', display: 'grid' }}>
                            <Stack verticalFill horizontalAlign="center" horizontal tokens={{ childrenGap: 16 }} style={{ display: 'inline-flex', padding: '12px 4px 12px 4px' }}>
                                {props.offers.map((o, index) => {
                                    const grade = props.grades.find(i => i.code === o.grade) as IGrade
                                    if (!grade) {
                                        return (
                                            <Price
                                                key={`${props.serviceId}-${o.price}-${index}`}
                                                name={"unknown grade"}
                                                description={"unknown grade"}
                                                explanation={"unknown grade"}
                                                price={o.price} />
                                        )
                                    }
                                    return (
                                        <Price
                                            key={`${props.serviceId}-${grade.code}-${index}`}
                                            name={grade.name}
                                            description={grade.description}
                                            explanation={grade.explanation}
                                            price={o.price} />
                                    )
                                })
                                }
                            </Stack>
                        </Stack>
                    </Stack.Item>
                )}
            </Stack>
        </Stack>
    )
}

const theme: ITheme = getTheme();