/** Content transfer stages */
export enum ContentTransferStages {
    /** DeviceSelections step */
    DeviceSelections = 0,
    /** Preparing/checking source device step */
    PreparingSourceDevice = 1,
    /** Preparing/checking target device step */
    PreparingTargetDevice = 2,
    /** Method selection step */
    MethodSelection = 3,
    /** Content enumeration and selection step */
    ContentEnumerationAndSelection = 4,
    /** Content transfer step */
    ContentTransfer = 5,
    /** Cloning preparation */
    CloningPreparation = 6,
    /** Cloning ongoing */
    CloningOngoing = 7,
    /** Tranfer / cloning is done */
    Done = 8
}