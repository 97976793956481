import { IResponseResult } from "../store/typings/IResponseResult";
import { ITransaction } from "@piceasoft/core";
import { endpoints } from "./endpoints";

export const overviewApi = {
    transactions: {
        getTransaction: async (uid: string): Promise<IResponseResult<ITransaction>> => {
            let result: IResponseResult<ITransaction> = { successed: false };
            await fetch(endpoints.v1.overview.transaction(uid))
                .then(async response =>  {
                    await (response.json() as Promise<IResponseResult<ITransaction>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{description: error.message}])
                })
                .catch(error => result.errors = [{description: error.message}])
            return result;
        },
        // getTransactionTechLog: async (transactionId: string): Promise<IRequestResult<ITransactionTechLog[]>> => {
        //     let result: IRequestResult<ITransactionTechLog[]> = { successed: false };
        //     await fetch(endpoints.v1.overview.transactionTechLog(transactionId))
        //         .then(async response =>  {
        //             await (response.json() as Promise<IRequestResult<ITransactionTechLog[]>>)
        //                 .then(response => result = response)
        //                 .catch(error => result.errors = [{description: error.message}])
        //         })
        //         .catch(error => result.errors = [{description: error.message}])
        //     return result;
        // }
    },
    // getModerations: async (): Promise<IRequestResult<IModerationResultLog[]>> => {
    //     let result: IRequestResult<IModerationResultLog[]> = { successed: false };
    //     await fetch(endpoints.v1.moderations)
    //         .then(async response => {
    //             await (response.json() as Promise<IRequestResult<IModerationResultLog[]>>)
    //                 .then(response => result = response)
    //                 .catch(error => result.errors = [{description: error.message}])
    //         })
    //         .catch(error => result.errors = [{description: error.message}])
    //     return result;
    // },
    // getModeration: async (transactionId: string): Promise<IRequestResult<IModerationResult>> => {
    //     let result: IRequestResult<IModerationResult> = { successed: false };
    //     await fetch(endpoints.v1.moderation(transactionId))
    //         .then(async response => {
    //             await (response.json() as Promise<IRequestResult<IModerationResult>>)
    //                 .then(response => result = response)
    //                 .catch(error => result.errors = [{description: error.message}])
    //         })
    //         .catch(error => result.errors = [{description: error.message}])
    //     return result;
    // },
}