import { IAssessmentDetails, IInspectionModuleState } from "@piceasoft/core"
import { IStore } from "../../store"
import { getOperationTypeByAssesmentModule } from "./offerOperationDetails";

export const getAssessmentOperationDetails = (appState: IStore): IAssessmentDetails | undefined => {
    const currentTransaction = appState.process.current?.transaction;
    const workflow = appState.process.current?.workflow;

    const getGradeDescription = (grade_code?: string, grade_categories_code?: string) => {
        const workflowGrades = appState.process.current?.workflow.grades;
        const workflowGradeCategories = appState.process.current?.workflow.gradesCategories;

        if(grade_categories_code && grade_code) {
            const grade_category_description = workflowGradeCategories?.find(gc => gc.code === grade_categories_code)?.grades.find(g => g.code === grade_code)?.description;
            return grade_category_description || "";
        }

        const grade_description = workflowGrades?.find(g => g.code === grade_code)?.description;
        return grade_description || undefined;
    }

    const getGradeCategoriesDescription = (grade_category_code: string) => {
        const workflowGradeCategories = appState.process.current?.workflow.gradesCategories;
        const grade_category_description = workflowGradeCategories?.find(gc => gc.code === grade_category_code)?.name;
        return grade_category_description || "";
    }

    const getAssessmentGradeDescription = () => {
        const workflowGrades = appState.process.current?.workflow.grades;

        const assessment = currentTransaction?.assessment;
        if(assessment?.resultGrades) {
            const grade_description = assessment.resultGrades.map(rg => getGradeDescription(rg.code, rg.gradeCategoryCode))
                .filter(description => description)
                .join(appState.process.current?.workflow.gradesDelimiter ?? ':')
            return grade_description || undefined;
        }

        const grade_description = workflowGrades?.find(g => g.code === assessment?.grade)?.description;
        return grade_description || undefined;
    }

    const getGrades = (module: IInspectionModuleState) => {
        if(module.state.grades && module.state.grades.length > 0) {
            const grades = module.state.grades.map(g => ({
                grade: g.grade,
                grade_description: getGradeDescription(g.grade, g.category),
                category: g.category,
                category_description: getGradeCategoriesDescription(g.category)
            }))

            return grades
        }

        const workflowGradesCategory = workflow?.assessment?.modules.find(m => m.index === module.index)?.config.gradesCategory;
        if(workflowGradesCategory) {
            return [{
                grade: "",
                grade_description: "",
                category: workflowGradesCategory,
                category_description: getGradeCategoriesDescription(workflowGradesCategory)
            }]
        }

        return [{
            grade: "",
            grade_description: "",
            category: "",
            category_description: ""
        }]
    }

    if(currentTransaction?.assessment) {
        let assessmentDetails: IAssessmentDetails = {
            operation_uid: currentTransaction.uid,
            grade: currentTransaction.assessment.grade,
            grade_description: getAssessmentGradeDescription(),
            grades: currentTransaction.assessment.resultGrades?.map(rg => ({
                grade: rg.code,
                grade_description: getGradeDescription(rg.code, rg.gradeCategoryCode),
                category: rg.gradeCategoryCode,
                category_description: rg.gradeCategoryName
            })),
            modules: []
        }

        if(currentTransaction.assessment.modules) {
            currentTransaction.assessment.modules.forEach(m => {
                assessmentDetails.modules.push({
                    operation_type: getOperationTypeByAssesmentModule(m?.type),
                    operation_uid: currentTransaction.uid,
                    status: m?.state.status,
                    grade: m?.state.grade ?? '',
                    grade_description: m?.state.grade ? getGradeDescription(
                            m?.state.grade, 
                            m?.state.grades ? m?.state.grades?.find(g => g.grade === m?.state.grade)?.category : 
                                currentTransaction.assessment.resultGrades?.find(rg => rg.code === m?.state.grade)?.gradeCategoryCode
                        ) 
                    : undefined,
                    was_aborted: m?.state.wasAborted ?? false,
                    grades: workflow?.useGradesCategories ? getGrades(m) : undefined
                })
            })
        }

        return assessmentDetails;
    }

    return undefined;
}
