import { IProcess } from "../store";
import { IWorkplace, IService } from '@piceasoft/core';
import { getApiResponse, IApiResponse } from "./api";
import { endpoints } from "./endpoints";

export const workplaceApi = {
    async requestWorkplace(): Promise<IApiResponse<IWorkplace>> {
        return await getApiResponse(endpoints.v1.requestWorkplace);
    },
    async getServices(channelId: number): Promise<IApiResponse<IService[]>> {
        return await getApiResponse(endpoints.v1.getServiceHub(channelId));
    },
    async getProcess(serviceId: number, link?: string): Promise<IApiResponse<IProcess>> {
        return await getApiResponse(endpoints.v1.process.initializationRequest(serviceId, link), {
            method: "POST"
        })
    },
    async addLog(processId: number, code: string, description: string, data: any): Promise<IApiResponse<undefined>> {
        return await getApiResponse(endpoints.v1.process.addLog(processId), {
            method: "POST",
            data: {
                code: code,
                description: description,
                data: data
            },
            headers: { "Content-Type": "application/json" }
        });
    }
}