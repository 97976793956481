import { CountryPhonePrefixCodes } from "../../../../core/helpers/localization"
import { TCustomFieldData, IFieldsData, IDataCollectionStandardConfig, ITransactionValue } from "@piceasoft/core"
import { focusElement } from "../../search/SuggestedSearch"
import { TField } from "./contractTypes"
import { TransactionKeys } from "@piceasoft/core"


export function prepareContractObject(config?: IDataCollectionStandardConfig) {
    const contractObject: TField[] = []
    config?.fields?.phone && contractObject.push({ field: TransactionKeys.CONTRACT_PHONE_NUMBER, isValid: false })
    config?.fields?.passport?.placeOfBirth && contractObject.push({ field: TransactionKeys.PASSPORT_PLACE_OF_BIRTH, isValid: false })
    config?.fields?.passport?.dateOfBirth && contractObject.push({ field: TransactionKeys.PASSPORT_DATE_OF_BIRTH, isValid: false })
    config?.fields?.passport?.number && contractObject.push({ field: TransactionKeys.PASSPORT_SN, isValid: false })
    config?.fields?.passport?.dateOfIssue && contractObject.push({ field: TransactionKeys.PASSPORT_DATE_OF_ISSUE, isValid: false })
    config?.fields?.passport?.subdivisionCode && contractObject.push({ field: TransactionKeys.PASSPORT_SUBDIVISION_CODE, isValid: false })
    config?.fields?.passport?.issuedBy && contractObject.push({ field: TransactionKeys.PASSPORT_ISSUED_BY, isValid: false })
    config?.fields?.passport?.address && contractObject.push({ field: TransactionKeys.PASSPORT_ADDRESS, isValid: false })
    config?.fields?.operator && contractObject.push({ field: TransactionKeys.OPERATOR, isValid: false })
    config?.fields?.name && contractObject.push({ field: TransactionKeys.PASSPORT_NAME, isValid: false })
    config?.fields?.email && contractObject.push({ field: TransactionKeys.CONTRACT_EMAIL, isValid: false })
    config?.fields?.subscription && contractObject.push({ field: TransactionKeys.SUBSCRIPTION, isValid: false })
    return contractObject
}

export function parseDateFromString(value: string): Date | null {
    return value ? new Date(value) : null;
}

export function parseDateField(dateString: string | undefined): Date | null {
    return dateString !== undefined ? parseDateFromString(dateString) : null
}

export function formatDate(date?: Date | null): string {
    return date?.toLocaleDateString() ?? "";
}

export function toIsoUtcDateString(date?: Date | null): string {
    if (!date) return "" 
    const offset = date.getTimezoneOffset() * 60000 
    return new Date(date.getTime() - offset).toISOString();
}

export async function fetchDaData(value: string, key: string, useDaData: boolean, endpoint: 'fio' | 'fms_unit' | 'address') {
    if (!useDaData || !key) {
        return
    }

    let url = `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/${endpoint}`
    const authHeader = `Token ${key}`

    let options: RequestInit = {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": authHeader
        },
        body: JSON.stringify({ query: value })
    }

    try {
        let resp = await fetch(url, options)
        return await resp.json()
    } catch (e) {
        console.log(`Failed to fetch ${endpoint} DaData`, e)
    }
}

export function getTransactionValues(data: IFieldsData): ITransactionValue[] {

    let transactionValues: ITransactionValue[] = []

    if (data.name) {
        transactionValues.push({
            key: TransactionKeys.PASSPORT_NAME,
            value: data.name
        })
    }

    if (data.phoneCountry && data.phone) {
        transactionValues.push({
            key: TransactionKeys.CONTRACT_PHONE_NUMBER,
            value: ((data.phoneCountry && data.phone) ? `+${CountryPhonePrefixCodes[data.phoneCountry as unknown as CountryPhonePrefixCodes].toString()} ` : "") + data.phone
        })
    }

    if (data.passport?.dateOfBirth) {
        transactionValues.push({
            key: TransactionKeys.PASSPORT_DATE_OF_BIRTH,
            value: parseDateField(data.passport?.dateOfBirth) ?? undefined
        })
    }

    if (data.passport?.placeOfBirth) {
        transactionValues.push({
            key: TransactionKeys.PASSPORT_PLACE_OF_BIRTH,
            value: data.passport?.placeOfBirth
        })
    }

    if (data.passport?.number) {
        transactionValues.push({
            key: TransactionKeys.PASSPORT_SN,
            value: data.passport?.number
        })
    }

    if (data.passport?.dateOfIssue) {
        transactionValues.push({
            key: TransactionKeys.PASSPORT_DATE_OF_ISSUE,
            value: parseDateField(data.passport?.dateOfIssue) ?? undefined
        })
    }

    if (data.passport?.subdivisionCode) {
        transactionValues.push({
            key: TransactionKeys.PASSPORT_SUBDIVISION_CODE,
            value: data.passport?.subdivisionCode
        })
    }

    if (data.passport?.issuedBy) {
        transactionValues.push({
            key: TransactionKeys.PASSPORT_ISSUED_BY,
            value: data.passport?.issuedBy
        })
    }

    if (data.passport?.address) {
        transactionValues.push({
            key: TransactionKeys.PASSPORT_ADDRESS,
            value: data.passport?.address
        })
    }

    if (data.operator) {
        transactionValues.push({
            key: TransactionKeys.OPERATOR,
            value: data.operator
        })
    }

    if (data.email) {
        transactionValues.push({
            key: TransactionKeys.CONTRACT_EMAIL,
            value: data.email
        })
    }

    if (data.subscribed) {
        transactionValues.push({
            key: TransactionKeys.SUBSCRIPTION,
            value: data.subscribed
        })
    }

    const customFields = getCustomFields(data.customFields)

    if (customFields.length > 0) {
        transactionValues.push(...customFields)
    }

    return transactionValues
}

function getCustomFields(customFields?: TCustomFieldData[]) {
    let newCustomFields: TCustomFieldData[] = []

    customFields?.forEach((cf) => {
        newCustomFields.push({
            key: `CUSTOM_${cf.key}`,
            value: cf.value
        })
    })

    return newCustomFields
}

export const handleTextToListKeyPressLocal = (
    ev: React.KeyboardEvent<HTMLElement>,
    hostId: string,
    listId: string,
    suggestionsLength: number,
    setSearchRequest: (item?: any) => void,
    index?: number,
    item?: string,
) => {
    if (suggestionsLength > 0) {
        switch (ev.key) {
            case 'ArrowDown':
                if (index === undefined) {
                    focusElement(hostId, `${listId}-0`)
                    break
                }
                if (index < suggestionsLength - 1) {
                    focusElement(`${listId}-${index}`, `${listId}-${index + 1}`)
                    break
                }
                focusElement(`${listId}-${index}`, hostId)
                break
            case 'ArrowUp':
                if (index !== undefined && index > 0) {
                    focusElement(`${listId}-${index}`, `${listId}-${index - 1}`)
                    break
                }
                focusElement(`${listId}-${index}`, hostId)
                break
            case 'Enter':
                setSearchRequest(item)
                // переделать на ref
                document.getElementById(hostId)?.focus()
                break
            default:
                focusElement(`${listId}-${index}`, hostId)
                break
        }
    }
}