import { DeviceAttributes, ProcessStages, OperationTypes } from '@piceasoft/core';
import { IProcess } from "../../store";
import { actionCreators } from '../../actions/process-actions';
import { consoleStyles } from '../../scripts/console';

export const prepareAddOperation = (processData: IProcess, operationType: OperationTypes, operationDetails: any, startDate?: Date, connection_type?: number, dispatch?: any): void => {

    let config = {
        device_id: processData.identification?.stamp,
        session_id: processData.session_id,
        connection_type: connection_type,
        operation: {
            type: operationType,
            type_string: OperationTypes[operationType ?? 0],
            //For fix issue with timestapm
            timestamp: new Date(Math.round(Date.now() / 1000) * 1000).toISOString().replace(".000Z", "Z"),//'2023-05-11T10:45:48Z',//Math.round(Date.now() / 1000),
            utc_offset: 0,
            status: 1,
            error_code: 0,
            duration: startDate == null ? 0 : Math.round(((new Date()).getTime() - startDate.getTime()) / 1000),
            operation_details: null,
            erasure_details: null,
            custom_questions_details: null,
            grading_details: null,
            device: {
                device_id: processData.identification?.stamp,
                tid: processData.identification?.device.attributes[DeviceAttributes.TID],
                tid_string: processData.identification?.device.attributes[DeviceAttributes.TID],
                fid: processData.identification?.device.attributes[DeviceAttributes.FID],
                fid_string: processData.identification?.device.attributes[DeviceAttributes.FID],
                name: processData.identification?.device.name,
                manufacturer: processData.identification?.device.manufacturer,
                model_name: processData.identification?.device.name,
                model_code: processData.identification?.device.attributes[DeviceAttributes.Model],
                imei: processData.identification?.device.attributes[DeviceAttributes.IMEI],
                serial: processData.identification?.device.attributes[DeviceAttributes.SN],
                storage: processData.identification?.device.configuration
            }

        }
    }
    if (processData.currentOperation == OperationTypes.ERASURE) {
        config.operation.erasure_details = operationDetails;
    }
    else if (processData.currentOperation == OperationTypes.CUSTOM_QUESTIONS) {
        config.operation.custom_questions_details = operationDetails;
    }
    else if (processData.currentOperation == OperationTypes.GRADING) {
        config.operation.grading_details = operationDetails;
    }
    else {
        config.operation.operation_details = operationDetails;
    }

    console.info({ config });

    dispatch(actionCreators.addLog(ProcessStages[processData.stage].toUpperCase(), "CALL ONLINE_API.addOperation()", config))

    window.ONLINE_API.addOperation(config, (error: any, type: any, data: any) => {
        console.debug("%c" + "Picea™ Online Services: ONLINE_API.addPiceaOnlineOperation()", consoleStyles.piceaCollback);
        
        dispatch(actionCreators.addLog(ProcessStages[processData.stage].toUpperCase(), "CALLBACK ONLINE_API.addOperation()", {error, data}));

        if (error) {
            console.error("addOperation error", error);
        }

    })

}
