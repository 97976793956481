import { Breadcrumb, FontSizes, IBreadcrumbItem, Icon, IDividerAsProps, SharedColors, TooltipHost } from '@fluentui/react';
import * as React from 'react'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { actionCreators } from '../../../core/actions/workplace-actions';
import { IStore } from '../../../core/store';
import { strings } from '../../../localization/strings';
import { ChannelTypes } from '@piceasoft/core';

function _getCustomDivider(dividerProps: IDividerAsProps): JSX.Element {
    return (
        <TooltipHost calloutProps={{ gapSpace: 0 }}>
            <span aria-hidden="true" style={{ cursor: "default", margin: "0 4px", color: SharedColors.gray30 }}>/</span>
        </TooltipHost>
    );
}

function _getCustomOverflowIcon(): JSX.Element {
    return <Icon iconName={'MoreVertical'} />
}

const _returnUndefined = () => undefined;

type TProps = {
}

export const CustomBreadcrumb: React.FC<TProps> = (props) => {
    const services = useSelector((s: IStore) => s.workplace.services.items)

    const [crumbs, setCrumbs] = useState<IBreadcrumbItem[]>([])
    const [currentPath, setCurrentPath] = useState<string>()

    const dispatch = useDispatch();

    const getTextbyPathPart = (pathPart: string) => {
        switch (pathPart) {
            case '':
                return strings.HEADER.BREADCRUMB.SERVICES
            case 'process':
                return strings.HEADER.BREADCRUMB.SERVICES
            case 'log':
                return strings.HEADER.BREADCRUMB.LOG
            case 'warehouse':
                return strings.HEADER.BREADCRUMB.WAREHOUSE
            default:
                return services?.find(i => i.id.toString() === pathPart)?.name.toString() as string
        }
    }

    const newPath = useLocation().pathname
    let currentPathParts = newPath.substring(1).split('/')
    if (currentPathParts.length > 1) {
        currentPathParts = currentPathParts.filter(i => i !== "")
    }

    useEffect(() => {
        dispatch(actionCreators.requestServices(ChannelTypes.Retail));

        if (currentPath !== newPath) {
            setCurrentPath(newPath)
            prepareCrumbs()
        }
        if (services) {
            prepareCrumbs()
        }
    }, [])

    useEffect(() => {
        if (services && currentPathParts) {
            prepareCrumbs()
        }
    }, [newPath, services])

    const prepareCrumbs = () => {
        let crumbs: IBreadcrumbItem[] = []
        currentPathParts.forEach((value, index) => {
            const text = getTextbyPathPart(value)
            let href = ''
            if (currentPathParts[index + 1]) {
                href = '/' + crumbs.map(c => c.key).join('/')
            }
            crumbs.push({ text: text, key: value, href: href })
            return
        })
        setCrumbs(crumbs)
    }

    return services && (
        <Breadcrumb styles={scopedSettings}
            items={crumbs}
            dividerAs={_getCustomDivider}
            onReduceData={_returnUndefined}
            onRenderOverflowIcon={_getCustomOverflowIcon}
        />
    ) || null
}

const scopedSettings = {
    chevron: {
        fontSize: "8px",
    },
    item: {
        color: SharedColors.gray30,
        fontSize: FontSizes.medium
    },
    itemLink: {
        color: SharedColors.gray30,
        fontSize: FontSizes.medium
    },
    root: {
        margin: "auto"
    }
}
