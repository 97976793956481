import React from "react"
import {
    Callout, IPersonaSharedProps, Persona, Image, PersonaSize,
    Stack, Text, PersonaPresence, getTheme, SharedColors,
    IRenderFunction, IPersonaProps, CommandBarButton
} from "@fluentui/react"
import { useBoolean } from "@uifabric/react-hooks"
import { useDispatch, useSelector } from "react-redux"
import { IStore } from "../../../core/store"
import { actionCreators } from "../../../core/actions/environment-actions"
import { langItems } from "../../../screens/login"
import { strings } from "../../../localization/strings"

export const UserBox: React.FC = () => {

    const [isUserMenuOpen, { toggle: toggleUserMenu }] = useBoolean(false)
    const user = useSelector((s: IStore) => s.workplace.user);
    const point = useSelector((s: IStore) => s.workplace.point);
    const company = useSelector((state: IStore) => state.workplace.company);
    const organization = useSelector((state: IStore) => state.workplace.organization);
    const [presense, setPresense] = React.useState<PersonaPresence>(PersonaPresence.none);
    const dispatch = useDispatch();
    const locale = useSelector((s: IStore) => s.environment.lang)

    React.useEffect(() => {
        const onReconnectingEvent = window.NOTIFICATIONS_HANDLERS.onReconnecting(() => {
            setPresense(PersonaPresence.away)
        });
        const onConnectedEvent = window.NOTIFICATIONS_HANDLERS.onConnected(() => {
            setPresense(PersonaPresence.online)
        });
        const onDisconnectedEvent = window.NOTIFICATIONS_HANDLERS.onDisconnected(() => {
            setPresense(PersonaPresence.busy)
        });
        return (() => {
            onDisconnectedEvent()
            onConnectedEvent()
            onReconnectingEvent()
        })
    }, []);

    React.useEffect(() => {
    }, [presense]);

    if (!user || !organization) return null;

    const handleLogout = () => {
        dispatch(actionCreators.requestSignOut());
    }

    const persona: IPersonaSharedProps = {
        imageInitials: user.name[0],
        text: user.display,
        secondaryText: user.name
    };

    const onRenderPrimaryText: IRenderFunction<IPersonaProps> = (props) => {
        return (
            <Text variant="large" style={{ color: SharedColors.gray40, fontWeight: "bold" }}>{props?.text}</Text>
        )
    }

    const onRenderSecondaryText: IRenderFunction<IPersonaProps> = (props) => {
        return (
            <Text variant="small">{props?.secondaryText}</Text>
        )
    }

    const onRenderTertiaryText: IRenderFunction<IPersonaProps> = (props) => {
        return (
            <Text variant="small">{props?.tertiaryText}</Text>
        )
    }

    return (
        <>
            <Persona onClick={toggleUserMenu} presence={presense} size={PersonaSize.size32} className="personaButton">
                {isUserMenuOpen &&
                    <Callout
                        onDismiss={toggleUserMenu}
                        target={`.${"personaButton"}`}
                        isBeakVisible={false}
                        gapSpace={16}
                        setInitialFocus
                        alignTargetEdge
                    >
                        <Stack tokens={{ childrenGap: 16 }} style={{ width: 320 }}>
                            <Stack.Item>
                                <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
                                    <Stack.Item>
                                        <Stack horizontal tokens={{ childrenGap: 8 }} style={{ paddingLeft: 8 }}>
                                            <Image height={20} src={organization.logo} />
                                            <Text style={{ color: SharedColors.gray20, fontWeight: "bold" }}>{organization.name}</Text>
                                        </Stack>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <CommandBarButton onClick={handleLogout} style={{ margin: 0, height: 48, paddingLeft: 16, paddingRight: 16 }}
                                            text={strings.HEADER.USER_SIGN_OUT} />
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                            <Stack.Item style={{ paddingLeft: 16 }}>
                                <Persona {...persona} onRenderTertiaryText={onRenderTertiaryText} onRenderPrimaryText={onRenderPrimaryText}
                                    onRenderSecondaryText={onRenderSecondaryText} presence={presense} size={PersonaSize.size72} />
                            </Stack.Item>
                            <Stack.Item>
                                <Stack horizontal grow tokens={{ padding: 16 }}
                                    style={{
                                        backgroundColor: theme.palette.neutralLighter,
                                        borderTop: `1px solid ${theme.palette.neutralQuaternaryAlt}`,
                                        borderBottom: `1px solid ${theme.palette.neutralQuaternaryAlt}`
                                    }}>
                                    <Stack.Item grow>
                                        {company && point && (<Stack tokens={{ childrenGap: 2 }}>
                                            <Text variant="small" style={{ color: SharedColors.gray30 }}>
                                                {company?.name}
                                            </Text>
                                            <Text variant="small" style={{ color: SharedColors.gray40 }}>
                                                <b>{point?.name}</b>
                                            </Text>
                                            <Text variant="xSmall" style={{ color: SharedColors.gray30 }}>
                                                {point?.city}{point?.city ? "," : ""} {point?.address}
                                            </Text>
                                        </Stack>) || (
                                                <Text variant="small" style={{ color: SharedColors.gray40 }}>
                                                    <b>{organization?.name}</b>
                                                </Text>
                                            )}
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Stack>
                                            <Image src={langItems.find(i => i.key === (locale ?? "ru").substring(0, 2))?.imgSrc} height={24} width={24} />
                                        </Stack>
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                        </Stack>
                    </Callout>}
            </Persona>
        </>
    )
}

const theme = getTheme();