import { IStore } from "../../store";
import { Inspections, PromotionPriceTypes, IOfferDetail, ICampaign, OperationTypes } from "@piceasoft/core";
import { findSelectedOffer } from "../../../components/helpers/offer";

export const getOfferOperationDetails = (appState: IStore): IOfferDetail | undefined => {
  const currentProcess = appState.process.current;
  if (currentProcess?.transaction?.assessment) {
    const selectedPromo = currentProcess.transaction.selectedPromo;
    const selectedProviderCode = currentProcess.transaction.selectedProvider

    const offers = currentProcess?.offers;
    const hasOffers = offers?.items && offers?.items.length > 0;
    const organization = appState.workplace.organization;
    let operationCurrency = currentProcess?.transaction.currency;
    const currentGrade = currentProcess?.transaction?.assessment.grade;
    const conditions = selectedPromo?.state?.conditions?.filter(c => c.grade === currentGrade || c.grade === "*");
   
    let price = (currentProcess?.transaction?.assessment.price ?? 0);
    let selectedCondition = null;
    if (conditions && conditions.length > 0) {
      selectedCondition = conditions[0];
      if (selectedCondition.priceType == PromotionPriceTypes.Benefit) {
        price += selectedCondition.price;
      }
      else {
        price = selectedCondition.price;
      }
    }

    if (hasOffers && !operationCurrency) {
      operationCurrency = offers.currency ?? organization?.currency;
    }

    let operationDetails: IOfferDetail =
    {
      error_reason: "",
      price: price,
      offer_price: currentProcess?.transaction?.priceWithoutCommission,
      valid_until: undefined,
      currency: operationCurrency,
      offers: [],
      campaigns: [],
      assessment: {
        grade: currentProcess?.transaction?.assessment.grade,
        modules: []
      }
    };

    if (currentProcess?.transaction?.assessment.modules) {
      currentProcess?.transaction?.assessment.modules.forEach(m => {
        operationDetails.assessment.modules.push({
          operation_type: getOperationTypeByAssesmentModule(m?.type),
          operation_uid: currentProcess?.transaction.uid,
          status: m?.state.status,
          grade: m?.state.grade ?? '',
          grade_description: m?.state.grade ?? '',
          was_aborted: m?.state?.wasAborted ?? false
        });
      });
    }
    if (selectedPromo && selectedCondition) {
      const currCompain: ICampaign = {
        uid: selectedPromo.promo_id,
        name: selectedPromo.state.name,
        provider_code: selectedPromo?.state.provider_code ?? '',
        provider_name: selectedPromo?.state.provider_name ?? '',
        valid_from: selectedPromo?.state?.from,
        valid_to: selectedPromo?.state?.to,
        device: undefined,
        condition: {
          type: selectedCondition.priceType,
          value: selectedCondition.price,
          currency: selectedCondition.currency
        }
      }
      if (selectedPromo?.state?.manufacturer || selectedPromo?.state?.device) {
        currCompain.device = {
          manufacturer: selectedPromo?.state?.manufacturer,
          model_name: selectedPromo?.state?.device,
          identifier: "imei|serial"
        }
      }
      operationDetails.campaigns.push(currCompain);
    }
    const offer = findSelectedOffer( appState, selectedProviderCode);
    if( offer) {
      const { name: catalog_name, commissionValue, commissionType } = currentProcess?.workflow?.commonOffer?.providers?.find(o => o?.code == selectedProviderCode) || {};
      const  offer_detail = {
        catalog_name: catalog_name ?? selectedProviderCode ?? '',
        sku: offer.sku ?? '',
        grade: offer.grade ?? currentGrade,
        price: offer.price,
        currency: offer.currency_code ?? operationCurrency,
        provider_code: selectedProviderCode ?? '',
        provider_name: catalog_name ?? '',
        selected: selectedProviderCode && selectedProviderCode !== '',
        commission_value: commissionValue,
        commission_type: commissionType,
      };

      operationDetails.offers.push( offer_detail)
      operationDetails.valid_until = offer.expires
    }
    return operationDetails;
  }
  else {
    return undefined;
  }
}

export const getOperationTypeByAssesmentModule = (moduleName: Inspections): any => {
  switch (moduleName) {
    case Inspections.AIGrading: return OperationTypes.GRADING;
    case Inspections.Contract: return OperationTypes.CONTRACT;
    case Inspections.DataCollection: return OperationTypes.DATA_COLLECTION;
    case Inspections.Diagnostics: return OperationTypes.DIAGNOSTICS;
    case Inspections.Erase: return OperationTypes.ERASURE;
    case Inspections.Interview: return OperationTypes.CUSTOM_QUESTIONS;
    case Inspections.Photographic: return OperationTypes.PHOTO_MODERATION;
    case Inspections.Software: return OperationTypes.VERIFY;
    case Inspections.ManualGrading: return OperationTypes.GRADING;
    default: return OperationTypes.DIAGNOSTICS;
  }
}
