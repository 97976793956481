export enum PiceaKeys {
	PICEAMOBILE_DIAGNOSTICS = "piceamobile_diagnostics",
	PICEAMOBILE_VERIFY = "piceamobile_verify",
	PICEAONE_DIAGNOSTICS = "piceaone_diagnostics",
	PICEAONE_VERIFY = "piceaone_verify",
	WEBBASED_DEVICE_CHECK = "webbased_devicecheck",
	ONLINE_MOBILE_BRANDING = "ONLINE_MOBILE_BRANDING"
}

export enum PiceaResultsKeys {
	PICEAMOBILE_DIAGNOSTICS_RESULT = 'piceamobile_diagnostics_result',
	PICEAMOBILE_VERIFY_RESULT = 'piceamobile_verify_result',
	PICEAONE_DIAGNOSTICS_RESULT = 'piceaone_diagnostics_result',
	PICEAONE_VERIFY_RESULT = 'piceaone_verify_result',
	WEBBASED_DEVICE_CHECK_RESULT = "webbased_devicecheck_result"
  }