import { IOfferHub, IOffer, IDevice, IOfferProviderResponse, IPromoProviderResponse, DeviceAttributes, IGradeCategoryGrade, ISelectedPromo } from '@piceasoft/core';
import { IResponseResult } from "../store/typings/IResponseResult";
import { endpoints } from "./endpoints";

export const offerApi = {
    getOffer: async (
        serviceId: number,
        processId: number,
        manufacturer: string,
        name: string,
        configuration: string,
        notify: boolean,
        group?: string,
        imei?: string,
        uid?: string,
    ): Promise<IResponseResult<IOfferHub>> => {
        let result: IResponseResult<IOfferHub> = { successed: false };
        await fetch(endpoints.v1.process.requestOffer(serviceId, processId, manufacturer, name, configuration, notify, group, imei, uid))
            .then(async response => {
                await (response.json() as Promise<IResponseResult<IOfferHub>>)
                    .then(response => result = response)
                    .catch(error => result.errors = [{ description: error.message }])
            })
            .catch(error => result.errors = [{ description: error.message }])
        return result;
    },
    getPreOffer: async (
        serviceId: number,
        processId: number,
        manufacturer: string,
        name: string,
        configuration: string,
        notify: boolean,
        group?: string,
        imei?: string,
        uid?: string,
    ): Promise<IResponseResult<IOfferHub>> => {
        let result: IResponseResult<IOfferHub> = { successed: false };
        await fetch(endpoints.v1.process.requestOffer(serviceId, processId, manufacturer, name, configuration, notify, group, imei, uid))
            .then(async response => {
                await (response.json() as Promise<IResponseResult<IOfferHub>>)
                    .then(response => result = response)
                    .catch(error => result.errors = [{ description: error.message }])
            })
            .catch(error => result.errors = [{ description: error.message }])
        return result;
    },

    // grade parameter is added to the method, in order to be able to get the final price
    // from Trading Vendor API in case of using Grade categories
    getProviderOffer: async (processId: number, providerCode: string, device: IDevice, grade: string | null | undefined, gradeCategories: IGradeCategoryGrade[] | null | undefined, promo?: ISelectedPromo): Promise<IResponseResult<IOfferProviderResponse>> => {

        const promoCondition = promo?.state?.conditions && promo.state.conditions.length > 0 ? promo.state.conditions[0] : undefined
        const selectedCampaign = promoCondition ? {
            name: promo?.state.name,
            type: promoCondition.priceType,
            price: promoCondition.price,
            currency_code: promoCondition.currency
        } : undefined

        const request = {
            process_id: processId,
            provider_code: providerCode,
            grade: grade,
            gradeCategories: gradeCategories,
            device: {
                manufacturer: device.manufacturer,
                model_name: device.name,
                configuration: device.configuration,
                imei: device.attributes[DeviceAttributes.IMEI],
                serial_number: device.attributes[DeviceAttributes.SN]
            },
            campaign: selectedCampaign
        }
        let result: IResponseResult<IOfferProviderResponse> = { successed: false };

        await fetch(endpoints.v1.offerProviderOffers,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request)
            })
            .then(async response => {
                await (response.json() as Promise<IResponseResult<IOfferProviderResponse>>)
                    .then(response => result = response)
                    .catch(error => {
                        result.errors = [{ description: error.message }];
                        console.log(error)
                    })
            })
            .catch(error => result.errors = [{ description: error.message }])
        console.debug("getProviderOffer result: %O", {request: request, result: result});
        return result;
    },
    notifyProvider: async (processId: number, transactionNumber: string, providerCode: string): Promise<IResponseResult<undefined>> => {
        let result: IResponseResult<undefined> = { successed: false };

        await fetch(endpoints.v1.notifyProvider(processId, transactionNumber, providerCode))
            .then(async response => {
                await (response.json() as Promise<IResponseResult<undefined>>)
                    .then(response => result = response)
                    .catch(error => result.errors = [{ description: error.message }])
            })
            .catch(error => result.errors = [{ description: error.message }])
        return result;
    },
    getProviderPromo: async (processId: number, providerCode: string, device: IDevice, grade?: string): Promise<IResponseResult<IPromoProviderResponse>> => {
        let result: IResponseResult<IPromoProviderResponse> = { successed: false };
        await fetch(endpoints.v1.getProviderPromo(processId, providerCode, device, grade))
            .then(async response => {
                await (response.json() as Promise<IResponseResult<IPromoProviderResponse>>)
                    .then(response => result = response)
                    .catch(error => {
                        result.errors = [{ description: error.message }];
                        console.log(error)
                    })
            })
            .catch(error => result.errors = [{ description: error.message }])
        return result;
    },
    getServiceOffer: async (
        serviceId: number,
        manufacturer: string,
        name: string,
        configuration: string,
        group?: string,
    ): Promise<IResponseResult<IOffer[] | undefined>> => {
        let result: IResponseResult<IOffer[] | undefined> = { successed: false };
        await fetch(endpoints.v1.process.requestOffer(serviceId, 0, manufacturer, name, configuration, false, group))
            .then(async response => {
                await (response.json() as Promise<IResponseResult<IOfferHub>>)
                    .then(response => result = { ...response, data: response.data?.items })
                    .catch(error => result.errors = [{ description: error.message }])
            })
            .catch(error => result.errors = [{ description: error.message }])
        return result;
    }
}