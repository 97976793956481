import { Action, Reducer } from "redux";
import { KnownAction } from "../actions/log-actions";
import { ILog } from "@piceasoft/core";

export const reducer: Reducer<ILog> = (state: ILog | undefined, incomingAction: Action): ILog => {
    if (state === undefined) {
        return {
            isFetched: false
        };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOG_RECEIVE_LOGS':
            return { ...state, entries: action.logs, isFetched: true };
        default: return state;
    }
};
