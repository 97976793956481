export const getCurrentTime = (): string => {
    var d = new Date();
    var s = d.getFullYear().toString() +
        "-" + ((d.getMonth() + 1 > 9) ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) +
        "-" + ((d.getDate() + 1 > 9) ? d.getDate().toString() : "0" + d.getDate().toString()) +
        "T" + (d.getHours() > 9 ? d.getHours().toString() : "0" + d.getHours().toString()) +
        ":" + (d.getMinutes() > 9 ? d.getMinutes().toString() : "0" + d.getMinutes().toString()) +
        ":" + (d.getSeconds() > 9 ? d.getSeconds().toString() : "0" + d.getSeconds().toString());
    return s;
}
