import { IResponseError, IKnownDevice } from "@piceasoft/core";
import { IResponseResult } from "../store/typings/IResponseResult";
import { endpoints } from "./endpoints";

export const commonApi = {
    devices: {
        getDevices: async (): Promise<IResponseResult<IKnownDevice[]>> => {
            let result: IResponseResult<IKnownDevice[]> = { successed: false };
            await fetch(endpoints.v1.common.devices.devices)
                .then(async response => {
                    await (response.json() as Promise<IResponseResult<IKnownDevice[]>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        getManufacturers: async (): Promise<IResponseResult<string[]>> => {
            let result: IResponseResult<string[]> = { successed: false };
            await fetch(endpoints.v1.common.devices.manufacturers)
                .then(async response => {
                    await (response.json() as Promise<IResponseResult<string[]>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        getModels: async (manufacturer: string): Promise<IResponseResult<string[]>> => {
            let result: IResponseResult<string[]> = { successed: false };
            await fetch(endpoints.v1.common.devices.models(manufacturer))
                .then(async response => {
                    await (response.json() as Promise<IResponseResult<string[]>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        getConfigurations: async (manufacturer: string, model: string): Promise<IResponseResult<string[]>> => {
            let result: IResponseResult<string[]> = { successed: false };
            await fetch(endpoints.v1.common.devices.configurations, {
                    method: "POST",
                    body: JSON.stringify({manufacturer, model}),
                    headers: {
                        "Content-Type": "application/json"
                }}).then(async response => {
                    await (response.json() as Promise<IResponseResult<string[]>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        }
    },
    files: {
        uploadFile: async (container: string, file: File, setProgress?: (progress: number) => void, noPreview?: boolean): Promise<IResponseResult<string>> => {
            return new Promise((resolve, reject) => {
                let result: IResponseResult<string> = { successed: false };
                let body = new FormData()
                const error: IResponseError = { description: 'Bad response from server' }
                result.errors = [error]

                body.append('uploadFile', file)

                let xhr = new XMLHttpRequest();
                xhr.upload.onprogress = function (event) {
                    setProgress && setProgress(event.loaded / event.total)
                }
                xhr.open("POST", endpoints.v1.uploadFile(container, noPreview))
                xhr.responseType = 'json';
                xhr.onload = function () {
                    if (xhr.status == 200) {
                        resolve(xhr.response);
                    } else {
                        resolve(result);
                    }
                };
                xhr.onerror = function () {
                    resolve(result);
                };
                xhr.send(body);
            });
        },
    }
}