export const getPersonaColorsPallete: { [key: string]: string } = {
    "0": "#4C997F", //
    "1": "#9C640C", //
    "2": "#1DB1EF", //
    "3": "#8CABA8", //
    "4": "#8E708E", //
    "5": "#B22222", //
    "6": "#565656", //
    "7": "#878F87", //
    "8": "#8D6C9F", //
    "9": "#B8A17D", //
    "a": "#4C997F", //
    "b": "#9C640C", //
    "c": "#1DB1EF", //
    "d": "#8CABA8", //
    "e": "#8E708E", //
    "f": "#B22222", //
    "g": "#565656", //
    "h": "#878F87", //
    "i": "#8D6C9F", //
    "j": "#B8A17D", //
    "k": "#8B864E", //
    "l": "#B1C800", //
    "m": "#51A488", //
    "n": "#000080", //
    "o": "#F98400", //
    "p": "#6C3483", //
    "q": "#E59447", //
    "r": "#CD0000", //
    "s": "#36648B", //
    "t": "#00868B", //
    "u": "#228B22", //
    "v": "#D02090", //
    "w": "#8B7E66", //
    "x": "#5B3B1C", //
    "y": "#EFB11D", //
    "z": "#3CB371", //
    "а": "#4C997F",
    "б": "#9C640C",
    "в": "#1DB1EF",
    "г": "#8CABA8",
    "д": "#8E708E",
    "е": "#B22222",
    "ё": "#565656",
    "ж": "#878F87",
    "з": "#8D6C9F",
    "и": "#B8A17D",
    "й": "#8B864E",
    "к": "#B1C800",
    "л": "#51A488",
    "м": "#000080",
    "н": "#F98400",
    "о": "#6C3483",
    "п": "#E59447",
    "р": "#CD0000",
    "с": "#36648B",
    "т": "#00868B",
    "у": "#228B22",
    "ф": "#D02090",
    "х": "#8B7E66",
    "ц": "#5B3B1C",
    "ч": "#EFB11D",
    "ш": "#3CB371",
    "щ": "#895BE5",
    "ы": "#E58900",
    "ъ": "#E58900",
    "ь": "#E58900",
    "э": "#8B864E",
    "ю": "#565656",
    "я": "#993366"
}

export const getIconSymbolsFromString = (text?: string): string => {
    if (!text) return " "
    text = text.trim();
    const skippedWords = [
        "ООО", "OOO", "ИП",
        "\"", ",",
    ]
    const escapedSymbols = [
        "\"", ",", "_",
        "(", ")", "<", ">", "{", "}",

    ]
    return text
        .split(" ")
        .map(w => {
            escapedSymbols.map(s => {
                w = trimChar(w, s)
            })
            return w
        })
        .filter(w => !skippedWords.includes(w))
        .map(w => {
            if (w.length === 0) {
                return ""
            }
            return (w.trim()[0]?.toUpperCase())
        })
        .join("")
        .substr(0, 2)
}

function trimChar(text: string, charToRemove: string): string {
    while (text.charAt(0) == charToRemove) {
        text = text.substring(1);
    }
    while (text.charAt(text.length - 1) == charToRemove) {
        text = text.substring(0, text.length - 1);
    }
    return text;
}
