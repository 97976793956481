import { Stack } from '@fluentui/react'
import React, * as react from 'react'

type TProps = {
    src: string
    contentId: string
    startLocation: string
    setCurentIFrameLocation: () => void
}

export const HelpUrlMode: React.FC<TProps> = (props) => {
    console.log("+")
    console.log("start: " + props.startLocation)
    console.log("cur: " + (document.getElementById(props.contentId) as HTMLIFrameElement)?.contentWindow?.location.pathname)
    const onLoadHandler = () => {
        if (props.src !== (document.getElementById(props.contentId) as HTMLIFrameElement)?.contentWindow?.location.pathname) {
            console.log("++")
            props.setCurentIFrameLocation()
        }
    }

    return (
        <Stack verticalFill>
            <iframe onLoad={onLoadHandler} id={props.contentId} src={props.src} width="100%" height="100%" frameBorder="0" allowFullScreen />
        </Stack>
    )
}
