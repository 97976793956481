import { DeviceSearchItem } from "../../components/process/identifications/Search";
import { ITransactionLog, IDevice } from '@piceasoft/core';
import { DeviceTypes } from "@piceasoft/core";
import { IResponseResult } from "../store/typings/IResponseResult";
import { endpoints } from "./endpoints";
import { IPiceaModel } from "../scripts/picea.typings";

export const identificationApi = {
    // async check(identification: IIdentification): Promise<IApiResponse<undefined>> {
    //     return await getApiResponse(endpoints.v1.identification.check, {
    //         method: "POST",
    //         data: identification,
    //         headers: {
    //             "Content-Type": "application/json"
    //         }
    //     });
    // },
    async global(globalRequest: string, serviceId?: number, types?: DeviceTypes[]): Promise<IResponseResult<DeviceSearchItem[]>> {
        let result: IResponseResult<DeviceSearchItem[]> = { successed: false };
        await fetch(endpoints.v1.identification.global(globalRequest, serviceId, types))
            .then(async response => {
                await (response.json() as Promise<IResponseResult<DeviceSearchItem[]>>)
                    .then(response => result = response)
                    .catch(error => result.errors = [{ description: error.message }])
            })
            .catch(error => result.errors = [{ description: error.message }])
        return result;

    },
    async transaction(search: string): Promise<IResponseResult<ITransactionLog>> {
        let result: IResponseResult<ITransactionLog> = { successed: false };
        await fetch(endpoints.v1.identification.transaction(search))
            .then(async response => {
                await (response.json() as Promise<IResponseResult<ITransactionLog>>)
                    .then(response => result = response)
                    .catch(error => result.errors = [{ description: error.message }])
            })
            .catch(error => result.errors = [{ description: error.message }])
        return result;
    },
    async deviceCompletedTransactions(device: IDevice): Promise<IResponseResult<ITransactionLog[]>> {
        let result: IResponseResult<ITransactionLog[]> = { successed: false };
        await fetch(endpoints.v1.identification.deviceCompletedTransactions, {
            method: "POST",
            body: JSON.stringify(device),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(async response => {
                await (response.json() as Promise<IResponseResult<ITransactionLog[]>>)
                    .then(response => result = response)
                    .catch(error => result.errors = [{ description: error.message }])
            })
            .catch(error => result.errors = [{ description: error.message }])
        return result;
    },
    
    async imei(imei: string, processId: number) : Promise<IResponseResult<IDevice | undefined | null>>  {
        let result: IResponseResult<IDevice | undefined | null> = { successed: false }

        await fetch(endpoints.v1.identification.imei(imei, processId))
            .then(async (response) => {
                await ( response.json() as Promise<IResponseResult<IDevice | undefined | null>>)
                    .then((response) => result = response )
                    .catch((error) => result.errors = [{ description: error.message }])
            })
            .catch((error) => result.errors = [{ description: error.message }])
        return result;
    },
    async imeiLockoutCheck(imei: string, period: number): Promise<IResponseResult<boolean>> {
        let result: IResponseResult<boolean> = { successed: false };
        await fetch(endpoints.v1.identification.imeiLockout(imei, period))
            .then(async response => {
                await (response.json() as Promise<IResponseResult<boolean>>)
                    .then(response => result = response)
                    .catch(error => result.errors = [{ description: error.message }])
            })
            .catch(error => result.errors = [{ description: error.message }])
        return result;
    },
    async modelByImei(imei: string, processId: number) : Promise<IResponseResult<IPiceaModel | undefined | null>>  {
        let result: IResponseResult<IPiceaModel | undefined | null> = { successed: false }

        await fetch(endpoints.v1.identification.modelByImei(imei, processId))
            .then(async (response) => {
                await ( response.json() as Promise<IResponseResult<IPiceaModel | undefined | null>>)
                    .then((response) => result = response )
                    .catch((error) => result.errors = [{ description: error.message }])
            })
            .catch((error) => result.errors = [{ description: error.message }])
        return result;
    },
}
