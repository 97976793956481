import React, * as react from 'react'

type TProps = {
    src: string
}

export const HelpYoutubeMode: React.FC<TProps> = (props) => {

    return (
        <iframe width="100%" height="100%" src={props.src} allow="autoplay" frameBorder="0" allowFullScreen>
            <p>
                <a href="https://developer.mozilla.org/en-US/docs/Glossary">
                    Fallback link for browsers that don't support iframes
                </a>
            </p>
        </iframe>
    )
}