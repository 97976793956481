import { AppThunkAction } from "..";
import { api } from "../api/api";
import { consoleStyles } from "../scripts/console";
import { initializeNotifications } from "../scripts/notifications";
import { IWorkplace, IService } from '@piceasoft/core';

export interface RequestServicesAction { type: 'WORKPLACE_REQUEST_SERVICES' }
export interface ReceiveServicesAction { type: 'WORKPLACE_RECEIVE_SERVICES', services: IService[] }
export interface RequestWorkplaceAction { type: 'WORKPLACE_REQUEST_DATA' }
export interface ReceiveWorkplaceAction { type: 'WORKPLACE_RECEIVE_DATA', data: IWorkplace }

export type KnownAction = RequestServicesAction | ReceiveServicesAction | RequestWorkplaceAction | ReceiveWorkplaceAction

export const actionCreators = {
    /** Запрос на получение данных авторизованного рабочего места. */
    requestWorkplace: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        console.debug("%c" + "LOGIC requestWorkplace()", consoleStyles.logic);
        const appState = getState();
        if (appState && appState.environment && appState.environment.isAuthenticated === true) {
            dispatch({ type: 'WORKPLACE_REQUEST_DATA' });
            const apiResponse = await api.v1.workplace.requestWorkplace();
            if (apiResponse.successed && apiResponse.data) {
                dispatch({ type: 'WORKPLACE_RECEIVE_DATA', data: apiResponse.data });
                initializeNotifications();
            }
        }
    },

    /** Запрос доступных сервисов авторизованного рабочего места. */
    requestServices: (channelId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        console.debug("%c" + "LOGIC requestServices()", consoleStyles.logic);
        const appState = getState();
        if (appState && appState.workplace && appState.workplace.services.items === undefined) {
            dispatch({ type: 'WORKPLACE_REQUEST_SERVICES' });
            const apiResponse = await api.v1.workplace.getServices(channelId);
            if (apiResponse.successed && apiResponse.data) {
                dispatch({ type: 'WORKPLACE_RECEIVE_SERVICES', services: apiResponse.data });
            }
        }
    }
};
