import { IAIGrading, IGrade, IGradingOperationDetails, IGradingItemDetail } from '@piceasoft/core';

export const getGradingOperationDetails = (gradingResult: IAIGrading, grades: IGrade[]): any => {
  const grade = grades?.find(g => g.code === gradingResult.grade);

  let operationDetails: IGradingOperationDetails = { grading_items: [] };

  let grading_items: IGradingItemDetail = {
    grading_item_type: 1,
    timestamp: new Date(Math.round(Date.now() / 1000) * 1000).toISOString().replace(".000Z", "Z"),
    status: gradingResult.status,
    name: "AIGrading",
    grade: undefined,
    custom_grade: grade?.name ?? grade?.code,
    error_code: 0,
    grade_source: 2,
    duration: 0,
    options: {},
    data: []
  };

  operationDetails.grading_items.push(grading_items);
  let worthGrade = 12;
  if (gradingResult.images) {
    gradingResult.images.forEach(f => {
      if(f.response?.image_data){
        f.response?.image_data.forEach(im => {
        if(f.response?.image_grade && f.response?.image_grade < worthGrade){
          worthGrade = f.response?.image_grade;
        }


        operationDetails.grading_items[0].data.push(
            {
              format: im.format,
              type: im.type,
              value: {
                file_id: im?.value.file_id,
                file_mime_type: im?.value.file_mime_type,
                file_type: im?.value.file_type
              }
            }
          );          
        })
      }
    });
   
  }
  grading_items.grade = worthGrade;
  return operationDetails;
}
