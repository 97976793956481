import {
    CommandBarButton, FontIcon, FontWeights, getTheme, IconButton,
    IIconProps, Link, mergeStyleSets, MessageBar, MessageBarType,
    Modal, PrimaryButton, Separator, Stack, Text
} from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import { useBoolean } from '@uifabric/react-hooks';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators } from '../../../core/actions/environment-actions';
import { IStore } from '../../../core/store';
import { strings } from '../../../localization/strings';
import { PiceaConnectionStatuses } from '@piceasoft/core';
const PiceaConnections: React.FC = () => {
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const picea = useSelector((state: IStore) => state.environment.picea);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (picea && picea.status === "available") {
            window.PICEA.onConnected((args) => {
                if (args.error) {
                    dispatch(actionCreators.changePiceaConnection(args.connectionType, PiceaConnectionStatuses.Failed, args.error));
                } else if (args.data?.status && args.data.status === window.ONLINE_API.STATUS_FAILED) {
                    dispatch(actionCreators.changePiceaConnection(args.connectionType, PiceaConnectionStatuses.Failed,
                        { status: args.data.status as number, message: strings.FOOTER.PICEA_CONNECTIONS.MESSAGE_FAIL }));
                } else {
                    dispatch(actionCreators.changePiceaConnection(args.connectionType, PiceaConnectionStatuses.Connected));
                }
            });

            window.PICEA.onDisconnected((args) => {
                if (args.error) {
                    dispatch(actionCreators.changePiceaConnection(args.connectionType, PiceaConnectionStatuses.Failed, args.error));
                } else {
                    dispatch(actionCreators.changePiceaConnection(args.connectionType, PiceaConnectionStatuses.Disconnected));
                }
            });
        }
    }, [picea?.status])

    if (!picea) return null;
    if (picea.status !== "available") return null;

    let otfConnectionStatus;
    let otfConnectionStatusIcon;
    let otfConnectionStatusColor;
    let usbConnectionStatus;
    let usbConnectionStatusIcon;
    let usbConnectionStatusColor;
    let ncdConnectionStatus;
    let ncdConnectionStatusIcon;
    let ncdConnectionStatusColor;

    switch (picea.connections.otf.status) {
        case "connected":
            otfConnectionStatus = strings.FOOTER.PICEA_CONNECTIONS.CONNECTIONS_STATUS_CONNECTED;
            otfConnectionStatusIcon = "PlugConnected";
            otfConnectionStatusColor = SharedColors.green10;
            break;
        case "connecting":
            otfConnectionStatus = strings.FOOTER.PICEA_CONNECTIONS.CONNECTIONS_STATUS_CONNECTING;
            otfConnectionStatusIcon = "Processing";
            otfConnectionStatusColor = SharedColors.orange10;
            break;
        case "disconnected":
            otfConnectionStatus = strings.FOOTER.PICEA_CONNECTIONS.CONNECTIONS_STATUS_DISCONNECTED;
            otfConnectionStatusIcon = "PlugDisconnected";
            otfConnectionStatusColor = SharedColors.gray10;
            break;
        case "failed":
            otfConnectionStatus = strings.FOOTER.PICEA_CONNECTIONS.CONNECTIONS_STATUS_FAIL;
            otfConnectionStatusIcon = "Error";
            otfConnectionStatusColor = SharedColors.red10;
            break;
    }

    switch (picea.connections.usb.status) {
        case "connected":
            usbConnectionStatus = strings.FOOTER.PICEA_CONNECTIONS.CONNECTIONS_STATUS_CONNECTED;
            usbConnectionStatusIcon = "PlugConnected";
            usbConnectionStatusColor = SharedColors.green10;
            break;
        case "connecting":
            usbConnectionStatus = strings.FOOTER.PICEA_CONNECTIONS.CONNECTIONS_STATUS_CONNECTING;
            usbConnectionStatusIcon = "Processing";
            usbConnectionStatusColor = SharedColors.orange10;
            break;
        case "disconnected":
            usbConnectionStatus = strings.FOOTER.PICEA_CONNECTIONS.CONNECTIONS_STATUS_DISCONNECTED;
            usbConnectionStatusIcon = "PlugDisconnected";
            usbConnectionStatusColor = SharedColors.gray10;
            break;
        case "failed":
            usbConnectionStatus = strings.FOOTER.PICEA_CONNECTIONS.CONNECTIONS_STATUS_FAIL;
            usbConnectionStatusIcon = "Error";
            usbConnectionStatusColor = SharedColors.red10;
            break;
    }

    switch (picea.connections.ncd.status) {
        case "connected":
            ncdConnectionStatus = strings.FOOTER.PICEA_CONNECTIONS.CONNECTIONS_STATUS_CONNECTED;
            ncdConnectionStatusIcon = "PlugConnected";
            ncdConnectionStatusColor = SharedColors.green10;
            break;
        case "connecting":
            ncdConnectionStatus = strings.FOOTER.PICEA_CONNECTIONS.CONNECTIONS_STATUS_CONNECTING;
            ncdConnectionStatusIcon = "Processing";
            ncdConnectionStatusColor = SharedColors.orange10;
            break;
        case "disconnected":
            ncdConnectionStatus = strings.FOOTER.PICEA_CONNECTIONS.CONNECTIONS_STATUS_DISCONNECTED;
            ncdConnectionStatusIcon = "PlugDisconnected";
            ncdConnectionStatusColor = SharedColors.gray10;
            break;
        case "failed":
            ncdConnectionStatus = strings.FOOTER.PICEA_CONNECTIONS.CONNECTIONS_STATUS_FAIL;
            ncdConnectionStatusIcon = "Error";
            ncdConnectionStatusColor = SharedColors.red10;
            break;
    }

    return (
        <Stack horizontal>
            <CommandBarButton onClick={showModal} style={{ height: 28, padding: "0 8px" }}>
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                    <FontIcon iconName="InternetSharing" style={{ color: otfConnectionStatusColor }} />
                    <FontIcon iconName="USB" style={{ color: usbConnectionStatusColor }} />
                    {picea.connections.usb.version && (
                        <Text variant="small">{picea.connections.usb.version}</Text>
                    )}
                    <FontIcon iconName="CellPhone" style={{ color: ncdConnectionStatusColor }} />
                </Stack>
            </CommandBarButton>
            <Modal isOpen={isModalOpen} onDismiss={hideModal} containerClassName={contentStyles.container}>
                <div className={contentStyles.header}>
                    <span style={{ paddingRight: 32 }}>{strings.FOOTER.PICEA_CONNECTIONS.TITLE}</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel={strings.FOOTER.PICEA_SOFTWARE.CLOSE_BUTTON_ARIALABEL}
                        onClick={hideModal}
                    />
                </div>
                <div className={contentStyles.body}>
                    <Stack tokens={{ childrenGap: 16 }}>
                        <Stack.Item>
                            <Text style={{ color: SharedColors.gray40 }}>{strings.FOOTER.PICEA_CONNECTIONS.DESCRIPTION}</Text>
                        </Stack.Item>
                        {picea.status === "available" && (
                            <Stack.Item>
                                <MessageBar messageBarType={MessageBarType.info} isMultiline={true}>
                                    <Text>
                                        <Link href={picea.installer} target="_blank">
                                            {strings.FOOTER.PICEA_SOFTWARE.INSTALL_LINK_TEXT}
                                        </Link> <span dangerouslySetInnerHTML={{ __html: strings.FOOTER.PICEA_SOFTWARE.MESSAGE_AVAILABLE }} /></Text>
                                </MessageBar>
                            </Stack.Item>
                        )}
                        {picea.connections.usb.status === "connected" && (
                            <>
                                <Stack.Item>
                                    <Text style={{ color: SharedColors.gray40 }}>{strings.FOOTER.PICEA_SOFTWARE.VERSION_INSTALLED_TEXT}</Text>
                                </Stack.Item>
                                <Stack.Item>
                                    <Text style={{ color: SharedColors.gray40 }}>{picea.connections.usb.version ?? "-"}</Text>
                                </Stack.Item>
                            </>
                        )}
                        {picea.error && (
                            <Stack.Item>
                                <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                                    {picea.error.message}
                                </MessageBar>
                            </Stack.Item>
                        )}
                        {picea.connections.otf.error && (
                            <Stack.Item>
                                <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                                    <b>{strings.FOOTER.PICEA_CONNECTIONS.CONNECTIONS_OTF}</b> {picea.connections.otf.error.message}
                                </MessageBar>
                            </Stack.Item>
                        )}
                        {picea.connections.usb.error && (
                            <Stack.Item>
                                <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                                    <b>{strings.FOOTER.PICEA_CONNECTIONS.CONNECTIONS_USB}</b> {picea.connections.usb.error.message}
                                </MessageBar>
                            </Stack.Item>
                        )}
                        {picea.connections.ncd.error && (
                            <Stack.Item>
                                <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                                    <b>{"strings.FOOTER.PICEA_CONNECTIONS.CONNECTIONS_NCD"}</b> {picea.connections.ncd.error.message}
                                </MessageBar>
                            </Stack.Item>
                        )}
                        <Separator />
                        <Stack.Item>
                            <Stack verticalAlign="center">
                                <Stack horizontal tokens={{ childrenGap: 12 }}>
                                    <Stack.Item>
                                        <Stack horizontal tokens={{ childrenGap: 24 }}>
                                            <Stack.Item>
                                                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                                    <FontIcon iconName="InternetSharing" style={{ color: otfConnectionStatusColor }} />
                                                    <Text style={{ color: otfConnectionStatusColor }}>{otfConnectionStatus}</Text>
                                                </Stack>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                                    <FontIcon iconName="USB" style={{ color: usbConnectionStatusColor }} />
                                                    <Text style={{ color: usbConnectionStatusColor }}>{usbConnectionStatus}</Text>
                                                </Stack>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                                    <FontIcon iconName="CellPhone" style={{ color: ncdConnectionStatusColor }} />
                                                    <Text style={{ color: ncdConnectionStatusColor }}>{ncdConnectionStatus}</Text>
                                                </Stack>
                                            </Stack.Item>
                                        </Stack>
                                    </Stack.Item>
                                    <Stack.Item grow>
                                        <Stack horizontalAlign="end">
                                            <PrimaryButton onClick={hideModal}>{strings.FOOTER.PICEA_CONNECTIONS.BUTTON_OK}</PrimaryButton>
                                        </Stack>
                                    </Stack.Item>
                                </Stack>
                            </Stack>
                        </Stack.Item>
                    </Stack>
                </div>
            </Modal>
        </Stack>
    )
}

export default PiceaConnections

const theme = getTheme();
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        maxWidth: 640
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
