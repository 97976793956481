
import { IosEncryptionStatus } from "../scripts/picea.typings";
import { ContentTransferStages, IContentTransferState } from "../store"


export const isRequiringSourceBackupPasswd = (state?: IContentTransferState) :boolean => {
    if (!state) return false; // no state? not yeat requiring passwd.
    if (state.stage !== ContentTransferStages.PreparingSourceDevice) return false; // setup phase is already completed
    return state.sourceEncryption !== undefined && 
        (state.sourceEncryption.encryption_status !== IosEncryptionStatus.IosEncryption_NotEncrypted && 
        state.sourceEncryption.encryption_status !== IosEncryptionStatus.IosEncryption_CorrectPassword);
}
export const isRequiringTargetBackupPasswd = (state?: IContentTransferState) :boolean => {
    if (!state) return false; // no state? not yeat requiring passwd.
    if (state.stage !== ContentTransferStages.PreparingTargetDevice) return false; // setup phase is already completed
    return state.targetEncryption !== undefined && 
        (state.targetEncryption.encryption_status !== IosEncryptionStatus.IosEncryption_NotEncrypted && 
        state.targetEncryption.encryption_status !== IosEncryptionStatus.IosEncryption_CorrectPassword);
}

/**
 * hasSourceBlockingIssues - issues, other than password query
 */
export const hasSourceBlockingIssues = (state: IContentTransferState) :boolean => {
    return (state.sourceDevStatus === undefined || state.sourceDevStatus.length > 0);
}
/**
 * hasTargetBlockingIssues - issues, other than password query
 */
export const hasTargetBlockingIssues = (state: IContentTransferState) :boolean => {
    return (state.targetDevStatus === undefined || state.targetDevStatus.length > 0);
}

export const isSourceDeviceReady = (state?: IContentTransferState) : boolean => {
    if (!state) return false;

    //  Tips must be dismissed by user.
    if( !state.sourceTipsDismissed && state.sourceTips && state.sourceTips.length > 0)
        return false;

    return !hasSourceBlockingIssues(state) && !isRequiringSourceBackupPasswd(state);
}
export const isTargetDeviceReady = (state?: IContentTransferState) : boolean => {
    if (!state) return false;

    //  Tips must be dismissed by user.
    if( !state.targetTipsDismissed && state.targetTips && state.targetTips.length > 0)
        return false;

    return !hasTargetBlockingIssues(state) && !isRequiringTargetBackupPasswd(state);
}
export const areDevicesReady = (state?: IContentTransferState) : boolean => {
    if (!state) return false;
    return isSourceDeviceReady(state) && isTargetDeviceReady(state);
}
