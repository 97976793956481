import * as log from '../reducers/log-reducer';
import * as environment from '../reducers/environment-reducer';
import * as process from '../reducers/process-reducer';
import * as workplace from '../reducers/workplace-reducer';
import * as teaching from '../reducers/teaching-reducer';
import * as widget from '../reducers/piceawidget-reducer';
import * as googlemaps from '../reducers/googlemaps-reducers';
import * as validationjwt from '../reducers/validationjwt-reducer';

export const reducers = {
    environment: environment.reducer,
    process: process.reducer,
    workplace: workplace.reducer,
    log: log.reducer,
    teaching: teaching.reducer,
    widget: widget.reducer,
    googlemaps: googlemaps.reducer,
    validationjwt: validationjwt.reducer
};


export type { IDeviceIdentificationConfiguration } from './typings/IDeviceIdentificationConfiguration'
export type { IAppleWatchDeviceIdentificationConfiguration } from './typings/IAppleWatchDeviceIdentificationConfiguration'
export type { IAppleWatchAvailableConfiguration } from './typings/IAppleWatchAvailableConfiguration'
// export type { IIdentificationModule } from './typings/IIdentificationModule'
export type { IProcess } from './typings/IProcess'
export type { IProcessHub } from './typings/IProcessHub'
export type { IStore } from './typings/IStore'
export type { InspectionTypes } from './typings/InspectionTypes'
export type { IContentTransferState } from './typings/IContentTransferState'

export { ContentTransferStages } from './typings/ContentTransferStages'