import { IModerationMessage, IPhotoModerationDetail } from '@piceasoft/core';

export const getPhotoModerationOperationDetails = (data: IModerationMessage, gradeDescription?: string | undefined): any => {
  let operationDetails: IPhotoModerationDetail = {
    grade: data.grade,
    grade_description: gradeDescription,
    photo_moderation: []
  }

  if (data.files) {
    data.files.forEach(f => {
      let fileDetails = {
        status: data.status == "completed" ? 0 : 1,
        url: f.link 
      }
      operationDetails.photo_moderation.push(fileDetails);
    })
  }
  return operationDetails;
}
