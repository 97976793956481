import { Action, Reducer } from "redux";

import { KnownAction } from "../actions/contenttransfer-actions";
import { consoleStyles } from "../scripts/console";

import { ProcessStages } from '@piceasoft/core';
import { IProcessHub, IContentTransferState, ContentTransferStages } from "../store";
import { DeviceStorageInfo, IosEncryptionStatus, RestoreState } from "../scripts/picea.typings";
import * as Utils from "../helpers/contentTransferUtils"

import { DeviceErrors, DeviceStatuses } from "../scripts/picea.errors";


export const reducer: Reducer<IProcessHub> = (state: IProcessHub | undefined, incomingAction: Action): IProcessHub => {
    if (state === undefined) {
        return {
            isReady: false,
            sync: {
                haveToBeSynced: false,
                isFetching: false
            }
        };
    }
    const action = incomingAction as KnownAction;
    let newState = { ...state }
    switch (action.type) {
        case 'CONTENTTRANSFER_SET_STATE':
            console.log("called CONTENTTRANSFER_SET_STATE");
            if (newState.current?.stage !== ProcessStages.ContentTransfer) {
                console.log("CONTENTTRANSFER_SET_STATE: but wrong state");
                console.log(newState);
                return newState;
            } 
            newState.current.contentTransfer = action.state;
            return newState;
        case 'CONTENTTRANSFER_RESET_STATE':
            if (newState.current?.stage !== ProcessStages.ContentTransfer) {
                console.log(newState);
                return newState;
            } 
            newState.current.contentTransfer = undefined;
            return newState;
        case 'CONTENTTRANSFER_SET_STAGE':
            if (newState.current?.stage !== ProcessStages.ContentTransfer) {
                console.log(newState);
                return newState;
            } 
            if (!newState.current.contentTransfer) {
                newState.current.contentTransfer = {} as IContentTransferState
            }
            newState.current.contentTransfer.stage = action.stage;
            if (action.stage === ContentTransferStages.CloningPreparation || action.stage === ContentTransferStages.CloningOngoing) {
                newState.current.contentTransfer.isCloningMethod = true;
            }
            return newState;
        case 'CONTENTTRANSFER_SET_DEVICES':
            if (newState.current?.stage !== ProcessStages.ContentTransfer) {
                console.log(newState);
                return newState;
            } 
            if (!newState.current.contentTransfer) {
                newState.current.contentTransfer = {} as IContentTransferState
            }
            newState.current.contentTransfer.selectedSource = action.selectedSource;
            newState.current.contentTransfer.selectedTarget = action.selectedTarget;
            newState.current.contentTransfer.stage = ContentTransferStages.PreparingSourceDevice;

            newState.current.contentTransfer.sourceConnected = true;
            newState.current.contentTransfer.targetConnected = true;
            return newState;
        case 'CONTENTTRANSFER_SET_DEVICE_ENCRYPTION_INFO':
            if (newState.current?.stage !== ProcessStages.ContentTransfer) return newState;
            if (!newState.current.contentTransfer) return newState;
            if (action.device_id === newState.current.contentTransfer.selectedSource?.device_id) {
                newState.current.contentTransfer.sourceEncryption = action.state;
            }
            else if (action.device_id === newState.current.contentTransfer.selectedTarget?.device_id) {
                newState.current.contentTransfer.targetEncryption = action.state;
            }
            return newState;
        case 'CONTENTTRANSFER_RECEIVE_SWITCH_SESSION_OPENED':
            if (newState.current?.stage !== ProcessStages.ContentTransfer) return newState;
            if (!newState.current.contentTransfer) return newState;
            // transfer session is opened.
            newState.current.contentTransfer.switch_session_id = action.switch_session_id;
            return newState;

        case 'CONTENTTRANSFER_SET_INITIAL_TIME_ESTIMATE':
            if (newState.current?.stage !== ProcessStages.ContentTransfer) return newState;
            if (!newState.current.contentTransfer) return newState;
            if (action.forCloning) {
                newState.current.contentTransfer.time_estimate_for_cloning = action.timeEstimate;
            }
            else {
                newState.current.contentTransfer.time_estimate_for_switch = action.timeEstimate;
            }
            return newState;
        case 'CONTENTTRANSFER_RECEIVE_ENUM_STATUS':
            if (newState.current?.stage !== ProcessStages.ContentTransfer) return newState;
            if (!newState.current.contentTransfer) return newState;
            if (action.enumStateData.switch_session_id !== newState.current.contentTransfer.switch_session_id) return newState;
            newState.current.contentTransfer.enumerationStatusData = action.enumStateData;
            return newState;
        case 'CONTENTTRANSFER_RECEIVE_TRANSFER_STATUS':
            if (newState.current?.stage !== ProcessStages.ContentTransfer) return newState;
            if (!newState.current.contentTransfer) return newState;
            if (action.transferStatusData && action.transferStatusData.switch_session_id !== newState.current.contentTransfer.switch_session_id) return newState;
            newState.current.contentTransfer.transferStatusData = action.transferStatusData;
            newState.current.contentTransfer.transferStartDone = (action.transferStatusData !== undefined);
            return newState;
        case 'CONTENTTRANSFER_RECEIVE_IOS_CLONING_STATUS':
            if (newState.current?.stage !== ProcessStages.ContentTransfer) return newState;
            if (!newState.current.contentTransfer) return newState;
            if (action.iosCloningStatusData.switch_session_id !== newState.current.contentTransfer.switch_session_id) return newState;
            newState.current.contentTransfer.iOsCloningStatusData = action.iosCloningStatusData;
            newState.current.contentTransfer.transferStartDone = true;
            return newState;
        case 'CONTENTTRANSFER_RECEIVE_IOS_RESTORE_STATUS':
            if (newState.current?.stage !== ProcessStages.ContentTransfer) return newState;
            if (!newState.current.contentTransfer) return newState;
            if (action.iosRestoreStatusData.switch_session_id !== newState.current.contentTransfer.switch_session_id) return newState;
            newState.current.contentTransfer.iOsRestoreStatusData = action.iosRestoreStatusData;
            return newState;
        case 'CONTENTTRANSFER_RECEIVE_OPERATION_COMPLETED':
            if (newState.current?.stage !== ProcessStages.ContentTransfer) return newState;
            if (!newState.current.contentTransfer) return newState;
            if (action.operationCompletedData.switch_session_id !== newState.current.contentTransfer.switch_session_id) return newState;
            newState.current.contentTransfer.operationCompletedData = action.operationCompletedData;
            if (newState.current.contentTransfer.transferStatusData)
                newState.current.contentTransfer.transferStatusData.remaining_time_estimate = undefined;
            newState.current.contentTransfer.stage = ContentTransferStages.Done;
            if (newState.current.contentTransfer.operationCompletedData.operation_status.switch_details.switch_type === 5) {
                newState.current.contentTransfer.isCloningMethod = true;
            }
            // make sure to close all dialogs
            newState.current.contentTransfer.sourceReconnectNeeded = undefined;
            newState.current.contentTransfer.targetReconnectNeeded = undefined;
            newState.current.contentTransfer.appChangeNeeded = undefined;
            newState.current.contentTransfer.restoreUserActionNeededData = undefined;
            newState.current.contentTransfer.errorCode = undefined;
            newState.current.contentTransfer.errorDesc = undefined;
            return newState;
        case 'CONTENTTRANSFER_RECEIVE_RECONNECT':
            if (newState.current?.stage !== ProcessStages.ContentTransfer) return newState;
            if (!newState.current.contentTransfer) return newState;
            if (action.device_id === newState.current.contentTransfer.selectedSource?.device_id) {
                newState.current.contentTransfer.sourceReconnectNeeded = action.reconnectNeeded;
            }
            else if (action.device_id === newState.current.contentTransfer.selectedTarget?.device_id) {
                newState.current.contentTransfer.targetReconnectNeeded = action.reconnectNeeded;
            }
            return newState;
        case 'CONTENTTRANSFER_RECEIVE_APP_CHANGE_NEEDED':
            if (newState.current?.stage !== ProcessStages.ContentTransfer) return newState;
            if (!newState.current.contentTransfer) return newState;
            if (action.data.switch_session_id !== newState.current.contentTransfer.switch_session_id) return newState;
            if (action.data.device_id === newState.current.contentTransfer.selectedTarget?.device_id) {
                newState.current.contentTransfer.appChangeNeeded = true;
            }
            return newState;
        case 'CONTENTTRANSFER_RECEIVE_APP_CHANGE_DONE':
            if (newState.current?.stage !== ProcessStages.ContentTransfer) return newState;
            if (!newState.current.contentTransfer) return newState;
            if (action.data.switch_session_id !== newState.current.contentTransfer.switch_session_id) return newState;
            newState.current.contentTransfer.appChangeNeeded = false;
            newState.current.contentTransfer.appChangeFailedErr = action.data.error_code;
            return newState;
        case 'CONTENTTRANSFER_RECEIVE_RESTORE_USER_ACTION_NEEDED':
            if (newState.current?.stage !== ProcessStages.ContentTransfer) return newState;
            if (!newState.current.contentTransfer) return newState;
            if (action.data && action.data.switch_session_id !== newState.current.contentTransfer.switch_session_id) return newState;
            if (action.data?.required_action === RestoreState.RestoreState_Finished) {
                // actually no action needed, clear old if any. Connector should not even sent this.
                newState.current.contentTransfer.restoreUserActionNeededData = undefined;
            }
            else {
                newState.current.contentTransfer.restoreUserActionNeededData = action.data;
            }
            return newState;

        case 'CONTENTTRANSFER_RECEIVE_DEVICE_REMOVED':
            if (newState.current?.stage !== ProcessStages.ContentTransfer) return newState;
            if (!newState.current.contentTransfer) return newState;
            console.log("got action: CONTENTTRANSFER_RECEIVE_DEVICE_REMOVED");
            if (newState.current.contentTransfer.selectedSource?.device_id === action.device_id) {
                newState.current.contentTransfer.sourceDevStatus = undefined;
                newState.current.contentTransfer.sourceConnected = false;
            }
            else if (newState.current.contentTransfer.selectedTarget?.device_id === action.device_id) {
                newState.current.contentTransfer.targetDevStatus = undefined;
                newState.current.contentTransfer.targetConnected = false;
            }
            return newState;
        case 'CONTENTTRANSFER_RECEIVE_DEVICE_ADDED':
            if (newState.current?.stage !== ProcessStages.ContentTransfer) return newState;
            if (!newState.current.contentTransfer) return newState;
            console.log("got action: CONTENTTRANSFER_RECEIVE_DEVICE_ADDED");
            if (newState.current.contentTransfer.selectedSource?.device_id === action.device.device_id) {
                newState.current.contentTransfer.sourceConnected = true;
            }
            else if (newState.current.contentTransfer.selectedTarget?.device_id === action.device.device_id) {
                newState.current.contentTransfer.targetConnected = true;
            }
            return newState;
        case 'CONTENTTRANSFER_RECEIVE_DEVICE_UPDATED':
            if (newState.current?.stage !== ProcessStages.ContentTransfer) return newState;
            if (!newState.current.contentTransfer) return newState;
            console.log("got action: CONTENTTRANSFER_RECEIVE_DEVICE_UPDATED");
            return newState;
        case 'CONTENTTRANSFER_RECEIVE_DEVICE_STATUS':
            if (newState.current?.stage !== ProcessStages.ContentTransfer) return newState;
            if (!newState.current.contentTransfer) return newState;
            if (newState.current.contentTransfer.selectedSource?.device_id === action.device_id) {
                newState.current.contentTransfer.sourceDevStatus = action.status;
                newState.current.contentTransfer.sourceTips = action.tips;

                if (newState.current.contentTransfer.sourceDevStatus?.includes(DeviceStatuses.iOS_BackupEncrypted)) {
                    // source backup is encypted/password protected
                    if (newState.current.contentTransfer.sourceEncryption === undefined) {
                        newState.current.contentTransfer.sourceEncryption = {
                            encryption_status: IosEncryptionStatus.IosEncryption_Encrypted
                        }
                    }
                    // we have own state member for iOS_BackupEncrypted, se remove iOS_BackupEncrypted state from DevStatus
                    newState.current.contentTransfer.sourceDevStatus = newState.current.contentTransfer.sourceDevStatus.filter((id) => id !== DeviceStatuses.iOS_BackupEncrypted);
                }
                
                if (Utils.isSourceDeviceReady(newState.current.contentTransfer)) {
                    // source status is ok
                    if (newState.current.contentTransfer.stage === ContentTransferStages.PreparingSourceDevice) {
                        if (newState.current.contentTransfer.targetDevStatus === undefined || newState.current.contentTransfer.targetDevStatus.length > 0) {
                            newState.current.contentTransfer.stage = ContentTransferStages.PreparingTargetDevice;
                        }
                    }
                }
            }
            else if (newState.current.contentTransfer.selectedTarget?.device_id === action.device_id) {
                newState.current.contentTransfer.targetDevStatus = action.status;
                newState.current.contentTransfer.targetTips = action.tips;

                if (newState.current.contentTransfer.targetDevStatus?.includes(DeviceStatuses.iOS_BackupEncrypted)) {
                    // target backup is encypted/password protected
                    if (newState.current.contentTransfer.targetEncryption === undefined) {
                        newState.current.contentTransfer.targetEncryption = {
                            encryption_status: IosEncryptionStatus.IosEncryption_Encrypted
                        }
                    }

                    // we have own state member for iOS_BackupEncrypted, se remove iOS_BackupEncrypted state from DevStatus
                    newState.current.contentTransfer.targetDevStatus = newState.current.contentTransfer.targetDevStatus.filter((id) => id !== DeviceStatuses.iOS_BackupEncrypted);
                }
            }

            return newState;

        case 'CONTENTTRANSFER_SET_TIPS_STATUS':
            if (newState.current?.stage !== ProcessStages.ContentTransfer) return newState;
            if (!newState.current.contentTransfer) return newState;
            if (newState.current.contentTransfer.selectedSource?.device_id === action.device_id) {
                newState.current.contentTransfer.sourceTipsDismissed = !action.tipsEnabled;
            }
            else if (newState.current.contentTransfer.selectedTarget?.device_id === action.device_id) {
                newState.current.contentTransfer.targetTipsDismissed = !action.tipsEnabled;
            }

            return newState;

        case 'CONTENTTRANSFER_RECEIVE_DEVICE_STORAGE_INFO':
            if (newState.current?.stage !== ProcessStages.ContentTransfer) return newState;
            if (!newState.current.contentTransfer) return newState;

            let storageInfo : DeviceStorageInfo | undefined = undefined;
            
            if (action.storages && action.storages?.length > 0) {
                let size_free=0;
                let size_total=0;
                action.storages.map(storage => {
                    size_free += storage.size_free;
                    size_total += storage.size_total;
                });
                storageInfo = {name: '', size_free: size_free, size_total: size_total};
            }

            if (newState.current.contentTransfer.selectedSource?.device_id === action.device_id) {
                newState.current.contentTransfer.sourceStorageInfo = storageInfo;
            }
            else if (newState.current.contentTransfer.selectedTarget?.device_id === action.device_id) {
                newState.current.contentTransfer.targetStorageInfo = storageInfo;
            }
            return newState;

        case 'CONTENTTRANSFER_RECEIVE_IS_TRANSFER_ALLOWED':
            if (newState.current?.stage !== ProcessStages.ContentTransfer) return newState;
            if (!newState.current.contentTransfer) return newState;
            console.log("got action: CONTENTTRANSFER_RECEIVE_IS_TRANSFER_ALLOWED");

            // if device not found error, check has device lost, if it is we already have UI indicating it, disable other errors.
            if (action.data?.result === DeviceErrors.EDP_DEVICE_NOT_FOUND &&  
                (!newState.current.contentTransfer.sourceConnected || !newState.current.contentTransfer.targetConnected)) {
                newState.current.contentTransfer.transferAnalyzeData = undefined;
                return newState;
            }

            newState.current.contentTransfer.transferAnalyzeData = action.data;
            return newState;
        
        case 'CONTENTTRANSFER_RECEIVE_IS_CLONING_ALLOWED':
            if (newState.current?.stage !== ProcessStages.ContentTransfer) return newState;
            if (!newState.current.contentTransfer) return newState;
            console.log("got action: CT_RECEIVE_IS_CLONING_ALLOWED");

            newState.current.contentTransfer.cloningSupport = undefined;
            newState.current.contentTransfer.time_estimate_for_cloning = undefined;
            newState.current.contentTransfer.cloning_data_size_estimate = undefined;

            if (action.data.status === 0) {
                // request succeeded, save response
                newState.current.contentTransfer.cloningSupport = action.data.result;
                newState.current.contentTransfer.time_estimate_for_cloning = action.data.time_estimate;
                newState.current.contentTransfer.cloning_data_size_estimate = action.data.data_size_estimate;
            }
            else {
                // TODO: failed
            }
            
            return newState;

        case 'CONTENTTRANSFER_SET_START_TRANSFER_RESP_CODE':
            if (newState.current?.stage !== ProcessStages.ContentTransfer) return newState;
            if (!newState.current.contentTransfer) return newState;
            newState.current.contentTransfer.startTransferRespCode = action.respCode;
            if (newState.current.contentTransfer.startTransferRespCode !== 0) {
                console.log("startTransferRespCode: %d", action.respCode);
            }
            return newState;

        case 'CONTENTTRANSFER_SET_API_ERROR':
            if (newState.current?.stage !== ProcessStages.ContentTransfer) return newState;
            if (!newState.current.contentTransfer) return newState;
            if (action.error === undefined) {
                newState.current.contentTransfer.errorCode = undefined;
                newState.current.contentTransfer.errorDesc = undefined;
                console.log("CONTENTTRANSFER_SET_API_ERROR: error cleared");
                return newState;
            }
            console.log("got action: CONTENTTRANSFER_SET_API_ERROR: %s", JSON.stringify(action.error));
            newState.current.contentTransfer.errorCode = action.error.details.error;
            newState.current.contentTransfer.errorDesc = action.error.details.message;
            return newState;
        
        case 'CONTENTTRANSFER_CLEAR_API_ERROR':
            if (newState.current?.stage !== ProcessStages.ContentTransfer) return newState;
            if (!newState.current.contentTransfer) return newState;
            newState.current.contentTransfer.errorCode = undefined;
            newState.current.contentTransfer.errorDesc = undefined;
            return newState;

        case 'CONTENTTRANSFER_SET_TARGET_ACTIVATION_STATE':
            if (newState.current?.stage !== ProcessStages.ContentTransfer) return newState;
            if (!newState.current.contentTransfer) return newState;
            newState.current.contentTransfer.targetActivationState = action.state;
            return newState;

        case 'CONTENTTRANSFER_SET_SOURCE_ACTIVATION_STATE':
            if (newState.current?.stage !== ProcessStages.ContentTransfer) return newState;
            if (!newState.current.contentTransfer) return newState;
            newState.current.contentTransfer.sourceActivationState = action.state;
            return newState;



        default: return state;
    }
};