import { Action, Reducer } from "redux";
import { KnownAction } from "../actions/workplace-actions";
import { IWorkplace, parseCountry } from '@piceasoft/core';

export const reducer: Reducer<IWorkplace> = (state: IWorkplace | undefined, incomingAction: Action): IWorkplace => {
    if (state === undefined) {
        return {
            services: {
                isFetching: false
            },
            transactions: []
        };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'WORKPLACE_REQUEST_DATA':
            return state;
        case 'WORKPLACE_RECEIVE_DATA':
            // return { ...state, ...action.data };
            return setWorkplaceData(state, action.data);
        case 'WORKPLACE_REQUEST_SERVICES':
            return { ...state, services: { isFetching: true } };
        case 'WORKPLACE_RECEIVE_SERVICES':
            return { ...state, services: { items: action.services, isFetching: false } };
        default: return state;
    }
};

const setWorkplaceData = (state: IWorkplace, data: IWorkplace): IWorkplace => {
    return {
        ...state,
        ...data,
        point : parseCountry(data.point)
    };
}
