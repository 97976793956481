export const consoleStyles = {
    logic: "color: #2ECC71; font-weight: bold;",
    apiCollback: "color: #A569BD; font-weight: bold;",
    piceaCollback: "color: #0080b4; font-weight: bold;",
    notificationsCallback: "color: #0080b4; font-weight: bold;",
    addEventListener: "color: #F7DC6F;",
    eventHandler: "color: #CD6155; font-weight: bold;",
    dispatchEvent: "color: #00a2df;",
    demo: "color: #DC7633; font-size: 24px;"
}
