import { IDiagnosticCaseResult, IPiceaVerify } from "@piceasoft/core";
import { getApiResponse, IApiResponse } from "./api";
import { endpoints } from "./endpoints";

export const piceaApi = {
    async getVerify(uid: any): Promise<IApiResponse<IPiceaVerify[]>> {
        return await getApiResponse(endpoints.v1.picea.verify(uid));
    },
    async getDiagnostics(uid: any): Promise<IApiResponse<IDiagnosticCaseResult[]>> {
        return await getApiResponse(endpoints.v1.picea.diagnostics(uid));
    }
}