import { Image, PrimaryButton, Stack } from '@fluentui/react';
import * as React from 'react';
import { strings } from '../../../localization/strings';

type TProps = {
    onClick?: () => void
    imgSrc?: string
    info: string
    suggestion?: string
    buttonText?: string
    doNotUseButton?: boolean
    imgHeight?: number
    backgroundColor?: string
}

export const ItemsNotFound: React.FC<TProps> = (props) => {
    return (
        <Stack style={{backgroundColor: props.backgroundColor}} verticalFill verticalAlign="center" horizontalAlign="center">
            <Stack horizontalAlign="center" tokens={{ childrenGap: 16 }} style={{ maxWidth: 520 }}>
                {props.imgSrc && (
                    <Stack.Item>
                        <Image style={{height:props.imgHeight ?? 160}}  src={props.imgSrc} />
                    </Stack.Item>
                )}
                <Stack.Item style={{ fontSize: "1.75rem", fontWeight: "bold", letterSpacing: "-.04em", color: "black", textAlign: "center" }}>
                    {props.info}
                </Stack.Item>
                {props.suggestion && (
                    <Stack.Item style={{ color: "black", textAlign: "center" }}>
                        {props.suggestion}
                    </Stack.Item>
                )}
                {!props.doNotUseButton && (
                        <Stack.Item style={{ paddingTop: 16 }}>
                            <PrimaryButton onClick={props.onClick} text={props.buttonText ?? strings.BUTTONS.TEXT.ADD} />
                        </Stack.Item>
                    )}
            </Stack>
        </Stack>
    )
}