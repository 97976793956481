import { Action, Reducer } from "redux";
import { validatePromotion } from "../../components/helpers/offer";
import { KnownAction } from "../actions/process-actions";
import { consoleStyles } from "../scripts/console";
import { 
    IInspectionConfigUI, IWorkflow, IPhotographic, IInspectionModuleState,
    IInspectionState, ISoftwarePiceaConfig, ITransaction, ISoftwareConfig, IPostOfferConfig, IPreOfferConfig,
    IInspectionConfig, IInspectionModuleConfig, IOfferProviderResponse, IProcessStageConfig, IPromoProviderResponse,
    IInspectionWarning, ProcessStages, InspectionStatuses, SoftwareModes, Inspections, IDiagnosticsPiceaConfig,
    DiagnosticsModes, ICommonOfferConfig, IDiagnosticsConfig, IdentificationMethods, IControlModuleConfig,
    calculateAssesmentGrades, checkoutSkip, 
    IFieldsData
} from '@piceasoft/core';
import {
    IProcess, IProcessHub
} from "../store";
import { IResponseResult } from "../store/typings/IResponseResult";
import { reducer as contentTransferReducer } from './contenttransfer-reducer';
import { keyBy, mergeWith } from "lodash";

export const reducer: Reducer<IProcessHub> = (state: IProcessHub | undefined, incomingAction: Action): IProcessHub => {
    if (state === undefined) {
        return {
            isReady: false,
            sync: {
                haveToBeSynced: false,
                isFetching: false
            }
        };
    }

    const action = incomingAction as KnownAction;

    // contentTransfer has own reducer
    if (action.type.startsWith('CONTENTTRANSFER_')) {
        return contentTransferReducer(state, action);
    }


    let newState = { ...state }
    switch (action.type) {
        case 'PROCESS_INITIALIZATION_REQUEST':
            return {
                isReady: false,
                sync: {
                    haveToBeSynced: false,
                    isFetching: false
                }
            };
        case 'PROCESS_INITIALIZATION_IS_READY': {
            const instance = prepareInitializedProcess(action.instance);
            return {
                current: instance,
                isReady: true,
                sync: {
                    state: instance,
                    haveToBeSynced: false,
                    isFetching: false
                }
            }
        }
        case 'PROCESS_SYNC_START':
            if (!newState.current) return state;
            return {
                ...newState,
                sync: {
                    ...newState.sync,
                    haveToBeSynced: false,
                    isFetching: true,
                    fetchTimestamp: Date.now()
                }
            }
        case 'PROCESS_SYNC_STOP':
            return {
                ...newState,
                sync: {
                    ...newState.sync,
                    isFetching: false,
                    fetchTimestamp: undefined
                }
            }
        case 'PROCESS_SYNC_UPDATE_STATE':
            if (!newState.current) return state;
            return {
                ...newState,
                sync: {
                    ...newState.sync,
                    state: { ...newState.current },
                    haveToBeSynced: true
                }
            }
        case 'PROCESS_INITIALIZATION_FAIL':
            return {
                isReady: false,
                error: action.error,
                sync: {
                    haveToBeSynced: false,
                    isFetching: false
                }
            }
        case 'PROCESS_SESSION':
            if (!state.current) return state;
            return {
                ...state, current: {
                    ...state.current,
                    session_id: action.session_id
                }
            }
        case 'PROCESS_SESSION_CLEAR':
            if (!state.current) return state;
            return {
                ...state, current: {
                    ...state.current,
                    session_id: undefined
                }
            }
        case 'PROCESS_MESSAGE':
            if (!state.current || state.current.message?.isBlocker) return state;
            return {
                ...state,
                current: {
                    ...state.current,
                    message: action.message
                }
            }
        case 'PROCESS_MESSAGE_BLOCKER_DIALOG_TOGGLE':
            if (!state.current || !state.current.message?.isBlocker) return state;
            return {
                ...state,
                current: {
                    ...state.current,
                    message: {
                        ...state.current.message,
                        isBlocker: {
                            ...state.current.message?.isBlocker,
                            hideDialog: !state.current.message?.isBlocker?.hideDialog
                        }
                    }
                }
            }
        case 'PROCESS_MESSAGE_CLEAR':
            if (!state.current || (state.current.message?.isBlocker && !action.force)) return state;
            if (action.code && state.current.message?.code !== action.code) return state;
            return {
                ...state,
                current: {
                    ...state.current,
                    message: undefined
                }
            }
        case 'PROCESS_RECEIVE_IDENTIFICATION':
            if (!state.current) return state;

            if (state.current?.identification?.device?.attributes) {
                // Merge attributes to be able to save manualy selected.

                Object.keys(action.device.attributes).forEach((key) => {
                    if (action.device.attributes[key]) {
                        const attr = state.current?.identification?.device.attributes;

                        if (attr) {
                            attr[key] = action.device.attributes[key];
                        }
                    }
                });

                action.device.attributes = state.current?.identification?.device.attributes;
            }

            return {
                ...state, current: {
                    ...state.current, identification: {
                        isReady: action.device.manufacturer && action.device.name && action.device.configuration ? true : false,
                        device: action.device,
                        method: action.method,
                        isAuthenticated: action.isAuthenticated,
                        isConnected: action.isConnected,
                        stamp: action.stamp,
                        warning: action.warning,
                        index: action.moduleIndex
                    }
                }
            }
        case 'PROCESS_RECEIVE_CONTROLLED_TRANSACTION':
            if (!state.current) return state;
            return {
                ...state, current: {
                    ...state.current,
                    controlledTransaction: action.data
                }
            }
        case 'PROCESS_WITHOUT_CONTROLLED_TRANSACTION':
            if (!state.current) return state;
            return {
                ...state, current: {
                    ...state.current,
                    withoutControlledTransaction: true
                }
            }
        case 'PROCESS_TRANSACTION_FETCH_START':
            if (!newState.current) return state;
            newState.current.transaction.isFetching = true;
            return newState;
        case 'PROCESS_TRANSACTION_FETCH_STOP':
            if (!newState.current) return state;
            newState.current.transaction.isFetching = undefined;
            return newState;
        case 'PROCESS_CLEAR_IDENTIFICATION':
            if (!state.current || !state.current.identification) return state;
            return {
                ...state, current: {
                    ...state.current,
                    message: undefined,
                    stageCompleted: (state.current.stage === ProcessStages.Identification ? false : state.current.stageCompleted),
                    identification: undefined
                }
            }
        case 'PROCESS_CLEAR_CONTROLLED_TRANSACTION':
            if (!state.current || !state.current.controlledTransaction) return state;
            return {
                ...state, current: {
                    ...state.current,
                    controlledTransaction: undefined,
                    withoutControlledTransaction: undefined
                }
            }
        case 'PROCESS_CLEAR_OFFERS':
            if (!state.current || !state.current.offers) return state;
            return {
                ...state, current: {
                    ...state.current,
                    transaction: {
                        ...state.current.transaction,
                        discount: undefined
                    },
                    stageCompleted: state.current.stage === ProcessStages.PreOffer ? false : state.current.stageCompleted,
                    offers: {
                        isFetching: false
                    }
                }
            }
        case 'PROCESS_TRANSACTION_STAGE_UNLOCK_PICEASOFT_MODULES': {
            if (!newState.current) return newState;
            const unlockStage = newState.current.stages.find(i => i.index === action.stageIndex)?.type ?? newState.current.stage

            switch (unlockStage) {
                case ProcessStages.Assessment:
                    newState.current.transaction.assessment.modules = newState.current.transaction.assessment.modules.map(m => {
                        const config = newState.current?.workflow.assessment?.modules.find(i => i.index === m.index)?.config
                        if (
                            (
                                m.type === Inspections.Software ||
                                (m.type === Inspections.Diagnostics && (config as IDiagnosticsConfig).mode !== DiagnosticsModes.PiceaMobile)
                            ) &&
                            (m.state.status === InspectionStatuses.Lock || m.index === action.fromModuleIndex)
                        ) {
                            return {
                                ...m,
                                state: {
                                    ...m.state,
                                    status: InspectionStatuses.New
                                }
                            }
                        }
                        return m
                    })
                    newState.current.transaction.assessment.modules = getAutorunPreparedModules(
                        { ...newState.current.transaction, workflow: newState.current.workflow },
                        newState.current.transaction.assessment.modules,
                        newState.current.workflow.assessment?.modules,
                        action.fromModuleIndex)

                    return newState;

                case ProcessStages.Control:
                    newState.current.transaction.control.modules = newState.current.transaction.control.modules.map(m => {
                        const config = newState.current?.workflow.assessment?.modules.find(i => i.index === m.index)?.config
                        if (
                            (
                                m.type === Inspections.Software ||
                                (m.type === Inspections.Diagnostics && (config as IDiagnosticsConfig).mode !== DiagnosticsModes.PiceaMobile)
                            ) && (m.state.status === InspectionStatuses.Lock || m.index === action.fromModuleIndex)) {
                            return {
                                ...m,
                                state: {
                                    ...m.state,
                                    status: InspectionStatuses.New
                                }
                            }
                        }
                        return m
                    })
                    newState.current.transaction.control.modules = getAutorunPreparedModules(
                        { ...newState.current.transaction, workflow: newState.current.workflow },
                        newState.current.transaction.control.modules,
                        newState.current.workflow.control?.modules,
                        action.fromModuleIndex)

                    return newState;
                case ProcessStages.PostOffer:
                    newState.current.transaction.postOffer.modules = newState.current.transaction.postOffer.modules.map(m => {
                        const config = newState.current?.workflow.postOffer?.modules.find(i => i.index === m.index)?.config
                        if (
                            (
                                m.type === Inspections.Software ||
                                (m.type === Inspections.Diagnostics && (config as IDiagnosticsConfig).mode !== DiagnosticsModes.PiceaMobile)
                            ) && (m.state.status === InspectionStatuses.Lock || m.index === action.fromModuleIndex)) {
                            return {
                                ...m,
                                state: {
                                    ...m.state,
                                    status: InspectionStatuses.New
                                }
                            }
                        }
                        return m
                    })
                    newState.current.transaction.postOffer.modules = getAutorunPreparedModules(
                        { ...newState.current.transaction, workflow: newState.current.workflow },
                        newState.current.transaction.postOffer.modules,
                        newState.current.workflow.postOffer?.modules,
                        action.fromModuleIndex)

                    return newState;
                default: return newState
            }
        }
        case 'PROCESS_TRANSACTION_STAGE_LOCK_PICEASOFT_MODULES': {
            if (!state.current) return state;
            const lockStage = state.current.stages.find(i => i.index === action.stageIndex)?.type ?? state.current.stage

            switch (lockStage) {
                case ProcessStages.Assessment:
                    return {
                        ...state,
                        current: {
                            ...state.current,
                            transaction: {
                                ...state.current.transaction,
                                assessment: {
                                    ...state.current.transaction.assessment,
                                    modules: state.current.transaction.assessment.modules.map(m => {
                                        const config = newState.current?.workflow.assessment?.modules.find(i => i.index === m.index)?.config
                                        if (
                                            [Inspections.Diagnostics, Inspections.Software].includes(m.type) &&
                                            (
                                                m.type === Inspections.Software && ![SoftwareModes.PiceaMobile].includes((config as ISoftwareConfig).mode) ||
                                                (m.type === Inspections.Diagnostics && (config as IDiagnosticsConfig).mode !== DiagnosticsModes.PiceaMobile)
                                            ) &&
                                            m.state.status === InspectionStatuses.New
                                        ) {
                                            return {
                                                ...m,
                                                state: {
                                                    ...m.state,
                                                    status: InspectionStatuses.Lock,
                                                    canAutorun: undefined
                                                }
                                            }
                                        }
                                        return m
                                    })
                                }
                            }
                        }
                    }
                case ProcessStages.Control:
                    return {
                        ...state,
                        current: {
                            ...state.current,
                            transaction: {
                                ...state.current.transaction,
                                control: {
                                    ...state.current.transaction.control,
                                    modules: state.current.transaction.control.modules.map(m => {
                                        if ([Inspections.Software].includes(m.type) && m.state.status === InspectionStatuses.New) {
                                            return {
                                                ...m,
                                                state: {
                                                    ...m.state,
                                                    status: InspectionStatuses.Lock,
                                                    canAutorun: undefined
                                                }
                                            }
                                        }
                                        return m
                                    })
                                }
                            }
                        }
                    }
                case ProcessStages.PostOffer:
                    return {
                        ...state,
                        current: {
                            ...state.current,
                            transaction: {
                                ...state.current.transaction,
                                postOffer: {
                                    ...state.current.transaction.postOffer,
                                    modules: state.current.transaction.postOffer.modules.map(m => {
                                        if ([Inspections.Software].includes(m.type) && m.state.status === InspectionStatuses.New) {
                                            return {
                                                ...m,
                                                state: {
                                                    ...m.state,
                                                    status: InspectionStatuses.Lock,
                                                    canAutorun: undefined
                                                }
                                            }
                                        }
                                        return m
                                    })
                                }
                            }
                        }
                    }
                default: return state
            }
        }
        case 'PROCESS_IDENTIFICATION_CHANGE_CONNECTION_STATUS': {
            if (!state.current || !state.current.identification) return state;
            const stamp = action.stamp ?? state.current.identification.stamp;
            return {
                ...state, current: {
                    ...state.current, identification: {
                        ...state.current.identification, isConnected: action.status, stamp: stamp
                    }
                }
            }
        }
        case 'PROCESS_REQUEST_PRE_OFFER':
            if (!newState.current?.identification) return state;
            newState.current.offers = { ...state.current?.offers, isFetching: true };
            return newState;
        case 'PROCESS_RECEIVE_PRE_OFFER':
            if (!newState.current?.identification) return state;
            newState.current.offers = {
                ...action.offer,
                items: action.offer.items?.map(i => ({ ...i, currency_code: action.offer.currency })),
                isFetching: false
            }
            return newState
        case 'PROCESS_REQUEST_PROVIDER_PROMO':
            if (!newState.current?.workflow?.commonOffer?.allowDiscount || !newState.current?.workflow?.commonOffer?.promoProviders) return state;
            newState.current.promoProviders = [...(newState.current?.promoProviders?.filter(i => i.code !== action.code) ?? []), { code: action.code, type: action.providerType, requested: true }]
            return newState;
        case 'PROCESS_RECEIVE_PROVIDER_PROMO': {
            if (!newState.current) return state;
            const newPromoState: IResponseResult<IPromoProviderResponse> = {
                ...action.response,
                data: {
                    ...action.response.data,
                    organization_id: action.response.data?.organization_id ?? "",
                    process_id: newState.current.id
                }
            }
            newState.current.promoProviders = newState.current?.promoProviders?.map(i => {
                if (i.code === action.providerCode) {
                    i.result === action.response.data
                    return { code: action.providerCode, type: action.providerType, result: newPromoState, requested: true }
                }
                return i
            }) ?? []
            return newState;
        }
        case 'PROCESS_REQUEST_PROVIDER_OFFER':
            if (!newState.current?.workflow?.commonOffer?.providers) return state;
            newState.current.offerProviders = [...(newState.current?.offerProviders?.filter(i => i.code !== action.code) ?? []), { code: action.code, type: action.providerType, requested: true }]
            return newState;
        case 'PROCESS_RECEIVE_PROVIDER_OFFER': {
            if (!newState.current) return state;
            const newOfferState: IResponseResult<IOfferProviderResponse> = {
                ...action.response,
                data: {
                    ...action.response.data,
                    organization_id: action.response.data?.organization_id ?? "",
                    process_id: newState.current.id
                }
            }
            newState.current.offerProviders = newState.current?.offerProviders?.map(i => {
                if (i.code === action.providerCode) {
                    i.result === action.response.data
                    return { code: action.providerCode, type: action.providerType, result: newOfferState, requested: true }
                }
                return i
            }) ?? []
            return newState;
        }
        case 'PROCESS_TRANSACTION_PROVIDER_OFFER_SELECT': {
            if (!newState.current?.transaction) return state;
            newState.current.transaction.selectedProvider = action.providerCode;
            newState.current.transaction.selectedProviderOrganizationId = action.organizationId;
            newState.current.transaction.assessment.price = action.price;
            newState.current.transaction.priceWithoutCommission = action.priceWithoutCommission;
            if (action.currency) {
                newState.current.transaction.currency = action.currency;
            }
            newState.current.transaction.sku = action.sku;

            const _selectedPromo = newState?.current?.transaction.selectedPromo
            const _promoProviderState = newState?.current?.promoProviders?.find(i => i.code === _selectedPromo?.provider_code)
            const _offerProviderConfig = newState.current.workflow.commonOffer?.providers?.find(i => i.code === newState.current?.transaction.selectedProvider)
            const _providerPromo = _promoProviderState?.result?.data?.promotions?.find(i => i.id === _selectedPromo?.promo_id)

            if (_selectedPromo && _providerPromo) {
                newState.current.transaction.isValidPromotion = validatePromotion(
                    { ..._providerPromo, provider_code: _selectedPromo.provider_code },
                    newState.current.transaction.assessment.grade,
                    newState.current.transaction.selectedProvider,
                    _offerProviderConfig?.allowPromotions
                );
            }

            return newState;
        }
        case 'PROCESS_NEXT_STAGE_INDEX': {
            console.log('PROCESS_NEXT_STAGE_INDEX: ' + action.nextIndex)
            if (!newState.current) return state;
            const nextStageConfig = newState.current.workflow.stages?.find(s => s.index === action.nextIndex)
            console.log(nextStageConfig)
            if (!nextStageConfig) return state;
            newState.current.stages = newState.current.stages.map(s => {
                if (s.index === newState.current?.stageIndex) {
                    return {
                        ...s,
                        completed: true
                    }
                }
                return s
            })
            newState.current.stageIndex = nextStageConfig.index;
            newState.current.stage = nextStageConfig.type;
            newState.current.stageCompleted = false;
            newState.current.stageAction = undefined;
            return newState;
        }
        case 'PROCESS_RECEIVE_PREVIOUS_STAGE': {
            console.log('PROCESS_RECEIVE_PREVIOUS_STAGE')
            if (!newState.current) return state;
            const stageIndex = newState.current?.stageIndex;
            if (typeof stageIndex !== "number") return state;
            const previousStages = newState.current.workflow.stages?.filter(s => s.index < stageIndex) ?? [];
            const previousStageConfig = previousStages[previousStages.length - 1];
            if (!previousStageConfig) return state;
            newState.current.stages = newState.current.stages.map(s => {
                if (s.index === previousStageConfig?.index) {
                    return {
                        ...s,
                        completed: false
                    }
                }
                return s
            })
            newState.current.stageIndex = previousStageConfig.index;
            newState.current.stage = previousStageConfig.type;
            newState.current.stageCompleted = false;
            return newState;
        }
        case 'PROCESS_PREPARE_OFFER_PROVIDERS_STATE': {
            if (!newState.current) return state;
            const providers = newState.current.workflow.commonOffer?.providers;
            if (providers) {
                newState.current.offerProviders = providers.map(i => ({ code: i.code, type: i.type }))
            }
            return newState;
        }
        case 'PROCESS_PREPARE_PROMO_PROVIDERS_STATE': {
            if (!newState.current || !newState.current.workflow.commonOffer?.allowDiscount) return state;
            const promoProviders = newState.current.workflow.commonOffer?.promoProviders;
            if (promoProviders) {
                newState.current.promoProviders = promoProviders?.map(i => ({ code: i.code, type: i.type })) ?? []
            }
            return newState;
        }
        case 'PROCESS_RECEIVE_STAGE_COMPLETED':
            if (!newState.current) return state;
            // console.log(`PROCESS_RECEIVE_CURRENT_STAGE_COMPLETED: (${action.completed ?? true}) ` + newState.current.stageIndex)
            newState.current.stageCompleted = action.completed ?? true;
            if (newState.current.message?.isBlocker) {
                newState.current.stageCompleted = false
            }
            return newState;
        case 'PROCESS_RECEIVE_STAGE_ACTION':
            if (!newState.current) return state;
            newState.current.stageAction = action.stageAction;
            return newState;
        case 'PROCESS_RECEIVE_BACK_ACTION':
            if (!newState.current) return state;
            newState.current.backButtonAction = action.backAction;
            return newState;
        case 'PROCESS_TRANSACTION_RECEIVE_DISCOUNT':
            if (!newState.current?.transaction) return state;
            newState.current.transaction.discount = action.discount;
            return newState;
        case 'PROCESS_TRANSACTION_REMOVE_DISCOUNT':
            if (!newState.current?.transaction.discount) return state;
            newState.current.transaction.discount = undefined;
            return newState;
        case 'PROCESS_TRANSACTION_RECEIVE_PROMOTION':
            if (!newState.current?.transaction) return state;
            newState.current.transaction.selectedPromo = action.promo
            newState.current.transaction.isValidPromotion = action.isValid

            return newState;
        case 'PROCESS_TRANSACTION_REMOVE_PROMOTION':
            if (!newState.current?.transaction.selectedPromo) return state;
            newState.current.transaction.selectedPromo = undefined;
            return newState;
        case 'PROCESS_CANCEL':
            newState.finished = true
            return newState;
        case 'PROCESS_REQUEST_TRANSACTION':
            return newState;
        case 'PROCESS_RECEIVE_TRANSACTION': {
            if (!newState.current) return state;
            const selectedPromotion = newState.current.transaction.selectedPromo;
            newState.current.transaction = action.transaction;
            newState.current.transaction.selectedPromo = selectedPromotion;
            if (newState.current.workflow.assessment && !newState.current.workflow.version) {
                newState.current.transaction.assessment.monitoring = {}
                if (newState.current.workflow.assessment.inspections?.interview) {
                    newState.current.transaction.assessment.inspections[Inspections.Interview] = { status: InspectionStatuses.New };
                    newState.current.transaction.assessment.monitoring[Inspections.Interview] = { count: 0, duration: 0 };
                }
                if (newState.current.workflow.assessment.inspections?.photographic) {
                    newState.current.transaction.assessment.inspections[Inspections.Photographic] = { status: InspectionStatuses.New };
                    newState.current.transaction.assessment.monitoring[Inspections.Photographic] = { count: 0, duration: 0 };
                }
                if (newState.current.workflow.assessment.inspections?.diagnostics) {
                    newState.current.transaction.assessment.inspections[Inspections.Diagnostics] = { status: InspectionStatuses.New };
                    newState.current.transaction.assessment.monitoring[Inspections.Diagnostics] = { count: 0, duration: 0 };
                }
                if (newState.current.workflow.assessment.inspections?.software) {
                    newState.current.transaction.assessment.inspections[Inspections.Software] = { status: InspectionStatuses.New };
                    newState.current.transaction.assessment.monitoring[Inspections.Software] = { count: 0, duration: 0 };
                }
            }

            if (newState.current.workflow.assessment && newState.current.workflow.version) {
                newState.current.transaction.assessment.modules = newState.current.workflow.assessment.modules.map(m => (
                    {
                        index: m.index,
                        type: m.type,
                        state: {
                            status: (
                                ![IdentificationMethods.PiceaOne, IdentificationMethods.PiceaUsb].includes(newState.current?.transaction.identity?.method as IdentificationMethods) &&
                                ((m.type === Inspections.Software && (m.config as ISoftwareConfig).mode === SoftwareModes.Piceasoft) || (m.type === Inspections.Diagnostics && (m.config as IDiagnosticsConfig).mode === DiagnosticsModes.Piceasoft))
                            ) ? InspectionStatuses.Lock : InspectionStatuses.New
                        },
                        monitoring: { count: 0, duration: 0 }
                    } as IInspectionModuleState))

                newState.current.transaction.assessment.modules = getAutorunPreparedModules({ ...newState.current.transaction, workflow: newState.current.workflow }, newState.current?.transaction.assessment.modules, newState.current?.workflow.assessment?.modules);
            }

            if (newState.current.workflow.control && newState.current.workflow.version) {
                newState.current.transaction.control = {
                    modules: []
                }
                newState.current.transaction.control.modules = newState.current.workflow.control.modules.map(m => (
                    {
                        index: m.index,
                        type: m.type,
                        state: {
                            status: (
                                ![IdentificationMethods.PiceaOne, IdentificationMethods.PiceaUsb].includes(newState.current?.transaction.identity?.method as IdentificationMethods) &&
                                (m.type === Inspections.Software || (m.type === Inspections.Diagnostics && (m.config as IDiagnosticsConfig).mode === DiagnosticsModes.Piceasoft))
                            ) ? InspectionStatuses.Lock : InspectionStatuses.New
                        },
                        monitoring: { count: 0, duration: 0 }
                    } as IInspectionModuleState))

                newState.current.transaction.control.modules = getAutorunPreparedModules({ ...newState.current.transaction, workflow: newState.current.workflow }, newState.current?.transaction.control.modules, newState.current?.workflow.control?.modules);
            }

            if (newState.current.workflow.postOffer?.modules && newState.current.workflow.version) {
                newState.current.transaction.postOffer = {
                    modules: []
                }
                newState.current.transaction.postOffer.modules = newState.current.workflow.postOffer.modules.map(m => (
                    {
                        index: m.index,
                        type: m.type,
                        state: {
                            status: (
                                ![IdentificationMethods.PiceaOne, IdentificationMethods.PiceaUsb].includes(newState.current?.transaction.identity?.method as IdentificationMethods) &&
                                ((m.type === Inspections.Software && (m.config as ISoftwareConfig).mode === SoftwareModes.Piceasoft) || (m.type === Inspections.Diagnostics && (m.config as IDiagnosticsConfig).mode === DiagnosticsModes.Piceasoft))
                            ) ? InspectionStatuses.Lock : InspectionStatuses.New
                        },
                        monitoring: { count: 0, duration: 0 }
                    } as IInspectionModuleState))

                newState.current.transaction.postOffer.modules = getAutorunPreparedModules(
                    { ...newState.current.transaction, workflow: newState.current.workflow },
                    newState.current?.transaction.postOffer.modules, newState.current?.workflow.postOffer?.modules);
            }
            return newState;
        }
        case 'PROCESS_TRANSACTION_UPDATE_IDENTITY':
            if (!newState.current?.transaction.identity) return state;
            newState.current.transaction.identity = {
                ...action.identity
            }
            return newState;
        case 'PROCESS_TRANSACTION_INSPECTION_MODULE_TRACK_LAUNCH':
            if (!newState.current?.transaction.assessment.modules.find(m => m.index === action.index)) return state;
            newState.current.transaction.assessment.modules = newState.current.transaction.assessment.modules.map(m => {
                if (m.index === action.index) {
                    return {
                        ...m,
                        monitoring: {
                            ...m.monitoring,
                            count: m.monitoring.count + 1
                        }
                    }
                }
                return m
            })
            return newState;
        case 'PROCESS_TRANSACTION_ASSESSMENT_RECEIVE_RESULT':
            if (!newState.current?.transaction) return state;
            newState.current.transaction.assessment = {
                ...newState.current.transaction.assessment,
                grade: action.grade,
                price: action.price
            }
            return newState;
        // case 'PROCESS_TRANSACTION_INSPECTION_AIGRADING_RECEIVE_FILE_STATE':
        //     return receiveCurrentStageAIGradeingFileState(newState, action.index, action.imageType, action.uploadProgress, action.response)
        case 'PROCESS_TRANSACTION_INSPECTION_MODULE_RECEIVE_STATE':
            return receiveCurrentStageInspectionState(newState, action.index, action.result);
        case 'PROCESS_TRANSACTION_INSPECTION_MODULE_RECEIVE_WARNING':
            return receiveCurrentStageInspectionWarning(newState, action.index, action.warning);
        case 'PROCESS_TRANSACTION_INSPECTION_MODULE_RESET_FILES':
            return resetCurrentStageInspectionFiles(newState, action.index);
        case 'PROCESS_TRANSACTION_INSPECTION_MODULE_UPLOAD_PROGRESS':
            return uploadProgressCurrentStageInspectionFile(newState, action.index, action.uploadProgress);
        case 'PROCESS_TRANSACTION_INSPECTION_MODULE_CHANGE_STATUS':
            return receiveCurrentStageInspectionStatus(newState, action.index, action.status, action.wasAborted, action.canAutorun);
        case 'PROCESS_TRANSACTION_RECEIVE_CONTRACT':
            if (newState.current?.stage !== ProcessStages.Contract) return newState;
            newState.current.transaction.contract = { fields: action.data }
            newState.current.stageCompleted = action.completeStage;
            return newState;
        case 'PROCESS_TRANSACTION_PREFILL_CONTRACT':
            if (!newState.current) return newState;
            /*
            *   Workflow may have several data collection modules.
            *   Each data collection module can bring it's one sent of data
            *   All these data should be pre-populated on the Print form step (in case particulat fields are defined on)
            *   So data from all Data collection modules should be merged together. 
            *   In case of a collision between fields 
            *   (value for the same field exist in all data collection modules) then last one wins.
            */
            newState.current.transaction.contract = { 
                fields: mergeWith({}, newState.current.transaction.contract?.fields, action.data, 
                    (objValue: IFieldsData|undefined, srcValue: IFieldsData|undefined, key: string) => {

                    if (Array.isArray(objValue) && (key === 'customFields' || key === 'attachments')) {
                        // Create a map of customFields/attachments by `key`
                        const mergedArray = keyBy(objValue, 'key');
                        const srcArray = keyBy(srcValue, 'key');
                
                        // Merge the arrays by key, taking values from src if not null/empty
                        const result = mergeWith(mergedArray, srcArray, (objVal, srcVal) => {
                            return srcVal !== null && srcVal !== '' ? srcVal : objVal;
                        });
                
                        // Return as an array
                        return Object.values(result)
                    }
                }) }
            return newState;
        case 'SET_CURRENT_OPERATION':
            if (!newState.current) return state;
            newState.current.currentOperation = action.currentOperation;
            return newState;
        case 'PROCESS_IMEI_LOCKED':
            if (!state.current || !newState.current) {
                return state;
            }
            newState.current.imeiLocked = action.imeiLocked;
            return newState;
        case 'PROCESS_SET_PICEA_SESSION_STATE':
            if (!newState.current) return state;
            newState.current.session_state = action.session_state;
            return newState;
        case 'CLEAR_TRANSACTION_IDENTITY':
            if (!state.current || !state.current.transaction || !state.current.transaction.identity) return state;
            return {
                ...state, current: {
                    ...state.current,
                    transaction: {
                        ...state.current.transaction,
                        identity: undefined
                    }
                }
            }

        default: return state;
    }
};

function assessmentModulesIsCompleted(modules: IInspectionModuleState[]): boolean {
    let completed = true;
    modules.forEach(m => {
        if (m.state.status !== InspectionStatuses.Done && m.state.status !== InspectionStatuses.SkippedByUser && m.state.status !== InspectionStatuses.SkippedByCondition) {
            completed = false;
        }
    })
    return completed;
}

const getAutorunPreparedModules = (transactionWithWorkflow: ITransaction, inspectionsData?: IInspectionModuleState[], inspectionsConfig?: IInspectionModuleConfig<IInspectionConfig<IInspectionConfigUI>>[], canAutorunIndex?: number): IInspectionModuleState[] => {
    console.debug("%c" + `LOGIC getAutorunPreparedModules()})`, consoleStyles.logic);
    console.log('states: ', inspectionsData)
    console.log('configs: ', inspectionsConfig)
    console.log('canAutorunIndex: ', canAutorunIndex)
    let newModuleStates: IInspectionModuleState[] = []
    let canRunWithLock = false
    const alreadyHasCanAutorunIndex = inspectionsData?.find(i => i.state.canAutorun)?.index
    const hasNonFreeModules = inspectionsData?.find(i => [InspectionStatuses.Run, InspectionStatuses.Error, InspectionStatuses.Fail].includes(i.state.status)) ? true : false;
    inspectionsData?.forEach(i => {
        const ahcai = alreadyHasCanAutorunIndex !== undefined
        const cainotu = (canAutorunIndex !== undefined && canAutorunIndex !== i.index)
        const newModulesHasCanAutorun = newModuleStates.find(i => i.state.canAutorun) ? true : false;
        const config = inspectionsConfig?.find(ic => ic.index === ic.index)?.config
        if (config && (!config.dependencyInspections || config.dependencyInspections.length === 0) && i.state.status === InspectionStatuses.Lock) {
            canRunWithLock = true;
        }
        newModuleStates.push({
            ...i,
            state: {
                ...i.state,
                canAutorun: (
                    canRunWithLock || alreadyHasCanAutorunIndex !== undefined || newModulesHasCanAutorun || hasNonFreeModules || (canAutorunIndex !== undefined && canAutorunIndex !== i.index)
                )
                    ? alreadyHasCanAutorunIndex === i.index ? true : false
                    : (
                        canAutorunIndex === i.index
                            ? true
                            : checkInspectionCanAutoRun(i.index, transactionWithWorkflow, inspectionsData, inspectionsConfig)
                    )
            }
        })
    });
    return newModuleStates;
}

const checkInspectionCanAutoRun = (index: number, transactionWithWorkflow: ITransaction, inspectionsData?: IInspectionModuleState[], inspectionsConfig?: IInspectionModuleConfig<IInspectionConfig<IInspectionConfigUI>>[]): boolean => {
    const inspectionConfig = inspectionsConfig?.find(i => i.index === index)?.config
    const inspectionData = inspectionsData?.find(i => i.index === index)?.state

    // console.debug("%c" + `LOGIC checkInspectionCanAutoRun(${index})})`, consoleStyles.logic);

    let canAutorun = (inspectionConfig?.autoRun && inspectionData?.status === InspectionStatuses.New) ? true : false;
    if (!canAutorun) return canAutorun;

    if (inspectionConfig?.dependencyInspections) {
        if (inspectionData?.status !== InspectionStatuses.SkippedByUser && inspectionData?.status !== InspectionStatuses.SkippedByCondition) {
            for (const i of inspectionConfig.dependencyInspections) {
                const dependencyModule = inspectionsData?.find(m => m.index === i) as IInspectionModuleState
                if (dependencyModule?.state.status === InspectionStatuses.Done) continue

                if (dependencyModule?.state.status !== InspectionStatuses.SkippedByUser && dependencyModule?.state.status !== InspectionStatuses.SkippedByCondition) {
                    canAutorun = false;
                }
            }
        }
    }

    if (!canAutorun) return canAutorun;

    // Note: we use version of checkoutSkip that is not recalculating grade etc, meaning current module (all finished ones) are taken account when
    // final decicion is made about is module ignored or not.
    // Resulting that checkoutSkip may give false result like module is ignored, but that can happen only if module is already runned.
    // So in this case this approach should be fine.

    const checkoutResult = inspectionConfig?.skip?.map((s) => checkoutSkip(undefined, transactionWithWorkflow, undefined, s)).find(i => i.successed)
    if (checkoutResult && checkoutResult.successed) {
        canAutorun = false;
        return canAutorun;
    }

    return canAutorun;
}

export const getWorkflowStages = (w: IWorkflow): IProcessStageConfig[] | undefined => {
    let stages: IProcessStageConfig[] = []
    if (w.resources) {
        stages.push({ index: stages ? stages?.length : 0, type: ProcessStages.Resources })
    }
    if (w.identification) {
        stages.push({ index: stages ? stages?.length : 0, type: ProcessStages.Identification })
    }
    if (w.preOffer) {
        stages.push({ index: stages ? stages?.length : 0, type: ProcessStages.PreOffer })
    }
    if (w.contentTransfer) {
        stages.push({ index: stages ? stages?.length : 0, type: ProcessStages.ContentTransfer })
    }
    if (w.assessment) {
        stages.push({ index: stages ? stages?.length : 0, type: ProcessStages.Assessment })
    }
    if (w.postOffer) {
        stages.push({ index: stages ? stages?.length : 0, type: ProcessStages.PostOffer })
    }
    if (w.contract) {
        stages.push({ index: stages ? stages?.length : 0, type: ProcessStages.Contract })
    }
    if (w.control) {
        stages.push({ index: stages ? stages?.length : 0, type: ProcessStages.Control })
    }
    if (w.result) {
        stages.push({ index: stages ? stages?.length : 0, type: ProcessStages.Result })
    }
    return stages.length > 0 ? stages : undefined;
}

export const prepareActualVersionWorkflow = (w: IWorkflow): IWorkflow => {
    let workflow = { ...w };

    if (workflow.offer && !workflow.preOffer && !workflow.postOffer && !workflow.commonOffer) {
        let preOffer: IPreOfferConfig = {
            prices: workflow.offer.prices,
            requireConfirmation: workflow.offer.requireConfirmation,
            showPricesBeforeAssessment: workflow.offer.showPricesBeforeAssessment,
            useAnalogs: workflow.offer.useAnalogs,
            useAnalogsChoice: workflow.offer.useAnalogsChoice,
            ui: workflow.offer.ui,
            cancelOptions: workflow.offer.cancelOptions
        }
        let commonOffer: ICommonOfferConfig = {
            allowDiscount: workflow.offer.allowDiscount,
            discount: workflow.offer.discount,
            providers: workflow.offer.providers,
            withoutPrices: workflow.offer.withoutPrices
        }
        workflow.preOffer = preOffer;
        workflow.commonOffer = commonOffer;

        if (workflow.offer.isAfterAssessment) {
            let postOffer: IPostOfferConfig = {
                cancelOptions: workflow.offer.cancelOptions,
                requireConfirmation: workflow.offer.requireConfirmation,
                ui: workflow.offer.ui,
                modules: [],
                hideDependency: false
            }
            workflow.postOffer = postOffer;
        }
        workflow.offer = undefined;
    }

    // BEGIN Convert old case type ('number') Diagnostics module with mode Piceasoft to type {id: number, grade?: string, options?: object}
    let diagnosticsPiceaModules = workflow.assessment?.modules?.filter(i => i.type === Inspections.Diagnostics)?.filter(i => (i.config as IDiagnosticsConfig).mode === DiagnosticsModes.Piceasoft)
    let diagnosticsCaseAsNumber = false;
    if (diagnosticsPiceaModules && diagnosticsPiceaModules.length > 0) {
        diagnosticsCaseAsNumber = diagnosticsPiceaModules.find(i => ((i.config as IDiagnosticsConfig).config as IDiagnosticsPiceaConfig).tests.find(i => i.sets.find(i => i.cases.find(i => typeof i === 'number')))) ? true : false;
    }

    if (diagnosticsCaseAsNumber)
        workflow = {
            ...workflow,
            assessment: {
                ...workflow?.assessment,
                modules: workflow?.assessment?.modules?.map(i => {
                    const isDiagnostics = i.type === Inspections.Diagnostics
                    if (isDiagnostics) {
                        const isDiagnosticsPicea = (i.config as IDiagnosticsConfig)?.mode === DiagnosticsModes.Piceasoft
                        if (isDiagnosticsPicea) {
                            const isCasesAsNumber = ((i.config as IDiagnosticsConfig)?.config as IDiagnosticsPiceaConfig)?.tests.find(t => t.sets.find(s => s.cases.find(i => typeof i === 'number')))
                            if (isCasesAsNumber) {
                                return ({
                                    ...i,
                                    config: {
                                        ...i.config,
                                        config: {
                                            ...(i.config as IDiagnosticsConfig).config,
                                            tests: ((i.config as IDiagnosticsConfig).config as IDiagnosticsPiceaConfig)?.tests.map(t => ({
                                                ...t,
                                                sets: t.sets?.map(s => ({
                                                    ...s,
                                                    cases: s.cases?.map(i => ({ id: i as unknown as number }))
                                                }))
                                            }))
                                        } as IDiagnosticsPiceaConfig
                                    } as IDiagnosticsConfig
                                })

                            }
                        }
                    }
                    return i
                })
            }
        } as IWorkflow
    // END Convert old case type ('number') Diagnostics module with mode Piceasoft to type {id: number, grade?: string, options?: object}


    // BEGIN Convert old check type ('number') Verify module with mode Piceasoft to type {id: number, grade?: string, errors?: }
    let verifyPiceaModules = workflow.assessment?.modules?.filter((module) => module.type === Inspections.Software)
    let verifyCheckAsNumber = false
    if (verifyPiceaModules && verifyPiceaModules.length > 0) {
        verifyCheckAsNumber = verifyPiceaModules.find(module => ((module.config as ISoftwareConfig).config as ISoftwarePiceaConfig)?.checks?.find((check) => typeof check === 'number')) ? true : false
    }
    if (verifyCheckAsNumber) {
        workflow = {
            ...workflow,
            assessment: {
                ...workflow.assessment,
                modules: workflow.assessment?.modules?.map((module) => {
                    const isSoftaware = module.type === Inspections.Software
                    if (isSoftaware) {
                        const isSoftawarePicea = (module.config as ISoftwareConfig)?.mode === SoftwareModes.Piceasoft
                        if (isSoftawarePicea) {
                            const isCheckAsNumber = ((module.config as ISoftwareConfig)?.config as ISoftwarePiceaConfig)?.checks.find((check) => typeof check === 'number') ? true : false
                            if (isCheckAsNumber) {
                                return ({
                                    ...module,
                                    config: {
                                        ...module.config,
                                        config: {
                                            ...(module.config as ISoftwareConfig).config,
                                            checks: ((module.config as ISoftwareConfig).config as ISoftwarePiceaConfig)?.checks.map((check) => ({ id: check as unknown as number }))
                                        } as ISoftwarePiceaConfig

                                    } as ISoftwareConfig
                                })
                            }
                        }
                    }
                    return module
                })
            }
        } as IWorkflow
    }
    // END Convert old check type ('number') Verify module with mode Piceasoft to type {id: number, grade?: string, errors}

    if (!workflow.stages) {
        workflow.stages = getWorkflowStages(workflow);
    }

    return workflow
}

const receiveCurrentStageInspectionState = (newState: IProcessHub, index: number, moduleState: IInspectionState): IProcessHub => {
    if (!newState.current) return newState;

    switch (newState.current.stage) {
        case ProcessStages.Assessment: {
            newState.current.transaction.assessment.modules = newState.current.transaction.assessment.modules.map(m => {
                if (m.index === index) {
                    return {
                        ...m,
                        state: {
                            ...moduleState,
                            gradesCategory: newState.current?.workflow.assessment?.modules.find(i => i.index === index)?.config.gradesCategory
                        },
                        monitoring: {
                            ...m.monitoring,
                            duration: moduleState.duration ?? 0
                        }
                    }
                }
                return m
            });

            newState.current.transaction.assessment = calculateAssesmentGrades({ ...newState.current.transaction, workflow: newState.current.workflow }, newState.current.offers );


            const __selectedPromo = newState?.current?.transaction.selectedPromo
            const __promoProviderState = newState?.current?.promoProviders?.find(i => i.code === __selectedPromo?.provider_code)
            const __offerProviderConfig = newState.current?.workflow.commonOffer?.providers?.find(i => i.code === newState.current?.transaction.selectedProvider)
            const __providerPromo = __promoProviderState?.result?.data?.promotions?.find(i => i.id === __selectedPromo?.promo_id)

            if (__selectedPromo && __providerPromo) {
                newState.current.transaction.isValidPromotion = validatePromotion(
                    { ...__providerPromo, provider_code: __selectedPromo.provider_code },
                    newState.current?.transaction.assessment.grade,
                    newState.current?.transaction.selectedProvider,
                    __offerProviderConfig?.allowPromotions
                );
            }

            newState.current.stageCompleted = assessmentModulesIsCompleted(newState.current.transaction.assessment.modules);

            newState.current.transaction.assessment.modules = getAutorunPreparedModules({ ...newState.current.transaction, workflow: newState.current.workflow }, newState.current?.transaction.assessment.modules, newState.current?.workflow.assessment?.modules);

            return newState;
        }
        case ProcessStages.Control:
            newState.current.transaction.control.modules = newState.current.transaction.control.modules.map(m => {
                if (m.index === index) {
                    return {
                        ...m,
                        state: {
                            ...moduleState,
                            gradesCategory: newState.current?.workflow.control?.modules.find(i => i.index === index)?.config.gradesCategory
                        },
                        monitoring: {
                            ...m.monitoring,
                            duration: moduleState.duration ?? 0
                        }
                    }
                }
                return m
            });

            newState.current.stageCompleted = requiredControlStageModulesIsCompleted(newState.current.transaction.control.modules, newState.current.workflow.control?.modules);

            newState.current.transaction.control.modules = getAutorunPreparedModules({ ...newState.current.transaction, workflow: newState.current.workflow }, newState.current?.transaction.control.modules, newState.current?.workflow.control?.modules);

            return newState;
        case ProcessStages.PostOffer:
            newState.current.transaction.postOffer.modules = newState.current.transaction.postOffer.modules.map(m => {
                if (m.index === index) {
                    return {
                        ...m,
                        state: {
                            ...moduleState,
                            gradesCategory: newState.current?.workflow.postOffer?.modules.find(i => i.index === index)?.config.gradesCategory
                        },
                        monitoring: {
                            ...m.monitoring,
                            duration: moduleState.duration ?? 0
                        }
                    }
                }
                return m
            });

            newState.current.stageCompleted = assessmentModulesIsCompleted(newState.current.transaction.postOffer.modules);

            newState.current.transaction.postOffer.modules = getAutorunPreparedModules({ ...newState.current.transaction, workflow: newState.current.workflow }, newState.current?.transaction.postOffer.modules, newState.current?.workflow.postOffer?.modules);

            return newState;
        default: return newState;
    }
}

const receiveCurrentStageInspectionWarning = (newState: IProcessHub, index: number, warning?: IInspectionWarning): IProcessHub => {
    if (!newState.current) return newState;
    switch (newState.current.stage) {
        case ProcessStages.Assessment:
            newState.current.transaction.assessment.modules = newState.current.transaction.assessment.modules.map(m => {
                if (m.index === index) {
                    return {
                        ...m,
                        state: {
                            ...m.state,
                            warning: warning
                        }
                    }
                }
                return m
            })
            return newState;
        case ProcessStages.Control:
            newState.current.transaction.control.modules = newState.current.transaction.control.modules.map(m => {
                if (m.index === index) {
                    return {
                        ...m,
                        state: {
                            ...m.state,
                            warning: warning
                        }
                    }
                }
                return m
            })
            return newState;
        case ProcessStages.PostOffer:
            newState.current.transaction.postOffer.modules = newState.current.transaction.postOffer.modules.map(m => {
                if (m.index === index) {
                    return {
                        ...m,
                        state: {
                            ...m.state,
                            warning: warning
                        }
                    }
                }
                return m
            })
            return newState;
        default: return newState;
    }
}

const resetCurrentStageInspectionFiles = (newState: IProcessHub, index: number): IProcessHub => {
    if (!newState.current) return newState;
    switch (newState.current.stage) {
        case ProcessStages.Assessment:
            newState.current.transaction.assessment.modules = newState.current.transaction.assessment.modules.map(m => {
                if (m.index === index) {
                    return {
                        ...m,
                        state: {
                            ...m.state,
                            files: undefined
                        } as IPhotographic
                    }
                }
                return m
            })
            return newState;
        case ProcessStages.Control:
            newState.current.transaction.control.modules = newState.current.transaction.control.modules.map(m => {
                if (m.index === index) {
                    return {
                        ...m,
                        state: {
                            ...m.state,
                            files: undefined
                        } as IPhotographic
                    }
                }
                return m
            })
            return newState;
        case ProcessStages.PostOffer:
            newState.current.transaction.postOffer.modules = newState.current.transaction.postOffer.modules.map(m => {
                if (m.index === index) {
                    return {
                        ...m,
                        state: {
                            ...m.state,
                            files: undefined
                        } as IPhotographic
                    }
                }
                return m
            })
            return newState;
        default: return newState;
    }
}

const uploadProgressCurrentStageInspectionFile = (newState: IProcessHub, index: number, uploadProgress: number): IProcessHub => {
    if (!newState.current) return newState;
    switch (newState.current.stage) {
        case ProcessStages.Assessment:
            newState.current.transaction.assessment.modules = newState.current.transaction.assessment.modules.map(m => {
                if (m.index === index) {
                    return {
                        ...m,
                        state: {
                            ...m.state,
                            uploadProgress: uploadProgress
                        } as IPhotographic
                    }
                }
                return m
            })
            return newState;
        case ProcessStages.Control:
            newState.current.transaction.control.modules = newState.current.transaction.control.modules.map(m => {
                if (m.index === index) {
                    return {
                        ...m,
                        state: {
                            ...m.state,
                            uploadProgress: uploadProgress
                        } as IPhotographic
                    }
                }
                return m
            })
            return newState;
        case ProcessStages.PostOffer:
            newState.current.transaction.postOffer.modules = newState.current.transaction.postOffer.modules.map(m => {
                if (m.index === index) {
                    return {
                        ...m,
                        state: {
                            ...m.state,
                            uploadProgress: uploadProgress
                        } as IPhotographic
                    }
                }
                return m
            })
            return newState;
        default: return newState;
    }
}

const receiveCurrentStageInspectionStatus = (newState: IProcessHub, index: number, status: InspectionStatuses, wasAborted?: boolean, canAutorun?: boolean): IProcessHub => {
    if (!newState.current) return newState;

    switch (newState.current.stage) {
        case ProcessStages.Assessment:
            newState.current.transaction.assessment = {
                ...newState.current.transaction.assessment,
                modules: newState.current.transaction.assessment.modules.map(m => {
                    if (m.index === index) {
                        return {
                            ...m,
                            state: {
                                ...m.state,
                                status: status,
                                wasAborted: wasAborted,
                                canAutorun: canAutorun,
                                preSkipStatus: status === InspectionStatuses.SkippedByCondition ?
                                    m.state.status :
                                    undefined
                            }
                        }
                    }
                    return m
                })
            }

            newState.current.transaction.assessment = calculateAssesmentGrades({ ...newState.current.transaction, workflow: newState.current.workflow }, newState.current.offers );

            newState.current.stageCompleted = assessmentModulesIsCompleted(newState.current.transaction.assessment.modules);

            newState.current.transaction.assessment.modules = getAutorunPreparedModules({ ...newState.current.transaction, workflow: newState.current.workflow }, newState.current?.transaction.assessment.modules, newState.current?.workflow.assessment?.modules, canAutorun ? index : undefined);

            return newState;
        case ProcessStages.Control:
            newState.current.transaction.control = {
                ...newState.current.transaction.control,
                modules: newState.current.transaction.control.modules.map(m => {
                    if (m.index === index) {
                        return {
                            ...m,
                            state: {
                                ...m.state,
                                status: status,
                                wasAborted: wasAborted,
                                canAutorun: canAutorun,
                                preSkipStatus: status === InspectionStatuses.SkippedByCondition ?
                                    m.state.status :
                                    undefined
                            }
                        }
                    }
                    return m
                })
            }

            newState.current.stageCompleted = requiredControlStageModulesIsCompleted(newState.current.transaction.control.modules, newState.current.workflow.control?.modules);

            newState.current.transaction.assessment.modules = getAutorunPreparedModules({ ...newState.current.transaction, workflow: newState.current.workflow }, newState.current?.transaction.control.modules, newState.current?.workflow.control?.modules, canAutorun ? index : undefined);

            return newState;

        case ProcessStages.PostOffer:
            newState.current.transaction.postOffer = {
                ...newState.current.transaction.postOffer,
                modules: newState.current.transaction.postOffer.modules.map(m => {
                    if (m.index === index) {
                        return {
                            ...m,
                            state: {
                                ...m.state,
                                status: status,
                                wasAborted: wasAborted,
                                canAutorun: canAutorun,
                                preSkipStatus: status === InspectionStatuses.SkippedByCondition ?
                                    m.state.status :
                                    undefined
                            }
                        }
                    }
                    return m
                })
            }

            newState.current.stageCompleted = assessmentModulesIsCompleted(newState.current.transaction.postOffer.modules);

            newState.current.transaction.postOffer.modules = getAutorunPreparedModules({ ...newState.current.transaction, workflow: newState.current.workflow }, newState.current?.transaction.postOffer.modules, newState.current?.workflow.postOffer?.modules, canAutorun ? index : undefined);

            return newState;
        default: return newState;
    }
}

const requiredControlStageModulesIsCompleted = (states: IInspectionModuleState[], configs?: IControlModuleConfig[]): boolean => {
    if (!configs) return false;

    configs.forEach(c => {
        const state = states.find(s => s.index === c.index)
        if (c.config.required && (!state?.state?.status || ![InspectionStatuses.Done, InspectionStatuses.SkippedByUser, InspectionStatuses.SkippedByCondition].includes(state?.state?.status))) {
            return false;
        }
    })

    return true;
}

const prepareInitializedProcess = (process: IProcess): IProcess => {
    let newProcess = { ...process }
    newProcess.workflow = prepareActualVersionWorkflow(process.workflow);
    const stageType = newProcess.stages.find(i => i.index === newProcess.stageIndex)?.type
    if (typeof stageType === "number") {
        if (stageType === ProcessStages.PreOffer) {
            newProcess.offers = { isFetching: false }
        }
        if ([ProcessStages.PreOffer, ProcessStages.PostOffer].includes(stageType)) {
            const providers = newProcess.workflow.commonOffer?.providers;
            if (providers) {
                newProcess.offerProviders = providers.map(i => ({ code: i.code, type: i.type }))
            }
            const promoProviders = newProcess.workflow.commonOffer?.promoProviders;
            if (promoProviders) {
                newProcess.promoProviders = promoProviders?.map(i => ({ code: i.code, type: i.type })) ?? []
            }
            newProcess.transaction.selectedProvider = undefined
            newProcess.transaction.selectedProviderOrganizationId = undefined
        }
    }

    return newProcess;
}
