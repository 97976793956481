import { ScrollablePane, ScrollbarVisibility, Spinner, Stack } from '@fluentui/react'
import React, * as react from 'react'
import { IStore } from '../../../../core/store'
import { useSelector } from 'react-redux'
import { strings } from '../../../../localization/strings'

type TProps = {
    src: string
    spinnerLabel?: string
}

export const HelpManualMode: React.FC<TProps> = (props) => {

    const [helpString, setHelpString] = react.useState<string>()
    const itn = useSelector((state: IStore) => state.workplace.company?.itn)

    react.useEffect(() => {
        if (props.src && props.src?.length > 0) {
            fetch('v1/help/' + props.src + "/" + itn ?? "common")
                .then(response => response.json() as Promise<{ help: string }>)
                .then(data => setHelpString(data.help))
                .catch(response => console.log(response))
        }
    }, [])

    return (
        <Stack verticalFill style={{ position: "relative" }}>
            {helpString && (
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    <div style={{ display: 'block', verticalAlign: 'center', margin: "0 24px" }} dangerouslySetInnerHTML={{ __html: helpString }} />
                </ScrollablePane>
            ) || (
                <Stack verticalFill verticalAlign="center" horizontalAlign="center" grow>
                    <Spinner label={props.spinnerLabel ?? strings.SPINNERS.PLEASE_WAIT} />
                </Stack>
            )}
        </Stack>
    )
}
