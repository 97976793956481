import { List, SearchBox, Stack, Text, Callout, TextField, ISearchBoxStyles, IDropdownOption, Icon, IDropdownProps, IRenderFunction, ITextFieldProps, getTheme, Dropdown, IComboBoxOption } from '@fluentui/react';
import * as React from 'react';
import s from '../../../assets/css/scrollHide.module.css';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { ReactNode } from 'react';
import { strings } from '../../../localization/strings';
import useDebounce from "../../../core/hooks/useDebounce";
import { isMobile } from 'react-device-detect';

type TProps = {
    hostId?: string
    onRenderSuggestionCell: (item?: any, index?: number) => ReactNode
    onRenderSuggestionsHeader?: () => ReactNode
    onRenderPlaceholder?: () => JSX.Element
    onRenderLabel?: (props: ITextFieldProps | IDropdownProps | undefined) => JSX.Element
    onInputEnterDefaultAction?: () => void
    isSearchEqualTheOneSuggestion: boolean
    searchRequest?: string
    suggestions?: any[]
    inputBoxId: string
    suggestionsListId: string
    inputBoxPlaceholder?: string
    label?: string
    title?: string
    type?: "search" | "text" | "dropdown-search" | "combobox-search"
    selectedKey?: string
    textDeferredValidationTime?: number
    textValidateOnFocusOut?: boolean
    textMinLength?: number
    textMaxLength?: number
    suggestionsListWidth?: number
    required?: boolean
    textOnBlur?: () => void
    textOnGetErrorMessage?: (value: string) => string | JSX.Element | PromiseLike<string | JSX.Element> | undefined
    notFoundBlockRender?: () => ReactNode
    setSearchRequest: (item?: any) => void
    onClickSuggestionCell: (item?: any) => void
    searchBoxStyles?: ISearchBoxStyles
    prepareFilteredItems?: (items: any[], filterValue?: string, selectedKey?: string) => any[]
    onRenderFilterEmptyResult?: () => ReactNode
    listWithTextfieldItem?: boolean
    setFilteredItemsLenght?: (count?: number) => void
    disabled?: boolean
    errorMessage?: string
}

export const focusElement = (prevId: string, nextId: string) => {
    var nextEl = document.getElementById(nextId)
    var prevEl = document.getElementById(prevId)
    // console.log(prevId, " => ", nextId)
    prevEl?.setAttribute("tabIndex", "-1")
    nextEl?.focus({ preventScroll: true })
    nextEl?.scrollIntoView({ block: "center", inline: "nearest" })
    nextEl?.setAttribute("tabIndex", "0")
}

export const handleTextToListKeyPress = (ev: React.KeyboardEvent<HTMLElement>, suggestionsLenght: number, inputId: string, listId: string, onEnter?: (item?: any) => void, index?: number, item?: any, hostId?: string) => {
    // console.log("key: " + ev.key)
    // console.log("suggestionsLenght: ", +suggestionsLenght)
    // console.log("inputId: ", inputId)
    // console.log("listId: ", listId)
    // console.log("index: ", index)
    // console.log("item: ", item)
    // console.log("hostId: ", hostId)
    if (suggestionsLenght > 0) {
        switch (ev.key) {
            case 'ArrowDown':
                ev.preventDefault()
                if (index === undefined) {
                    focusElement(inputId, `${listId}-0`)
                    break
                }
                if (index < suggestionsLenght - 1) {
                    focusElement(`${listId}-${index}`, `${listId}-${index + 1}`)
                    break
                }
                focusElement(`${listId}-${index}`, inputId)
                break
            case 'ArrowUp':
                ev.preventDefault()
                if (index !== undefined && index > 0) {
                    focusElement(`${listId}-${index}`, `${listId}-${index - 1}`)
                    break
                }
                focusElement(`${listId}-${index}`, inputId)
                break
            case 'Enter':
                if (item) {
                    onEnter && onEnter(item)
                    // console.log(hostId)
                    if (hostId) {
                        if (index) {
                            focusElement(`${listId}-${index}`, hostId)
                        } else {
                            focusElement(inputId, hostId)
                        }
                    } else {
                        focusElement(`${listId}-${index}`, 'undefined')
                    }
                }
                break
            case 'Tab':
                if (hostId) {
                    if (!index) {
                        focusElement(inputId, hostId)
                        break;
                    }
                    if (index && index < suggestionsLenght) {
                        focusElement(`${listId}-${index}`, hostId)
                        break;
                    }
                    focusElement(inputId, hostId)
                } else {
                    if (!index) {
                        focusElement(inputId, inputId)
                        break;
                    }
                    if (index && index < suggestionsLenght) {
                        focusElement(`${listId}-${index}`, inputId)
                        break;
                    }
                    focusElement(inputId, inputId)
                }
                break;
            case 'Escape':
                if (hostId) {
                    if (!index) {
                        focusElement(inputId, hostId)
                        break;
                    }
                    if (index && index < suggestionsLenght) {
                        focusElement(`${listId}-${index}`, hostId)
                        break;
                    }
                    focusElement(inputId, hostId)
                } else {
                    if (!index) {
                        focusElement(inputId, inputId)
                        break;
                    }
                    if (index) {
                        focusElement(`${listId}-${index}`, inputId)
                        break;
                    }
                    focusElement(inputId, inputId)
                }
                break;
            default:
                focusElement(`${listId}-${index}`, inputId)
                break
        }
    }
}

const SuggestedSearch: React.FC<TProps> = (props) => {

    const [showCallout, { setTrue: setCalloutOn, setFalse: setCalloutOff }] = useBoolean(false)
    const inputItemClassName = useId('input-item-class-name')
    
    const [filterValue, setFilterValue] = React.useState<string>()
    const [filteredItems, setFilteredItems] = React.useState<IDropdownOption[]>()

    const [localSearchValue, setLocalSearchValue] = React.useState(props.searchRequest)

    const debouncedSearchValue = useDebounce(localSearchValue, props.textDeferredValidationTime ?? 1500)

    const suspendCalloutOff = React.useRef(false);
    const calloutOffFinishedTimeout = React.useRef<NodeJS.Timeout | undefined>(undefined);
    

    React.useEffect(() => {
        if (props.type === "dropdown-search" ||
            props.listWithTextfieldItem &&
            props.type === "combobox-search") {
            if (showCallout) {
                focusElement("", props.inputBoxId)
            } else {
                setFilterValue(undefined)
            }
        }
        
    }, [showCallout])

    React.useEffect(() => {
        props.setFilteredItemsLenght && props.setFilteredItemsLenght(filteredItems?.length)
    }, [filteredItems])

    React.useEffect(() => {
        if (props.type === "dropdown-search" && props.suggestions && props.prepareFilteredItems) {
            setFilteredItems(
                props.prepareFilteredItems(props.suggestions, filterValue, props.selectedKey).map(i => {
                    return ({ ...i, selected: (i.key.toString() === props.selectedKey || i.key === props.selectedKey) ?? i.text === props.searchRequest } as IComboBoxOption)
                })
            )
        }
        if (props.type === "combobox-search" && props.suggestions && props.prepareFilteredItems) {
            const filtredSuggestions: IComboBoxOption[] = props.prepareFilteredItems(props.suggestions, filterValue, props.selectedKey)
            let preparedItems = []
            if (filterValue && props.searchRequest !== filterValue && !filteredItems?.find(i => i.text.toLowerCase() === filterValue.toLowerCase())) {
                preparedItems.push({ key: filterValue, text: filterValue } as IComboBoxOption)
            }
            if (props.searchRequest && !preparedItems.find(i => i.text === props.searchRequest) && !filtredSuggestions.find(i => i.text.toLowerCase() === props.searchRequest?.toLowerCase())) {
                if ((filterValue?.length === 0) || props.searchRequest.substr(0, filterValue?.length) === (filterValue ? filterValue : props.searchRequest)) {
                    preparedItems.push({ key: props.searchRequest, text: props.searchRequest } as IComboBoxOption)
                }
            }
            preparedItems.push(...filtredSuggestions)
            preparedItems = preparedItems.map(i => {
                return ({ ...i, selected: i.key === props.selectedKey ?? i.text === props.searchRequest } as IComboBoxOption)
            })
            setFilteredItems(preparedItems as IComboBoxOption[])
        }
    }, [props.suggestions, filterValue, props.searchRequest])

    React.useEffect(() => {
        if (props.type && ["dropdown-search", "combobox-search"].includes(props.type)) {
            setCalloutOff()
        }
    }, [props.searchRequest])
    
    const handleListVisibility = () => {
        if (!showCallout) {
            setCalloutOn()
        }
    }

    const handleDropdownClick = () => {
        if (!showCallout) {
            setCalloutOn()
        } else {
            setCalloutOff()
        }
    }   

    const searchChangeHandler = (
        ev?: (React.ChangeEvent<HTMLInputElement> | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>  | undefined), 
        value?: (string | undefined)) : void => {

        setLocalSearchValue(value?.trimStart() ?? "");
    }

    const debounceSearchChangeHandler = React.useMemo(
        () => {
            if(props.type == "text"){
                return searchChangeHandler(undefined, props.searchRequest);
            }
        },
        [props.type, props.searchRequest]
    )

    React.useEffect(() => {
        if (debouncedSearchValue)
            props.setSearchRequest(debouncedSearchValue)
						if(debouncedSearchValue === "") props.setSearchRequest("")
    }, [ debouncedSearchValue ])

    const onRenderDropdownPlaceholder = () => (
        <Text style={{ color: props.disabled ? theme.palette.neutralTertiary : props.searchRequest ? theme.palette.neutralDark : theme.palette.neutralSecondary }}>{props.disabled ? props.inputBoxPlaceholder : props.searchRequest ?? props.inputBoxPlaceholder}</Text>
    );

    const onRenderDropdownFilterInputPrefix: IRenderFunction<ITextFieldProps> = (orProps) => {
        return (
            <div onClick={() => focusElement("", props.inputBoxId)}>
                <Icon style={{ WebkitTouchCallout: "none", userSelect: "none", msUserSelect: "none", MozUserSelect: "none", WebkitUserSelect: "none", cursor: "default" }} iconName="Search" />
            </div>
        );
    }

    const onRenderDropdownFilterInputSuffix: IRenderFunction<ITextFieldProps> = (orProps) => {
        return (
            <div>
                {filterValue && filterValue?.length > 0 &&
                    <Icon
                        style={{ WebkitTouchCallout: "none", userSelect: "none", msUserSelect: "none", MozUserSelect: "none", WebkitUserSelect: "none", cursor: "default" }}
                        iconName="Cancel"
                        ariaLabel="Clear filter"
                        onClick={() => setFilterValue("")}
                    />
                }
            </div>
        );
    }

    return (
        <>
            {(!props.type || props.type === "search") && (
                <SearchBox
                    value={ localSearchValue }
                    onSearch={() => undefined}
                    onClick={handleListVisibility}
                    onChange={searchChangeHandler} 
                    onKeyDown={(ev) => {
                        if (ev.key !== 'Tab') {
                            handleListVisibility()
                            handleTextToListKeyPress(ev, props.suggestions?.length ?? 0, props.inputBoxId, props.suggestionsListId, props.onClickSuggestionCell, undefined, undefined, props.hostId)
                        }
                    }}
                    id={props.inputBoxId}
                    placeholder={props.inputBoxPlaceholder ?? strings.SHARED.SEARCH_PLACEHOLDER}
                    onFocus={setCalloutOn}
                    autoComplete="off"
                    className={inputItemClassName}
                    styles={props.searchBoxStyles}
                />
            )}
            {props.type === "text" && (
                <TextField
                    value={ localSearchValue }
                    onChange= { searchChangeHandler }
                    onKeyDown={(ev) => {
                        if (ev.key !== 'Tab') {
                            handleListVisibility()
                            handleTextToListKeyPress(ev, props.suggestions?.length ?? 0, props.inputBoxId, props.suggestionsListId, props.onClickSuggestionCell, undefined, undefined)
                        }
                    }}
                    id={props.inputBoxId}
                    label={props.label}
                    title={props.title}
                    onBlur={props.textOnBlur}
                    onClick={handleListVisibility}
                    onRenderLabel={props.onRenderLabel}
                    placeholder={props.inputBoxPlaceholder ?? strings.SHARED.SEARCH_PLACEHOLDER}
                    required={props.required ?? false}
                    onFocus={setCalloutOn}
                    minLength={props.textMinLength ? props.textMinLength : undefined}
                    maxLength={props.textMaxLength ? props.textMaxLength : undefined}
                    validateOnFocusOut={props.textValidateOnFocusOut ?? false}
                    deferredValidationTime={props.textDeferredValidationTime}
                    // onGetErrorMessage={props.textOnGetErrorMessage}
                    errorMessage={props.errorMessage}
                    autoComplete="off"
                    className={inputItemClassName} />
            )}
            {props.type === "dropdown-search" && (
                <div onClick={handleDropdownClick}>
                    <Dropdown
                        options={[]}
                        label={props.label}
                        title={props.title}
                        onRenderPlaceholder={(p) => props.onRenderPlaceholder !== undefined ? props.onRenderPlaceholder() : onRenderDropdownPlaceholder()}
                        onRenderLabel={props.onRenderLabel}
                        className={inputItemClassName}
                        // onFocus={setCalloutOff}
                        onKeyDown={(ev) => {
                            if (ev.key !== 'Tab') {
                                handleListVisibility()
                                handleTextToListKeyPress(ev, props.suggestions?.length ?? 0, props.inputBoxId, props.suggestionsListId, props.onClickSuggestionCell, undefined, undefined, props.hostId)
                            }
                        }}
                        id={props.hostId}
                        onClick={handleListVisibility}
                        styles={{ dropdown: { pointerEvents: "none" } }}
                        required={props.required ?? false}
                        errorMessage={props.selectedKey ? undefined : props.errorMessage}
                    />
                </div>
            )
            }
            {props.type === "combobox-search" && (
                props.listWithTextfieldItem && (
                    <div onClick={handleDropdownClick}>
                        <Dropdown
                            options={[]}
                            label={props.label}
                            title={props.title}
                            onRenderPlaceholder={(p) => onRenderDropdownPlaceholder()}
                            onRenderLabel={props.onRenderLabel}
                            className={inputItemClassName}
                            // onFocus={setCalloutOff}
                            onKeyDown={(ev) => {
                                if (ev.key !== 'Tab') {
                                    handleListVisibility()
                                    handleTextToListKeyPress(ev, filteredItems?.length ?? 0, props.inputBoxId, props.suggestionsListId, props.onClickSuggestionCell, undefined, undefined, props.hostId)
                                }
                            }}
                            id={props.hostId}
                            onClick={handleListVisibility}
                            styles={{ dropdown: { pointerEvents: "none" } }}
                            required={props.required ?? false}
                            errorMessage={props.errorMessage}
                        />
                    </div>
                ) || (
                    <TextField
                        value={ localSearchValue }
                        onChange={searchChangeHandler}
                        onKeyDown={(ev) => {
                            handleListVisibility()
                            handleTextToListKeyPress(ev,
                                (props.suggestions && (props.suggestions?.length > 1 || showCallout) ? props.suggestions?.length : 0),
                                props.inputBoxId, props.suggestionsListId, props.onClickSuggestionCell, undefined, undefined)
                        }}
                        id={props.inputBoxId}
                        label={props.label}
                        title={props.title}
                        onClick={handleListVisibility}
                        onRenderLabel={props.onRenderLabel}
                        placeholder={props.inputBoxPlaceholder ?? strings.SHARED.SEARCH_PLACEHOLDER}
                        required={props.required ?? false}
                        // onFocus={setCalloutOn}
                        minLength={props.textMinLength}
                        maxLength={props.textMaxLength}
                        validateOnFocusOut={props.textValidateOnFocusOut ?? false}
                        deferredValidationTime={props.textDeferredValidationTime}
                        onGetErrorMessage={props.textOnGetErrorMessage}
                        autoComplete="off"
                        className={inputItemClassName}
                    />
                )
            )
            }
            <Callout target={`.${inputItemClassName}`}
                isBeakVisible={false}
                gapSpace={5}
                onDismiss={(e) => {
                    const eventType = e?.type.toUpperCase();

                    // Handling issue with closing Callout after hiding the keyboard for mobile browsers.
                    if (isMobile && (eventType === 'RESIZE' || eventType === 'SCROLL')) {
                        if (calloutOffFinishedTimeout.current) {
                            clearTimeout(calloutOffFinishedTimeout.current);
                            calloutOffFinishedTimeout.current = undefined;
                        } else {
                            suspendCalloutOff.current = true;

                            setTimeout(() => {
                                suspendCalloutOff.current = false;
                            }, 500);
                        }

                        calloutOffFinishedTimeout.current = setTimeout(() => {
                            calloutOffFinishedTimeout.current = undefined;
                        }, 500);

                        if (suspendCalloutOff.current) {
                            return;
                        }
                    }

                    setCalloutOff()
                }}
                alignTargetEdge>
                {(!props.type || props.type && ["search", "text"].includes(props.type)) && showCallout && props.suggestions && !props.isSearchEqualTheOneSuggestion && ((
                    props.suggestions.length === 0 && ((
                        props.notFoundBlockRender && props.notFoundBlockRender()
                    ) || (
                            <Stack style={{ width: '23em' }}>
                                <Text style={{ paddingLeft: '0.5em', padding: '0.5em' }}>{strings.SHARED.SEARCH_NO_RESULTS}: <b>"{props.searchRequest}"</b></Text>
                            </Stack>)
                    )
                ) || !props.isSearchEqualTheOneSuggestion && (
                    <>
                        {props.onRenderSuggestionsHeader && props.onRenderSuggestionsHeader()}
                        <List style={{ width: props.suggestionsListWidth ?? 320, maxHeight: 300 }}
                            className={s.scrollHide}
                            id={props.suggestionsListId}
                            items={props.suggestions}
                            onRenderCell={(item, index) => props.onRenderSuggestionCell(item, index)}
                        />
                    </>
                ))
                    // ) || (props.onRenderFilterEmptyResult && (
                    //     <div style={{ WebkitTouchCallout: "none", userSelect: "none", msUserSelect: "none", MozUserSelect: "none", WebkitUserSelect: "none", cursor: "default" }} onClick={() => focusElement("", props.inputBoxId)}>
                    //         {props.onRenderFilterEmptyResult() || (
                    //             <Stack verticalAlign="center" horizontalAlign="center" style={{ width: props.suggestionsListWidth ?? 320, height: 50 }}>
                    //                 <Text onFocusCapture={() => undefined} style={{ color: theme.palette.neutralSecondaryAlt }}>{strings.SHARED.SEARCH_FILTER_NO_RESULT}</Text>
                    //             </Stack>
                    //         )}
                    //     </div>
                    // ))
                }
                {props.type === "dropdown-search" && showCallout && (
                    <>
                        <Stack onClick={() => focusElement("", props.inputBoxId)} grow tokens={{ padding: 6 }} style={{ borderBottom: `1px solid ${theme.semanticColors.bodyDivider}` }}>
                            <TextField
                                onRenderSuffix={onRenderDropdownFilterInputSuffix}
                                onRenderPrefix={onRenderDropdownFilterInputPrefix}
                                value={filterValue}
                                onChange={(e, v) => setFilterValue(v?.trimStart())}
                                onKeyDown={(ev) => {
                                    handleListVisibility()
                                    handleTextToListKeyPress(ev, props.suggestions?.length ?? 0, props.inputBoxId, props.suggestionsListId, props.onClickSuggestionCell, undefined, undefined, props.hostId)
                                }}
                                autoFocus={true}
                                id={props.inputBoxId}
                                placeholder={props.inputBoxPlaceholder}
                                autoComplete="off"
                                width={props.suggestionsListWidth ?? 320}
                                styles={{ fieldGroup: { backgroundColor: theme.palette.neutralLighter } }}
                                onBlur={props.textOnBlur}
                            />
                        </Stack>
                        {filteredItems && filteredItems.length > 0 && (
                            <List style={{ width: props.suggestionsListWidth ?? 320, maxHeight: 300, paddingLeft: 1, paddingRight: 0.5, paddingTop: 1 }}
                                className={s.scrollHide}
                                id={props.suggestionsListId}
                                items={filteredItems}
                                onRenderCell={(item, index) => props.onRenderSuggestionCell(item, index)}
                            />
                        ) || (
                                <div style={{ WebkitTouchCallout: "none", userSelect: "none", msUserSelect: "none", MozUserSelect: "none", WebkitUserSelect: "none", cursor: "default" }} onClick={() => focusElement("", props.inputBoxId)}>
                                    {props.onRenderFilterEmptyResult && props.onRenderFilterEmptyResult() || (
                                        <Stack verticalAlign="center" horizontalAlign="center" style={{ width: props.suggestionsListWidth ?? 320, height: 50 }}>
                                            <Text onFocusCapture={() => undefined} style={{ color: theme.palette.neutralSecondaryAlt }}>{strings.SHARED.SEARCH_FILTER_NO_RESULT}</Text>
                                        </Stack>
                                    )}
                                </div>
                            )
                        }
                    </>
                )
                }
                {props.type === "combobox-search" && showCallout && (
                    props.listWithTextfieldItem && (
                        <>
                            <Stack onClick={() => focusElement("", props.inputBoxId)} grow tokens={{ padding: 6 }} style={{ borderBottom: `1px solid ${theme.semanticColors.bodyDivider}` }}>
                                <TextField
                                    onRenderSuffix={onRenderDropdownFilterInputSuffix}
                                    onRenderPrefix={onRenderDropdownFilterInputPrefix}
                                    value={filterValue}
                                    onChange={(e, v) => setFilterValue(v?.trimStart())}
                                    onKeyDown={(ev) => {
                                        handleListVisibility()
                                        console.log(filteredItems?.find(i => i.text.toUpperCase() === filterValue?.toUpperCase())?.text)
                                        handleTextToListKeyPress(ev, props.suggestions?.length ?? 0, props.inputBoxId, props.suggestionsListId, props.onClickSuggestionCell, undefined, filteredItems?.find(i => i.text.toUpperCase() === filterValue?.toUpperCase())?.text ?? filterValue, props.hostId)
                                    }}
                                    autoFocus={true}
                                    id={props.inputBoxId}
                                    placeholder={props.inputBoxPlaceholder}
                                    autoComplete="off"
                                    // width={props.suggestionsListWidth ?? 320}
                                    styles={{ fieldGroup: { backgroundColor: theme.palette.neutralLighter } }}
                                />
                            </Stack>
                            {filteredItems && (
                                (
                                    filteredItems.length === 0 && (
                                        (
                                            props.notFoundBlockRender && props.notFoundBlockRender()
                                        ) || (
                                            props.onRenderFilterEmptyResult && props.onRenderFilterEmptyResult()
                                            || (
                                                <>
                                                </>
                                                // <Stack style={{ width: '23em' }}>
                                                //     <Text style={{ paddingLeft: '0.5em', padding: '0.5em' }}>{strings.SHARED.SEARCH_NO_RESULTS}: <b>"{props.searchRequest}"</b></Text>
                                                // </Stack>
                                            )
                                        )
                                    )
                                ) || (filteredItems.length > 2 && (
                                    <>
                                        {props.onRenderSuggestionsHeader && props.onRenderSuggestionsHeader()}
                                        <List style={{ width: props.suggestionsListWidth ?? 320, maxHeight: 300 }}
                                            className={s.scrollHide}
                                            id={props.suggestionsListId}
                                            items={props.listWithTextfieldItem ? filteredItems : props.suggestions}
                                            onRenderCell={(item, index) => props.onRenderSuggestionCell(item, index)}
                                        />
                                    </>
                                ) || (
                                        (
                                            <List style={{ width: props.suggestionsListWidth ?? 320, maxHeight: 300 }}
                                                className={s.scrollHide}
                                                id={props.suggestionsListId}
                                                items={props.listWithTextfieldItem ? filteredItems : props.suggestions}
                                                onRenderCell={(item, index) => props.onRenderSuggestionCell(item, index)}
                                            />
                                        )
                                    )
                                )
                            )
                            }
                        </>
                    ) || (
                        props.suggestions && (
                            (
                                props.suggestions.length === 0 && (
                                    (
                                        props.notFoundBlockRender && props.notFoundBlockRender()
                                    ) || (
                                        props.onRenderFilterEmptyResult && props.onRenderFilterEmptyResult()
                                        || (
                                            <>
                                            </>
                                            // <Stack style={{ width: '23em' }}>
                                            //     <Text style={{ paddingLeft: '0.5em', padding: '0.5em' }}>{strings.SHARED.SEARCH_NO_RESULTS}: <b>"{props.searchRequest}"</b></Text>
                                            // </Stack>
                                        )
                                    )
                                )
                            ) || (props.suggestions.length > 1 && (
                                <>
                                    {props.onRenderSuggestionsHeader && props.onRenderSuggestionsHeader()}
                                    <List style={{ width: props.suggestionsListWidth ?? 320, maxHeight: 300 }}
                                        className={s.scrollHide}
                                        id={props.suggestionsListId}
                                        items={props.listWithTextfieldItem ? filteredItems : props.suggestions}
                                        onRenderCell={(item, index) => props.onRenderSuggestionCell(item, index)}
                                    />
                                </>
                            ) || (
                                    (
                                        props.suggestions[0] as IComboBoxOption).text.substr(0, props.searchRequest?.length) !== props.searchRequest && (
                                        props.onRenderFilterEmptyResult && props.onRenderFilterEmptyResult()
                                    ) || (
                                        (props.suggestions[0] as IComboBoxOption).text !== props.searchRequest) && (
                                        <List style={{ width: props.suggestionsListWidth ?? 320, maxHeight: 300 }}
                                            className={s.scrollHide}
                                            id={props.suggestionsListId}
                                            items={props.listWithTextfieldItem ? filteredItems : props.suggestions}
                                            onRenderCell={(item, index) => props.onRenderSuggestionCell(item, index)}
                                        />
                                    )
                                )
                            )
                        )
                    )
                )
                }
            </Callout>
        </>
    );
}

export default SuggestedSearch

const theme = getTheme();