import { ILogin } from "@piceasoft/core";
import { getApiResponse, IApiResponse } from "./api";
import { endpoints } from "./endpoints";

export const accountApi = {
    async signIn(login: ILogin): Promise<IApiResponse<{authorize: boolean}>> {
        return await getApiResponse(endpoints.v1.requestSignIn, {
            method: "POST", data: login, headers: { "Content-Type": "application/json" }
        });
    },
    async signOut(): Promise<IApiResponse<undefined>> {
        return await getApiResponse(endpoints.v1.requestSignOut, { method: "POST" });
    }
}


