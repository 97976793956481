import { consoleStyles } from "../scripts/console";
import { IDevice, ITransactionDevice } from "@piceasoft/core";

export const mobileIdentificationGuard = (device: IDevice & {
  storage: number;
}, identity?: ITransactionDevice): boolean => {
    console.debug("%c" + "LOGIC mobileIdentificationGuard() - checking that new device is equal to transaction original device", consoleStyles.logic);
    console.log({device, identity})
    // Если устройство транзакции отсутствует, то разрешаем идентификацию устройства
    // If transaction device is missing, then allow identification
    if (!identity) return true;

    // Если устройство транзакции совподает с подключаемым устройством по первичным данным
    // If transaction device primary data is equal to new device
    if (compare(identity.device, device)) return true;

    // Запрещаем идентификацию устройства
    // Identification denied
    return false;
}

const compare = (a: IDevice, b: any & {
  storage: number;
}): boolean => {
	if(a.manufacturer && b.manufacturer && a.manufacturer.toLowerCase() !== b.manufacturer.toLowerCase()) return false;
	// if(a.name && b.name && a.name !== b.name) return false;
	if(a.attributes?.capacity && b?.storage && a.attributes?.capacity.toString().toLowerCase() !== b?.storage.toString().replace('.0', '').toLowerCase()) return false;
	// if(a.attributes.imei && b.imei && a.attributes.imei !== b.imei) return false;
  
	// if(a.attributes.imei2 && b.imei2 && a.attributes.imei2 !== b.imei2) return false;
	// if(a.attributes.sn && b.serial && a.attributes.sn !== b.serial) return false;

  return true;
}