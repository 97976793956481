import {
    DirectionalHint, getFocusStyle, getId, getTheme, ISearchBoxStyles,
    ITheme, mergeStyleSets, Panel, PanelType, PrimaryButton, Separator,
    SharedColors, Spinner, Stack, Text
} from "@fluentui/react"
import { useBoolean } from "@uifabric/react-hooks"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { actionCreators } from "../../../core/actions/teaching-actions"
import { api } from "../../../core/api/api"
import { IStore } from "../../../core/store"
import { IOffer, IGrade } from "@piceasoft/core"
import { strings } from "../../../localization/strings"
import SuggestedSearch, { handleTextToListKeyPress } from "../../shared/search/SuggestedSearch"
import { StartTeachingItem } from "../../shared/startTeaching/StartTeachingItem"
import { ServicePricesBox } from "./MainSearch/ServicePricesBox"

type TProps = {
}

type DeviceSearchItem = {
    id: number
    manufacturer: string
    name: string
    configuration: string
    type?: number
    group?: string
}

type TServiceOffers = { id: number, name: string, description?: string, imgSrc?: string, offers?: IOffer[], grades?: IGrade[] }

export const MainSearch: React.FC<TProps> = (props) => {
    const [searchRequest, setSearchRequest] = useState<string>()
    const [searchResults, setSearchResults] = useState<DeviceSearchItem[]>()
    const [selectedDevice, setSelectedDevice] = useState<DeviceSearchItem>()

    const generateIcon = useSelector((s: IStore) => s.workplace.organization?.workflowIconGeneration)
    const stateServicesOffers = useSelector((s: IStore) => s.workplace.services.items?.map(i => ({ id: i.id, name: i.name, description: i.description, imgSrc: i.imageSrc, })))
    const [servicesOffers, setServicesOffers] = useState<TServiceOffers[]>()
    const [fetchedOffers, setFetchedOffers] = useState<TServiceOffers>()
    const [isPanelOpen, { setTrue: showPanel, setFalse: hidePanel }] = useBoolean(false)

    const teachingState = useSelector((s: IStore) => s.teaching)
    const teachingItemName = "quick_price"
    const dispatch = useDispatch();

    React.useEffect(() => {
        setTimeout(() => {
            dispatch(actionCreators.addItemId(teachingItemName, mainSearchBoxId))
        }, 500)
    }, [])

    const mainCalloutListId = getId('main-search-list-id')
    const mainSearchBoxId = 'main-searchbox-id'
    const mainSearchId = 'main-search-id'
    const searchMaxWidth = 320

    const mainSearchStyles: Partial<ISearchBoxStyles> = {
        root: {
            width: searchRequest ? searchMaxWidth : 250,
            transition: '0.75s',
            selectors: {
                '&:focus-within': { width: searchMaxWidth },
            }
        }
    }

    useEffect(() => {
        if (fetchedOffers) {
            setServicesOffers(servicesOffers ? [...servicesOffers.filter(so => so.id !== fetchedOffers.id), fetchedOffers] : [fetchedOffers])
        }
    }, [fetchedOffers])

    useEffect(() => {
        if (selectedDevice) {
            showPanel()
            stateServicesOffers?.forEach(async service => {
                let result: TServiceOffers = { id: service.id, name: service.name, description: service.description, imgSrc: service.imgSrc }
                const offersResult = await api.v1.offer.getServiceOffer(
                    service.id,
                    selectedDevice.manufacturer,
                    selectedDevice.name,
                    selectedDevice.configuration,
                    selectedDevice.group?.toString()
                )
                if (offersResult.data) {
                    result.offers = offersResult.data
                    const workflow = await api.v1.environment.getServiceWorkflow(service.id)
                    if (workflow) {
                        result.grades = workflow.grades
                    }
                }

                setFetchedOffers(result)
            })
        }
    }, [selectedDevice])

    useEffect(() => {
        getSearchResult(searchRequest)
    }, [searchRequest])

    const getSearchResult = async (request?: string) => {
        if (request) {
            if (request.length > 0) {
                const result = await api.v1.identification.global(request)
                if (result.successed && result.data && result.data.length > 0) {
                    setSearchResults(result.data as DeviceSearchItem[])
                } else {
                    setSearchResults([])
                }
            }
            if (request.length === 0) {
                setSearchResults([])
            }
        } else {
            setSearchResults(undefined)
        }
    }

    const handleClick = (item?: DeviceSearchItem) => {
        setSelectedDevice(item)

    }

    const searchRequestHandler = async (value?: string) => {
        if (value && value.length > 0)
            setSearchRequest(value)
        if (!value || value.length === 0)
            setSearchRequest('')
    }

    const onRenderCell = (item?: DeviceSearchItem, index?: number, isScrolling?: boolean): JSX.Element => {
        return (
            <div className={classNames.itemCell}
                data-is-focusable={true}
                id={`${mainCalloutListId}-${index as number}`}
                onKeyDown={(ev: React.KeyboardEvent<HTMLElement>) => handleTextToListKeyPress(ev, searchResults?.length ?? 0, mainSearchBoxId, mainCalloutListId, handleClick, index, item)}
                tabIndex={-1}
                onClick={() => handleClick(item)}>
                <Stack grow tokens={{ padding: 4, childrenGap: 4 }}>
                    <Stack horizontal horizontalAlign="space-between" grow tokens={{ childrenGap: 4 }}>
                        <Text style={{ color: SharedColors.cyanBlue10 }}>{item?.manufacturer}</Text>
                        {item?.configuration && item?.configuration !== "0" && (
                            <Text variant="small" style={{ color: SharedColors.gray30 }}>
                                {strings.ATTRIBUTES.DEVICE.CONFIGURATION}: <Text variant="medium">{item?.configuration}</Text>
                            </Text>
                        )}
                    </Stack>
                    <Stack.Item style={{ maxWidth: searchMaxWidth }}>
                        <Text block nowrap variant="mediumPlus" style={{ color: SharedColors.gray40 }}>{item?.name}</Text>
                    </Stack.Item>
                </Stack>
            </div >
        );
    };

    const onRenderPanelFooter = () => {
        return (
            <Stack verticalAlign="center" horizontal horizontalAlign="end" tokens={{ childrenGap: 8, padding: 16 }}>
                <PrimaryButton onClick={dismissPanel}>{strings.BUTTONS.TEXT.OK}</PrimaryButton>
            </Stack>
        )
    }

    const onRenderPanelBody = () => {
        let count = 0
        servicesOffers?.filter(i => i.offers && i.offers.length > 0).map(so => { if (so.offers) count = + 1 })
        return (
            <Stack verticalFill style={{ overflow: 'auto' }} grow tokens={{ padding: 16, childrenGap: 16 }}>
                {servicesOffers && servicesOffers.length === stateServicesOffers?.length && (
                    count > 0 && (
                        servicesOffers?.filter(i => i.offers && i.offers.length > 0)?.map(so => so.offers && so.grades && (
                            <ServicePricesBox key={so.id} generate={generateIcon} serviceName={so.name} description={so.description} imgSrc={so.imgSrc} serviceId={so.id} offers={so.offers} grades={so.grades} />
                        ))
                    ) || (
                        <Stack verticalFill verticalAlign="center" horizontalAlign="center">
                            <Text>{strings.HEADER.MAIN_SEARCH.NO_OFFERS}</Text>
                        </Stack>
                    )) || (
                        <Stack verticalFill verticalAlign="center" horizontalAlign="center">
                            <Spinner label={strings.SPINNERS.DATA_IS_LOADING_PLEASE_WAIT} />
                        </Stack>
                    )}
            </Stack>
        )
    }

    const onRenderPanelHeader = () => {
        return (
            <Stack verticalFill style={{ paddingTop: 0, paddingLeft: 16, paddingRight: 0 }} grow tokens={{ childrenGap: 8 }}>
                <Stack.Item>
                    <Text variant="large">
                        {strings.HEADER.MAIN_SEARCH.TEXT_OFFERS_FOR} <Text variant="large" style={{ color: theme.palette.themePrimary }}>
                            {`${selectedDevice?.manufacturer} ${selectedDevice?.name} ${selectedDevice?.configuration}`}
                        </Text>
                    </Text>
                </Stack.Item>
                <Separator />
            </Stack>
        )
    }

    const dismissPanel = () => {
        hidePanel()
        setTimeout(() => {
            setSelectedDevice(undefined)
            setServicesOffers(undefined)
        }, 1000)
    }

    return (
        <div id={mainSearchId}>
            <SuggestedSearch
                inputBoxPlaceholder={strings.HEADER.PRICE_LOOKUP.PLACEHOLDER}
                onClickSuggestionCell={handleClick}
                inputBoxId={mainSearchBoxId}
                onRenderSuggestionCell={onRenderCell}
                suggestionsListId={mainCalloutListId}
                searchRequest={searchRequest}
                suggestions={searchResults}
                setSearchRequest={searchRequestHandler}
                isSearchEqualTheOneSuggestion={false}
                searchBoxStyles={mainSearchStyles}
                suggestionsListWidth={searchMaxWidth}
            />
            {teachingState.currentItem === teachingItemName && (
                <StartTeachingItem
                    name={teachingItemName}
                    header={strings.START_TEACHING.QUICK_PRICE.HEADER}
                    text={strings.START_TEACHING.QUICK_PRICE.TEXT}
                    target={mainSearchId}
                    direction={DirectionalHint.bottomLeftEdge}
                />
            )}
            <div>
                <Panel
                    isOpen={isPanelOpen}
                    onDismiss={dismissPanel}
                    type={PanelType.largeFixed}
                    closeButtonAriaLabel="Close"
                    onRenderHeader={onRenderPanelHeader}
                    onRenderFooter={onRenderPanelFooter}
                    onRenderBody={onRenderPanelBody}
                    isFooterAtBottom={true}
                    styles={{
                        scrollableContent: {
                            height: '100%',
                            overflow: 'hidden',
                            display: "flex",
                            flexGrow: 1
                        },
                        content: {
                            heigth: "100%",
                            display: "flex",
                            flexGrow: 1,
                            paddingLeft: 0,
                            paddingRight: 0

                        },
                    }}
                />
            </div>
        </div>
    )
}

const theme: ITheme = getTheme();

const classNames = mergeStyleSets({
    itemCell: [
        getFocusStyle(theme, { inset: -1, outlineColor: theme.palette.neutralLight, borderColor: theme.palette.neutralLight }),
        {
            padding: 5,
            boxSizing: 'border-box',
            borderBottom: `1px solid ${theme.semanticColors.bodyDivider}`,
            display: 'flex',
            selectors: {
                '&:hover': { background: theme.palette.neutralLight },
                '&:focus': { background: theme.palette.neutralLight },
            },
        },
    ],
});