import { AppThunkAction } from "..";
import { strings } from "../../localization/strings";
import { OnlineLanguage, strings as coreStrings, getOnlineLanguage } from "@piceasoft/core";
import { stringsLogin } from "../../screens/login/index.strings";
import { api } from "../api/api";
import { consoleStyles } from "../scripts/console";
import { initializePicea, initializePiceaCallbacks } from "../scripts/picea";
import { errorDescriber } from "../scripts/picea.errors";
import { PiceaConnectionTypes, PiceaError } from "../scripts/picea.typings";
import { ILogin } from "@piceasoft/core";
import { PiceaStatuses, PiceaConnectionStatuses, IEnvironment, IPiceaError } from '@piceasoft/core';
import { getEnumName } from "../helpers/common";
import { SwitchLocalStorageState } from "../store/typings/contentTransferTypes";
import { IStore } from "../store/typings/IStore";
import { generateUUID } from "@piceasoft/core";

export interface InitializationRequestAction { type: 'ENVIRONMENT_INITIALIZATION_REQUEST' }
export interface InitializationIsReadyAction { type: 'ENVIRONMENT_INITIALIZATION_IS_READY', configuration: IEnvironment }
export interface InitializationReceiveJsApiLinkAction { type: 'ENVIRONMENT_RECEIVE_JSAPILINK', script: string}

export interface ReceivePiceaStatusAction { type: 'ENVIRONMENT_PICEA_RECEIVE_STATUS', status: PiceaStatuses }
export interface ReceivePiceaConnectionIdAction { type: 'ENVIRONMENT_PICEA_RECEIVE_CONNECTION_ID', connection_id: string }
export interface ReceivePiceaGlobalErrorAction { type: 'ENVIRONMENT_PICEA_RECEIVE_GLOBAL_ERROR', error: IPiceaError }
export interface ReceivePiceaQRCodeAction { type: 'ENVIRONMENT_PICEA_RECEIVE_QR_CODE', link: string }
export interface ReceivePiceaLinkAction { type: 'ENVIRONMENT_PICEA_RECEIVE_LINK', link: string }
export interface ReceivePiceaInstallerAction { type: 'ENVIRONMENT_PICEA_RECEIVE_INSTALLER', link: string }
export interface ChangePiceaOtfConnectionAction { type: 'ENVIRONMENT_PICEA_CHANGE_OTF_CONNNECTION', status: PiceaConnectionStatuses, error?: PiceaError }
export interface ChangePiceaUsbConnectionAction { type: 'ENVIRONMENT_PICEA_CHANGE_USB_CONNNECTION', status: PiceaConnectionStatuses, error?: PiceaError }
export interface ChangePiceaNcdConnectionAction { type: 'ENVIRONMENT_PICEA_CHANGE_NCD_CONNNECTION', status: PiceaConnectionStatuses, error?: PiceaError }
export interface SetPiceaUsbConnectorVersionAction { type: 'ENVIRONMENT_PICEA_SET_USB_CONNECTOR_VERSION', version?: string}


export interface PiceasoftIsLoadingAction { type: 'ENVIRONMENT_PICEASOFT_IS_LOADING' }
export interface PiceasoftIsLoadedAction { type: 'ENVIRONMENT_PICEASOFT_IS_LOADED' }


export type KnownAction =
    InitializationRequestAction |
    InitializationIsReadyAction |
    InitializationReceiveJsApiLinkAction |
    ReceivePiceaStatusAction |
    ReceivePiceaConnectionIdAction |
    ReceivePiceaGlobalErrorAction |
    ReceivePiceaQRCodeAction |
    ReceivePiceaLinkAction |
    ReceivePiceaInstallerAction |
    ChangePiceaOtfConnectionAction |
    ChangePiceaUsbConnectionAction | 
    ChangePiceaNcdConnectionAction |
    SetPiceaUsbConnectorVersionAction


export const actionCreators = {
    requestSignIn: (login: ILogin, callback: (message: string) => void): AppThunkAction<KnownAction | AppThunkAction<KnownAction>> => async (dispatch, getState) => {
        console.debug("%c" + "LOGIC requestSignIn()", consoleStyles.logic);
        const apiResponse = await api.v1.account.signIn(login);
        if (apiResponse.successed && apiResponse.data?.authorize) {
            window.location.href = "/";
        } else {
            callback(stringsLogin.LOGIN_FAILED);
        }
    },

    requestSignOut: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        console.debug("%c" + "LOGIC requestSignOut()", consoleStyles.logic);
        const apiResponse = await api.v1.account.signOut();
        if (apiResponse.successed) {
            window.location.href = "/";
        }
    },

    initializationRequest: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        console.debug("%c" + "LOGIC initializationRequest()", consoleStyles.logic);
        const appState = getState();
        if (appState && appState.environment && appState.environment.isReady === false) {
            dispatch({ type: 'ENVIRONMENT_INITIALIZATION_REQUEST' });
            initializePicea();

            const apiResponse = await api.v1.environment.getEnvironment();
            
            if (apiResponse.successed && apiResponse.data) {
                const lang = getOnlineLanguage(apiResponse.data.lang)
                strings.setLanguage(lang)
                coreStrings.setLanguage(lang)
                
                // Listen onConnected, onDisconnected and update connection state
                window.PICEA.onConnected((args) => {
                    console.log("env: window.PICEA.onConnected, args:");
                    console.log(args);
                    if (args.error) {
                        dispatch(actionCreators.changePiceaConnection(args.connectionType, PiceaConnectionStatuses.Failed, args.error));
                    } else if (args.data?.status && args.data.status === window.ONLINE_API.STATUS_FAILED) {
                        dispatch(actionCreators.changePiceaConnection(args.connectionType, PiceaConnectionStatuses.Failed,
                            { status: args.data.status as number, message: strings.FOOTER.PICEA_CONNECTIONS.MESSAGE_FAIL }));
                    } else {
                        dispatch(actionCreators.changePiceaConnection(args.connectionType, PiceaConnectionStatuses.Connected));
                    }
                });
    
                window.PICEA.onDisconnected((args) => {
                    console.log("env: window.PICEA.onDisconnected, args:");
                    console.log(args);
                    if (args.error) {
                        dispatch(actionCreators.changePiceaConnection(args.connectionType, PiceaConnectionStatuses.Failed, args.error));
                    } else {
                        dispatch(actionCreators.changePiceaConnection(args.connectionType, PiceaConnectionStatuses.Disconnected));
                    }
                });

                dispatch({ type: 'ENVIRONMENT_INITIALIZATION_IS_READY', configuration: apiResponse.data });
            }
        }
    },

    languageReceive: (language: OnlineLanguage): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.debug("%c" + "LOGIC languageReceive()", consoleStyles.logic);
        const appState = getState();
        if (appState.environment) {
            dispatch({ type: 'ENVIRONMENT_INITIALIZATION_IS_READY', configuration: { ...appState.environment, lang: language } });
        }
    },

    /** Initializes a download request of Piceasoft Online API (jsApi) */
    requestPiceasoft: (callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState.environment.picea && appState.environment.picea.status === PiceaStatuses.Missing) {

            if (window.ONLINE_API) {
                console.log("ONLINE_API was already loaded!");

                // do all (re)initializations
                initializePiceaCallbacks((f: any) => { dispatch(f); });
                dispatch({ type: 'ENVIRONMENT_PICEA_RECEIVE_STATUS', status: PiceaStatuses.Available });
                dispatch({ type: 'ENVIRONMENT_PICEA_RECEIVE_QR_CODE', link: window.ONLINE_API.QRCode });
                dispatch({ type: 'ENVIRONMENT_PICEA_RECEIVE_LINK', link: window.ONLINE_API.link });
                dispatch({ type: 'ENVIRONMENT_PICEA_RECEIVE_INSTALLER', link: window.ONLINE_API.usb.installerUrl() });
                if (callback) {
                    callback();
                }
                return;
            }

            console.log("requestPiceasoft: loading jsApi");

            const script = document.createElement("script");

            let connection_id: string|undefined = undefined;

            // If there was stored contentTransfer session, we need to use same connection_id for jsApi.
            // This ensures that room_id and online connector connection_id remains constant even on browser refresh.

            const contentTransferStateStr = localStorage.getItem('contentTransfer');
            if (contentTransferStateStr) {
                let state : SwitchLocalStorageState | undefined = JSON.parse(contentTransferStateStr);
                if (state && state.connection_id) {
                    connection_id = state.connection_id;
                    console.log("Using stored connection_id: " + connection_id);
                }
            }

            if (!connection_id) {
                // else use random connection_id
                connection_id = generateUUID();
            }

            if (appState.environment.picea.script.includes("?")) {
                // some params already exists, append with '&'
                script.src = appState.environment.picea.script + '&connection_id=' + connection_id;
            } 
            else {
                // no params, start new
                script.src = appState.environment.picea.script + '?connection_id=' + connection_id;
            }

            
            script.async = true;

            let isFinished = false;
            

            script.onload = () => {
                console.log("requestPiceasoft: jsApi onload done");
                isFinished = true;
                if (typeof window.ONLINE_API === undefined) {
                    dispatch({
                        type: 'ENVIRONMENT_PICEA_RECEIVE_GLOBAL_ERROR', error: {
                            status: 0, message: strings.ACTIONS_MESSAGES.ENVIRONMENT.API_NOT_LOADED
                        }
                    });
                } else if (window.ONLINE_API.error) {
                    dispatch({
                        type: 'ENVIRONMENT_PICEA_RECEIVE_GLOBAL_ERROR', error: {
                            status: window.ONLINE_API.error.status ?? -1, message: window.ONLINE_API.error.message ?? "Unknown error"
                        }
                    });
                } else {
                    initializePiceaCallbacks((f: any) => { dispatch(f); });
                    // connection_id is always defined, check is there just to remove warnings
                    if (connection_id) {
                        dispatch({ type: 'ENVIRONMENT_PICEA_RECEIVE_CONNECTION_ID', connection_id: connection_id });
                    }
                    dispatch({ type: 'ENVIRONMENT_PICEA_RECEIVE_STATUS', status: PiceaStatuses.Available });
                    dispatch({ type: 'ENVIRONMENT_PICEA_RECEIVE_QR_CODE', link: window.ONLINE_API.QRCode });
                    dispatch({ type: 'ENVIRONMENT_PICEA_RECEIVE_LINK', link: window.ONLINE_API.link });
                    dispatch({ type: 'ENVIRONMENT_PICEA_RECEIVE_INSTALLER', link: window.ONLINE_API.usb.installerUrl() });
                    if (callback) {
                        callback();
                    }
                }
            }

            script.onerror = () => {
                console.warn("requestPiceasoft: jsApi load failed!");
                isFinished = true;
                dispatch({ type: 'ENVIRONMENT_PICEA_RECEIVE_GLOBAL_ERROR', error: { status: 0, message: strings.ACTIONS_MESSAGES.ENVIRONMENT.API_LOADING_PROBLEM } });
            }

            dispatch({ type: 'ENVIRONMENT_PICEA_RECEIVE_STATUS', status: PiceaStatuses.Loading });

            document.body.appendChild(script);

            setTimeout(() => {
                if (!isFinished) {
                    console.warn("requestPiceasoft: jsApi load timeout!");
                    dispatch({ type: 'ENVIRONMENT_PICEA_RECEIVE_GLOBAL_ERROR', error: { status: 0, message: strings.ACTIONS_MESSAGES.ENVIRONMENT.API_LOADING_PROBLEM } });
                    script.remove();
                }
            }, 10000);
        }
    },

    changePiceaConnection: (connectionType: PiceaConnectionTypes, status: PiceaConnectionStatuses, error?: PiceaError): ChangePiceaOtfConnectionAction | ChangePiceaUsbConnectionAction | ChangePiceaNcdConnectionAction => {
        switch (connectionType) {
            case PiceaConnectionTypes.OTF: return { type: 'ENVIRONMENT_PICEA_CHANGE_OTF_CONNNECTION', status: status, error: error };
            case PiceaConnectionTypes.USB: return { type: 'ENVIRONMENT_PICEA_CHANGE_USB_CONNNECTION', status: status, error: error };
            case PiceaConnectionTypes.NCD: return { type: 'ENVIRONMENT_PICEA_CHANGE_NCD_CONNNECTION', status: status, error: error };
        }
    },

    getConnectionStatus(appState: IStore, connectionType: PiceaConnectionTypes) : PiceaConnectionStatuses | undefined {
        switch (connectionType) {
            case PiceaConnectionTypes.OTF: return appState.environment.picea?.connections.otf.status;
            case PiceaConnectionTypes.USB: return appState.environment.picea?.connections.usb.status;
            case PiceaConnectionTypes.NCD: return appState.environment.picea?.connections.ncd.status;
            default: return undefined;
        }
    },

    setPiceaConnectorVersion: (version?: string): SetPiceaUsbConnectorVersionAction => {
        return { type: 'ENVIRONMENT_PICEA_SET_USB_CONNECTOR_VERSION', version: version };
    },

    requestPiceaConnection: (connectionType: PiceaConnectionTypes): AppThunkAction<KnownAction | AppThunkAction<KnownAction | any>> => (dispatch, getState) => {
        const appState = getState();
        if (appState.environment.picea?.status !== PiceaStatuses.Available) {
            console.log("requestPiceaConnection: picea not available, state: " + appState.environment.picea?.status)
            dispatch(actionCreators.requestPiceasoft(() => dispatch(actionCreators.requestPiceaConnection(connectionType))));
        }
        
        // on usb case we cannot really trust the state of ONLINE_API.isConnected
        // https://piceasoft.atlassian.net/browse/PICEA-6091
        const isReallySureConnected = window.ONLINE_API.isConnected(connectionType) && connectionType !== PiceaConnectionTypes.USB;

        if (appState.environment.picea?.status === PiceaStatuses.Available && isReallySureConnected) {
            console.info("requestPiceaConnection: oops, already connected! Mark connection ready");
            dispatch(actionCreators.changePiceaConnection(connectionType, PiceaConnectionStatuses.Connected));
            return;
        }

        if (appState.environment.picea?.status === PiceaStatuses.Available && !isReallySureConnected) {

            if (actionCreators.getConnectionStatus(appState, connectionType) === PiceaConnectionStatuses.Connecting) {
                console.info("requestPiceaConnection: already connecting.. skipping");
                return;
            }
            console.info("requestPiceaConnection: trying connecting, connectionType: " + connectionType);
            dispatch(actionCreators.changePiceaConnection(connectionType, PiceaConnectionStatuses.Connecting));

            setTimeout(() => {

                console.debug("%c" + "Picea™ Online Services: RUN connect (" + connectionType + ")", consoleStyles.piceaCollback);
                window.ONLINE_API.connect(connectionType, (error: PiceaError, type: any, data: any) => {
                    
                    console.debug("%c" + "Picea™ Online Services: connect (" + getEnumName(PiceaConnectionTypes, connectionType.toString()) + ")", consoleStyles.piceaCollback);
                    console.info({ error, type, data });
                    let version = data && data.version ? data.version as string : undefined;
                    
                    if (error) {
                        dispatch(actionCreators.changePiceaConnection(connectionType, PiceaConnectionStatuses.Failed,
                            { status: error.details?.error as number, message: error.details?.error ? errorDescriber(error.details?.error) : strings.PICEA.UNKNOWN_ERROR_JAVASCRIPT }
                        ));
                    } else if (data.status === window.ONLINE_API.STATUS_FAILED) {
                        dispatch(actionCreators.changePiceaConnection(connectionType, PiceaConnectionStatuses.Failed,
                            { status: data.status as number, message: strings.ACTIONS_MESSAGES.ENVIRONMENT.PICEA_CONNECTIOR_NOT_INSTALLED_OR_LOADED }
                        ));
                    } else {
                        // Usb case is little bit buggy.
                        // We need to wait for "PICEA.onConnected" event to be sure that connection is ready.
                        // But there is exeption: If this is retry case isConnected event is not fired fore some reason, and seems that there is no
                        // good solution in jsApi side about that either.
                        // So we check state from ONLINE_API.isConnected, that seems to return true in that case and indicates that onConnected event is not going to happen anymore
                        // https://piceasoft.atlassian.net/browse/PICEA-6091

                        if (connectionType === PiceaConnectionTypes.USB && !window.ONLINE_API.isConnected(connectionType)) {
                            dispatch(actionCreators.setPiceaConnectorVersion(version));
                            // OK, but connection may not be fully ready, wait for window.PICEA.onConnected event, which will change the connection state
                            console.log("ok, waiting for window.PICEA.onConnected");
                        }
                        else {
                            dispatch(actionCreators.changePiceaConnection(connectionType, PiceaConnectionStatuses.Connected));
                        }
                    }
                }, (status: number, data: any) => {
                    console.debug("%c" + "Picea™ Online Services: connect (" + getEnumName(PiceaConnectionTypes, connectionType.toString())  + ") : status", consoleStyles.piceaCollback);
                    console.info({ status, data });
                    switch (status) {
                        case 0:
                            // Usb case is little bit buggy.
                            // We need to wait for "PICEA.onConnected" event to be sure that connection is ready.
                            // But there is exeption: If this is retry case isConnected event is not fired fore some reason, and seems that there is no
                            // good solution in jsApi side about that either.
                            // So we check state from ONLINE_API.isConnected, that seems to return true in that case and indicates that onConnected event is not going to happen anymore
                            // https://piceasoft.atlassian.net/browse/PICEA-6091

                            if (connectionType === PiceaConnectionTypes.USB && !window.ONLINE_API.isConnected(connectionType)) {
                                // OK, but connection may not be fully ready, wait for window.PICEA.onConnected event, which will change the connection state
                                console.log("ok, waiting for window.PICEA.onConnected");
                            }
                            else {
                                dispatch(actionCreators.changePiceaConnection(connectionType, PiceaConnectionStatuses.Connected));
                            }
                            break;
                        case 1:
                            dispatch(actionCreators.changePiceaConnection(connectionType, PiceaConnectionStatuses.Failed,
                                { status: data.status as number, message: strings.ACTIONS_MESSAGES.ENVIRONMENT.PICEA_CONNECTIOR_NOT_INSTALLED_OR_LOADED }
                            ));
                            break;
                        case 2:
                            dispatch(actionCreators.changePiceaConnection(connectionType, PiceaConnectionStatuses.Connecting,
                                { status: data.status as number, message: `${strings.ACTIONS_MESSAGES.ENVIRONMENT.INITIALIZATION} ${data.progress}%` }
                            ));
                            break;
                        default:
                            dispatch(actionCreators.changePiceaConnection(connectionType, PiceaConnectionStatuses.Failed,
                                { status: data.status as number, message: data.message }
                            ));
                            break;
                    }
                });
            }, 1000);
        }
    }
};
