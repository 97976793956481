
import { IFieldsData, IAnswerDetails } from "@piceasoft/core";
import { getTransactionValues } from "../../../components/shared/forms/dataCollectionForm/contractFormHelper";

export const getContractOperationDetails = (data: IFieldsData, url: string): any => {

  let operationDetails: IAnswerDetails = {
    contract:
    {
      document_url: url,
      data: [],
      attachments: []
    }
  };

  if (data.attachments && data.attachments.length > 0)
    data.attachments.map(a => {
      operationDetails.contract.attachments?.push({key: a.label, value: a.filePath ?? ''})
    })

  const transactionValues = getTransactionValues(data);

  transactionValues.map((v) => {
    operationDetails.contract.data.push({
      key: v.key,
      value: v.value
    })
  })

  return operationDetails;
}
